import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-spinners/BarLoader';
import { getAllComplaint, modifyComplaintStatus } from '../../../actions/complaint';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Dropdown from '../../Common/Select/Select';
import { ucfirst } from '../../../utils/common';
import { getdecodedItem } from '../../../utils/localStorageHelper';
import { useHistory } from 'react-router';


const Complaint = () => {

    const dispatch = useDispatch();
    const complaintData = useSelector(state => state.complaint.complaint_data)
    const complaintDataLoading = useSelector(state => state.complaint.get_complaint_loading)
    const modifyStatusSuccess = useSelector(state => state.complaint.modify_status_success)
    const complaintMsg = useSelector(state => state.complaint.get_complaint_msg)
    const history = useHistory();

    const userData = JSON.parse(getdecodedItem('x_auth_user'));


    const [page, setPage] = useState(1);
    const [statusValue, setStatusValue] = useState(
        {
            complaint_id: '',
            status: ''
        }
    )

    const handleStatusChange = (value) => {
        value.status === 'active' ? dispatch(modifyComplaintStatus({ complaint_id: value.complaintId, status: 'solved' })) : dispatch(modifyComplaintStatus({ complaint_id: value.complaintId, status: 'active' }))
    }

    const statusDropdown = [
        { id: 'solved', name: 'Solved' },
        { id: 'active', name: 'Active' }
    ]

    // useEffect(() => {
    //     console.log(statusValue)
    //     statusValue !== null && dispatch(modifyComplaintStatus(statusValue))
    // }, [statusValue])

    useEffect(() => {

        if (modifyStatusSuccess) {
            dispatch(getAllComplaint(page))
        }

    }, [modifyStatusSuccess])

    useEffect(() => {
        dispatch(getAllComplaint(page))
        userData.adminGroup === 'oem admin' && history.push('/oem-management')
    }, [dispatch])
    return (
        <div className='container__content'>
            <div className='padded-container'>
                <span className="table_heading" style={{ height: 33 }}>
                    <h1>Complaint Management</h1>
                    <div className="center-row">
                        {/* <SearchBar initialSearch={(value) => search(value)} resetSearchVal={resetSearch} />
                        <div className="filter_icon_wrapper">
                            <img src={filter_icon} className="filter_icon" onClick={() => setOpenFilter(true)}
                                alt="filter" style={{ cursor: 'pointer', marginRight: 10 }} />

                        </div> */}

                    </div>

                </span>
                {


                    complaintMsg ? <h3 className="error_value">{complaintMsg}</h3> :
                        complaintDataLoading ? <Loader /> :
                            complaintData?.complaint_list?.length < 1 ?
                                <h4 className="error_value center">No Data Available</h4>
                                :
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Order ID</th>
                                            <th>Complaint Id</th>
                                            <th>Date</th>
                                            <th>Complaint Text</th>
                                            <th>Username</th>
                                            <th>Phone</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            complaintData?.complaint_list?.map((item, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.orderId}</td>
                                                    <td>{item.complaintId}</td>
                                                    <td>{item.date}</td>
                                                    <td>{item.complaintText}</td>
                                                    <td>{item.userName}</td>
                                                    <td>{item.userPhone}</td>
                                                    <td>
                                                        <Dropdown data={statusDropdown} arrowIcon="true" onChange={() => handleStatusChange(item)} placeholder={ucfirst(item.status)} />
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        {/* Pagination */}
                                        {/* <tr>

                                            <td style={{ textAlign: 'right', fontSize: '32px' }} colSpan="14"><span><span onClick={() => page > 1 && setPage(page - 1)}>
                                                <ChevronLeftIcon fontSize="large" /></span> {page}/{complaintData?.totalPages}
                                                <span onClick={() => page < complaintData?.totalPages && setPage(page + 1)}><ChevronRightIcon fontSize="large" /></span></span></td>

                                        </tr> */}
                                    </tbody>
                                </table>
                }

            </div>
        </div>
    )
}

export default Complaint
