import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getOemDropdown, getShowroomDetails, getshowroomDropdown } from '../../../actions/oem';
import { getColorDropdown, getVehiclesDropdown } from '../../../actions/vehicle';
import { getAllStates } from '../../../actions/common';
import { addInventory } from '../../../actions/inventory';
import { getdecodedItem } from '../../../utils/localStorageHelper';


const InventoryForm = ({ closeForm }) => {

    const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};
    const dispatch = useDispatch();
    const oemData = useSelector(state => state.oem.list_oem_data);
    const showroomData = useSelector(state => state.oem.list_showroom_data);
    const vehicleData = useSelector(state => state.vehicle.list_vehicle_data);
    const colorData = useSelector(state => state.vehicle.color_vehicle_data);
    const inventorySuccess = useSelector(state => state.inventory.add_success);
    const stateData = useSelector(state => state.common.state_data);

    const [formData, setFormData] = useState({
        oemId: '',
        showroomId: '',
        vehicleId: '',
        color: [],
        stateId: '',
        page: 1
    })

    const { oemId, showroomId, vehicleId, color, stateId, page } = formData;


    const [colorNum, setColorNum] = useState([
        { id: '', price: '', subsidy: '', units: '' }
    ])




    const onSubmitForm = (e) => {
        e.preventDefault()
        formData.color = colorNum
        dispatch(addInventory(formData))
        // setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleCloseForm = () => {
        closeForm()
        setFormData({
            oemId: '',
            showroomId: '',
            vehicleId: '',
            color: [],
            stateId: '',
        })
    }


    const onChangeOem = (e) => {
        setFormData({ ...formData, oemId: e.target.value })
        stateId && dispatch(getshowroomDropdown({ oemId: e.target.value, stateId: stateId, page: page }))
    }

    const onChangeShowroom = (e) => {
        setFormData({ ...formData, showroomId: e.target.value })

        dispatch(getVehiclesDropdown({ oemId: oemId, showroomId: e.target.value }))
    }

    const onChangeVehicle = (e) => {
        setFormData({ ...formData, vehicleId: e.target.value })
        dispatch(getColorDropdown({ vehicleId: e.target.value, showroomId: showroomId }))
    }


    const onChangeState = (e) => {
        setFormData({ ...formData, stateId: e.target.value })
        dispatch(getshowroomDropdown({ oemId: userData.OEMId ? userData.OEMId : oemId, stateId: e.target.value, page: page }))
    }


    const onChangeVehicleColor = (e) => {
        let value = [...colorNum];
        value[e.target.name].id = e.target.value;
        setColorNum(value)
    }


    // const setValueDisabled = (id) => {
    //     setInventoryVal(id)
    // }



    const addColor = () => {
        setColorNum([...colorNum, { id: '', price: '', subsidy: '', units: '' }])
    }


    const onChangeColorForm = (e) => {
        let value = [...colorNum];
        value[e.target.id][e.target.name] = e.target.value;
        setColorNum(value)
        //setColorNum([...colorNum , colorNum[e.target.id][e.target.name] = e.target.value])
    }

    const removeColor = (id) => {
        let value = [...colorNum];
        value.splice(id, 1)
        setColorNum(value)
    }

    useEffect(() => {
        inventorySuccess && closeForm(true)
    }, [inventorySuccess])


    useEffect(() => {
        dispatch(getOemDropdown())
        dispatch(getAllStates());
    }, [])



    return (
        <div>
            <h1>Add Inventory</h1>
            <form onSubmit={onSubmitForm} >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h3>Fill The Details</h3>
                    </Grid>
                    {userData.adminGroup === 'cesl admin' && <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="label">Oem</InputLabel>
                            <Select
                                required
                                id="select-outlined"
                                value={oemId}
                                onChange={e => onChangeOem(e)}
                                label="Oem"
                                fullWidth
                            >
                                {
                                    oemData?.oemDetail?.map((oem, i) => (
                                        <MenuItem key={i} value={oem.id}>{oem.name}</MenuItem>
                                    ))
                                }

                            </Select>
                        </FormControl>
                    </Grid>}
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="label">State</InputLabel>
                            <Select
                                required
                                id="select-outlined"
                                value={stateId}
                                onChange={e => onChangeState(e)}
                                label="State"
                                fullWidth
                            >
                                {
                                    stateData?.states?.map((state, i) => (
                                        <MenuItem key={i} value={state.id}>{state.name}</MenuItem>
                                    ))
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="label">Showroom</InputLabel>
                            <Select
                                required
                                id="select-outlined"
                                value={showroomId}
                                onChange={e => onChangeShowroom(e)}
                                label="Showroom"
                                fullWidth
                            >
                                {
                                    showroomData?.showroom?.map((showroom, i) => (
                                        <MenuItem key={i} value={showroom.id}>{showroom.name}</MenuItem>
                                    ))
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="label">Vehicle</InputLabel>
                            <Select
                                required
                                id="select-outlined"
                                value={vehicleId}
                                onChange={e => onChangeVehicle(e)}
                                label="Vehicle"
                                fullWidth
                            >
                                {
                                    vehicleData?.vehicleDetail?.map((vehicle, i) => (
                                        <MenuItem key={i} value={vehicle.id}>{vehicle.name}</MenuItem>
                                    ))
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className="table_heading">
                        <h3>Color</h3>
                        <h3 onClick={addColor} className="span-btn">Add New</h3>
                    </Grid>
                    {
                        colorNum?.map((item, index) => (
                            <>
                                <Grid container spacing={3} style={{ margin: 1, alignItems: 'center' }}>
                                    <Grid item xs={2}>
                                        <FormControl variant="outlined" id='abc' fullWidth>
                                            <InputLabel id="label">Color</InputLabel>
                                            <Select
                                                required
                                                name={index}
                                                value={colorNum[index].id}
                                                onChange={e => onChangeVehicleColor(e)}
                                                label="Color"
                                                fullWidth
                                            >
                                                {
                                                    colorData?.color?.map((color, i) => (
                                                        <MenuItem key={i} disabled={color.isInventory === 1 ? true : false}
                                                            style={{ color: color.isInventory === 1 ? 'red' : '' }}
                                                            value={color.colorId}>{color.colorName}</MenuItem>
                                                    ))
                                                }

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField variant="outlined"
                                            name="price" fullWidth
                                            required

                                            id={index}

                                            value={colorNum[index].price}
                                            onChange={e => onChangeColorForm(e)}
                                            label="Price" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField variant="outlined"
                                            name="subsidy" fullWidth
                                            required

                                            onChange={e => onChangeColorForm(e)}
                                            id={index}

                                            value={colorNum[index].subsidy}
                                            label="Subsidy" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField variant="outlined"
                                            name="units" fullWidth
                                            required

                                            onChange={e => onChangeColorForm(e)}
                                            id={index}

                                            value={colorNum[index].units}
                                            label="Units" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant='contained' style={{ backgroundColor: 'red', color: '#fff' }} onClick={() => removeColor(index)} >Delete</Button>
                                    </Grid>
                                </Grid>

                            </>
                        ))



                    }
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" size="large" type="submit" >Submit</Button>
                        <Button color="primary" onClick={handleCloseForm} >Cancel</Button>
                    </Grid>

                </Grid>
            </form>
        </div>
    )
}

export default InventoryForm
