import React, { useEffect, useState } from 'react'
import filter_icon from '../../Assets/images/filter_icon.svg';
import modal_filter_icon from '../../Assets/images/modal_filter_icon.svg';
import reset_icon from '../../Assets/images/reset_icon.svg';
import ComponentAddButton from '../../Common/ComponentAddButton';
import SwitchButton from '../../Common/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomers } from '../../../actions/user';
import Loader from '../../Common/Loaders/Loader';
import { getAllStates } from '../../../actions/common';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Button from '@material-ui/core/Button'
import SearchBar from '../../Common/SearchBar/SearchBar';

const Customers = () => {

    const dispatch = useDispatch();
    const customerData = useSelector(state => state.user.data)
    const customerDataLoading = useSelector(state => state.user.loading)
    const stateData = useSelector(state => state.common.state_data);
    const error = useSelector(state => state.user.error)
    const [openFilter, setOpenFilter] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);

    const [openState, setOpenState] = useState(false)
    const [page, setPage] = useState(1)

    const [applyState, setApplyState] = useState('')
    const [searchVal, setSearchVal] = useState("");


    const handleFilterClose = () => {
        setOpenFilter(false)
    }

    const applyFilter = {
        stateId: openState ? applyState : '',
        key: searchVal,
        page: page
    }

    const getFilteredData = () => {
        dispatch(getAllCustomers(applyFilter))
        setOpenFilter(false)
    }

    const handleResetFilter = () => {
        setOpenState(false)
    }


    /**
  * @method search
  * @description for seaching the value 
  * @param {*} value 
  */

    const search = (value) => {
        setPage(1);
        setSearchVal(value);
    }
    /**
     * @method resetSearch
     * @description for resetting the search
     */

    const resetSearch = () => {
        setPage(1)
        setSearchVal("");
    }



    useEffect(() => {
        openState && dispatch(getAllStates())
    }, [openState])

    useEffect(() => {
        dispatch(getAllCustomers(applyFilter))
    }, [dispatch, page, searchVal])

    return (
        <div className='container__content'>
            <div className='padded-container'>
                <span className="table_heading" style={{ height: 33 }}>
                    <h1>Customers</h1>
                    <div className="center-row">
                        <SearchBar initialSearch={(value) => search(value)} resetSearchVal={resetSearch} />
                        {/* <div className="filter_icon_wrapper">
                            <img src={filter_icon} className="filter_icon" onClick={() => setOpenFilter(true)}
                                alt="filter" style={{ cursor: 'pointer', marginRight: 10 }} />

                        </div> */}
                        {/* <ComponentAddButton title="Add Customer" onAdd={() => setOpenAdd(true)} /> */}

                    </div>

                </span>
                {
                    error ? <h3 className="error_value">{error}</h3> :
                        customerDataLoading ? <Loader /> :
                            customerData?.data?.length < 1 ?
                                <h4 className="error_value center">No Data Available</h4>
                                :
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Username</th>
                                            <th>Ph No</th>
                                            <th>Email</th>
                                            <th>Billing Address</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            customerData?.data?.map((item, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.street}</td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            {/* Pagination */}
                                            <td style={{ textAlign: 'right', fontSize: '32px' }} colSpan="14"><span><span onClick={() => page > 1 && setPage(page - 1)}>
                                                <ChevronLeftIcon fontSize="large" /></span> {page}/{customerData?.totalPages}
                                                <span onClick={() => page < customerData?.totalPages && setPage(page + 1)}><ChevronRightIcon fontSize="large" /></span></span></td>

                                        </tr>
                                    </tbody>
                                </table>
                }

            </div>
            {/* Modal of filter */}
            <Dialog open={openFilter} onClose={handleFilterClose}
                fullWidth={true} maxWidth={'xs'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <img src={modal_filter_icon} alt='filter' />
                                <h2>Customer Filter</h2>
                                <img src={reset_icon} style={{ cursor: 'pointer' }} onClick={handleResetFilter} alt='reset' />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>State</h2>
                                <SwitchButton checked={openState} onChange={() => setOpenState(!openState)} name="openState" />
                            </div>
                        </Grid>
                        {
                            openState &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="label">State</InputLabel>
                                    <Select

                                        id="select-outlined"
                                        value={applyState}
                                        onChange={e => setApplyState(e.target.value)}
                                        label="State"
                                        fullWidth
                                    >

                                        {
                                            stateData?.states?.map((state, i) => (
                                                <MenuItem key={i} value={state.id}>{state.name}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={getFilteredData} fullWidth >Apply</Button>
                        </Grid>

                    </Grid>

                </DialogContent>
            </Dialog>

            {/* Modal for add */}
            <Dialog open={openAdd} onClose={() => setOpenAdd(false)}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    {/* <ShowroomForm closeForm={()=> setOpenAdd(false)}/> */}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Customers
