import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import { addCeslAdmins, addOemAdmins } from '../../../actions/user'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { getAllRoles } from '../../../actions/common'

const CeslAdminForm = ({ closeForm }) => {

    const dispatch = useDispatch();

    const addSuccess = useSelector(state => state.user.cesl_add_success)
    const rolesData = useSelector(state => state.common.get_roles_data)

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        oemId: '',
        roleId: '',
        password: ''
    });

    const { name, phone, email, roleId, password } = formData;

    const onChangeForm = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onChangeRole = (e) => {
        setFormData({ ...formData, roleId: e.target.value })
    }



    const handleCloseForm = e => {
        setFormData({
            name: '',
            phone: '',
            email: '',
            oemId: '',
            roleId: '',
            password: ''
        })
        closeForm()
    }

    const submitForm = e => {
        e.preventDefault();
        dispatch(addCeslAdmins(formData))
    }

    useEffect(() => {
        dispatch(getAllRoles())
    }, [])

    useEffect(() => {
        addSuccess && closeForm(true)
    }, [addSuccess])



    return (
        <div>
            <form onSubmit={submitForm}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1>Add Admin</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <h3>Fill The Details</h3>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined"
                            name="name" fullWidth
                            value={name}
                            onChange={e => onChangeForm(e)}
                            label="Name" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined"
                            name="phone" fullWidth
                            value={phone}
                            onChange={e => onChangeForm(e)}
                            label="Phone Number" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined"
                            name="email" fullWidth
                            type="email"
                            value={email}
                            onChange={e => onChangeForm(e)}
                            label="Email" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined"
                            name="password" fullWidth
                            type="password"
                            value={password}
                            onChange={e => onChangeForm(e)}
                            label="Password" />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="label">Roles</InputLabel>
                            <Select

                                id="oem ID"
                                value={roleId}
                                required
                                onChange={e => onChangeRole(e)}
                                label="Roles"
                                fullWidth
                            >
                                {
                                    rolesData?.roles?.map((role, i) => (
                                        <MenuItem key={i} value={role.id}>{role.name}</MenuItem>
                                    ))
                                }

                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit" size="large" type="submit" >Submit</Button>
                        <Button color="primary" onClick={handleCloseForm} >Cancel</Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default CeslAdminForm
