import React from 'react';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';
import { getdecodedItem } from '../../utils/localStorageHelper';
import OemLayout from '../Oem/OemLayout';

const OemRoutes = (props) => {
    const { component: Component, ...rest } = props
    return (
        <div>
            <Route {...rest} render={props => ( getdecodedItem('x_auth_token')
            ?
                <OemLayout>
                    <Component {...props} />
                </OemLayout>
            :
            <Redirect to='/' />

            )} />
        </div>
    )
}

export default OemRoutes
