import { GET_COMPLAINT_LOADING, GET_COMPLAINT_SUCCESS, GET_COMPLAINT_FAILED, MODIFY_COMPLAINT_RESET, MODIFY_COMPLAINT_SUCCESS, MODIFY_COMPLAINT_FAILED } from '../actions/types';

const initialState = {
    get_complaint_loading: false,
    get_complaint_success: false,
    get_complaint_msg: null,
    complaint_data: [],
    modify_status_reset: false,
    modify_status_success: false,
    modify_status_failed: false,

}
export default function (state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case GET_COMPLAINT_LOADING:
            return {
                ...state,
                get_complaint_loading: true,
                get_complaint_success: false,
                get_complaint_msg: null,
                modify_status_reset: false,
                modify_status_success: false,
                modify_status_failed: false,
            }
        case GET_COMPLAINT_SUCCESS:
            return {
                ...state,
                get_complaint_loading: false,
                get_complaint_success: true,
                complaint_data: payload
            }
        case GET_COMPLAINT_FAILED:
            return {
                ...state,
                get_complaint_loading: false,
                get_complaint_success: false,
                get_complaint_msg: payload
            }
        case MODIFY_COMPLAINT_RESET:
            return {
                ...state,
                modify_status_reset: true,
                modify_status_success: false,
                modify_status_failed: false
            }
        case MODIFY_COMPLAINT_SUCCESS:
            return {
                ...state,

                modify_status_success: true,
                modify_status_failed: false

            }
        case MODIFY_COMPLAINT_FAILED:
            return {
                ...state,
                modify_status_success: false,
                modify_status_failed: true
            }
        default:
            return state
    }

}