import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { addShowroom, getOemDropdown, updateShowroom } from '../../../actions/oem';
import { getdecodedItem } from '../../../utils/localStorageHelper';
import { getAllStates } from '../../../actions/common';

const ShowroomForm = ({ closeForm, editData }) => {

    const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};
    const dispatch = useDispatch();
    const oemData = useSelector(state => state.oem.list_oem_data);
    const stateData = useSelector(state => state.common.state_data);
    const addShowroomSuccess = useSelector(state => state.oem.add_showroom_success);
    const updateShowroomSuccess = useSelector(state => state.oem.update_showroom_success);
    const [showroomForm, setShowroomForm] = useState({
        name: '',
        latitude: '',
        longitude: '',
        phone: '',
        streetAddress: '',
        pincode: '',
        city: '',
        stateId: '',
        oemId: '',
        id: ""
    })

    const { name, phone, latitude, longitude, streetAddress, pincode, city, stateId, oemId } = showroomForm;

    const onChangeForm = (e) => {
        setShowroomForm({ ...showroomForm, [e.target.name]: e.target.value })
    }

    const onChangeState = (e) => {
        setShowroomForm({ ...showroomForm, stateId: e.target.value })
    }

    const onChangeOem = (e) => {
        setShowroomForm({ ...showroomForm, oemId: e.target.value })
    }

    const submitData = (e) => {
        e.preventDefault()
        editData ? dispatch(updateShowroom(showroomForm)) : dispatch(addShowroom(showroomForm))
    }

    const handleCloseOemForm = () => {
        setShowroomForm({
            name: '',
            latitude: '',
            longitude: '',
            phone: '',
            streetAddress: '',
            pincode: '',
            city: '',
            stateId: '',
            oemId: '',
            id: ''
        })
        closeForm(false)
    }

    useEffect(() => {
        addShowroomSuccess && closeForm(true)
    }, [addShowroomSuccess])

    useEffect(() => {
        console.log(updateShowroomSuccess)
        updateShowroomSuccess && closeForm(true)
    }, [updateShowroomSuccess])

    useEffect(() => {
        if (editData) {
            setShowroomForm({
                name: editData.name,
                latitude: editData.latitude,
                longitude: editData.longitude,
                phone: editData.phone,
                streetAddress: editData.streetAddress,
                pincode: editData.pincode,
                city: editData.city,
                stateId: editData.stateId,
                oemId: editData.oemId,
                id: editData.id
            })
        }
    }, [editData])

    useEffect(() => {
        dispatch(getAllStates())
        dispatch(getOemDropdown())
    }, [])

    return (
        <div>
            <h1>{editData ? 'Edit Showroom' : 'Add Showroom'}</h1>
            <form onSubmit={submitData}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h3>Fill The Details</h3>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="name" fullWidth
                            value={name}
                            required
                            onChange={e => onChangeForm(e)}
                            label="Name" />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField variant="outlined"
                            name="latitude" fullWidth
                            value={latitude}
                            type="number"
                            required
                            onChange={e => onChangeForm(e)}
                            label="Latitude" />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField variant="outlined"
                            name="longitude" fullWidth
                            value={longitude}
                            type="number"
                            required
                            onChange={e => onChangeForm(e)}
                            label="Longitude" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="phone" fullWidth
                            value={phone}
                            required
                            onChange={e => onChangeForm(e)}
                            label="Phone Number" />
                    </Grid>

                    <Grid item xs={12}>
                        <h3>Address</h3>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="streetAddress" fullWidth
                            value={streetAddress}
                            required
                            onChange={e => onChangeForm(e)}
                            label="Street" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="pincode" fullWidth
                            value={pincode}
                            required
                            onChange={e => onChangeForm(e)}
                            label="Pincode" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="city" fullWidth
                            value={city}
                            required
                            onChange={e => onChangeForm(e)}
                            label="City" />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="label">State</InputLabel>
                            <Select

                                id="select-outlined"
                                value={stateId}
                                required
                                onChange={e => onChangeState(e)}
                                label="State"
                                fullWidth
                            >
                                {
                                    stateData?.states?.map((state, i) => (
                                        <MenuItem key={i} value={state.id}>{state.name}</MenuItem>
                                    ))
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                    {userData.adminGroup === 'cesl admin' &&
                        <Grid item xs={4}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="label">Oem</InputLabel>
                                <Select

                                    id="oem ID"
                                    value={oemId}
                                    required
                                    onChange={e => onChangeOem(e)}
                                    label="Oem"
                                    fullWidth
                                >
                                    {
                                        oemData?.oemDetail?.map((oem, i) => (
                                            <MenuItem key={i} value={oem.id}>{oem.name}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>}
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit" size="large" type="submit" >Submit</Button>
                        <Button color="primary" onClick={handleCloseOemForm} >Cancel</Button>
                    </Grid>
                </Grid>
            </form>

        </div>
    )
}

export default ShowroomForm
