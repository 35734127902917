
import OemRoutes from './components/Routes/OemRoutes';
import LandingRoutes from './components/Routes/LandingRoutes';
import { BrowserRouter as Router, Switch } from 'react-router-dom';


//Redux
import { Provider } from 'react-redux';
import store from './store';


//components
import OemManagement from './components/Oem/OemManagement/OemManagement';
import OrderManagement from './components/Oem/OrderManagement/OrderManagement';
import UserManagement from './components/Oem/UserManagement/UserManagement';
import VehicleManagement from './components/Oem/VehicleManagement/VehicleManagement';
import Login from './components/Auth/Login';
import Alert from './components/Alert/Alert';
import ComplaintManagement from './components/Oem/ComplaintManagement/ComplaintManagement';
import { getdecodedItem } from './utils/localStorageHelper';

// if(getItem){
//   setAuthToken(getItem)
// }
const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};


function App() {



  return (

    <Provider store={store}>
      <Router>
        <Alert />
        <Switch>
          <LandingRoutes path="/" exact component={Login} />
          {

            <OemRoutes path={['/oem-management', '/oem-management/:component']} exact component={OemManagement} />

          }


          <OemRoutes path={['/user-management', '/user-management/:component']} exact component={UserManagement} />
          <OemRoutes path={['/vehicle-management', '/vehicle-management/:component']} exact component={VehicleManagement} />
          <OemRoutes path={['/order-management', '/order-management/:component']} exact component={OrderManagement} />
          <OemRoutes path={['/user-management', '/user-management/:component']} exact component={UserManagement} />
          {


            <OemRoutes path={['/complaint-management', '/complaint-management/:component']} exact component={ComplaintManagement} />

          }

        </Switch>


      </Router>
    </Provider>

  );
}

export default App;
