
import { GET_ORDERS_FAILED, GET_ORDERS_SUCCESS, GET_TEST_DRIVE_SUCCESS, GET_TEST_DRIVE_FAILED, GET_ORDERS_RESET, GET_TEST_DRIVE_RESET, UPDATE_ORDERS_STATUS_RESET, UPDATE_ORDERS_STATUS_SUCCESS, UPDATE_ORDERS_STATUS_FAILED, UPDATE_TEST_DRIVE_STATUS_RESET, UPDATE_TEST_DRIVE_STATUS_SUCCESS, UPDATE_TEST_DRIVE_STATUS_FAILED, DOWNLOAD_ORDER_RESET, DOWNLOAD_ORDER_LOADING, DOWNLOAD_ORDER_FAILED, DOWNLOAD_ORDER_SUCCESS } from "../actions/types";

const initialState = {
    loading: true,
    data: [],
    error: null,
    update_order_status_success: false,
    update_order_status_loading: false,
    update_order_status_msg: null,
    update_test_drive_status_success: false,
    update_test_drive_status_loading: false,
    update_test_drive_status_msg: null,

    download_loading: false,
    download_success: false,
    download_msg: null

}

export default function (state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case GET_ORDERS_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: null,
                update_order_status_success: false,
                update_order_status_loading: false,
                update_order_status_msg: null,

            }
        case GET_TEST_DRIVE_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: null,
                update_test_drive_status_success: false,
                update_test_drive_status_loading: false,
                update_test_drive_status_msg: null,
            }
        case GET_ORDERS_SUCCESS:
        case GET_TEST_DRIVE_SUCCESS:
            return {
                ...state,
                data: payload,
                loading: false,
                error: null
            }
        case GET_ORDERS_FAILED:
        case GET_TEST_DRIVE_FAILED:
            return {
                ...state,
                error: payload,
                data: [],
                loading: false
            }
        case UPDATE_ORDERS_STATUS_RESET:
            return {
                ...state,
                update_order_status_loading: true,
                update_order_status_success: false,
                update_order_status_msg: null
            }
        case UPDATE_ORDERS_STATUS_SUCCESS:
            return {
                ...state,
                update_order_status_loading: false,
                update_order_status_success: true,
                update_order_status_msg: null
            }
        case UPDATE_ORDERS_STATUS_FAILED:
            return {
                ...state,
                update_order_status_loading: false,
                update_order_status_success: false,
                update_order_status_msg: payload
            }
        case UPDATE_TEST_DRIVE_STATUS_RESET:
            return {
                ...state,
                update_test_drive_status_loading: true,
                update_test_drive_status_success: false,
                update_test_drive_status_msg: null
            }
        case UPDATE_TEST_DRIVE_STATUS_SUCCESS:
            return {
                ...state,
                update_test_drive_status_loading: false,
                update_test_drive_status_success: true,
                update_test_drive_status_msg: null
            }
        case UPDATE_TEST_DRIVE_STATUS_FAILED:
            return {
                ...state,
                update_test_drive_status_loading: false,
                update_test_drive_status_success: false,
                update_test_drive_status_msg: payload
            }

        case DOWNLOAD_ORDER_RESET:
            return {
                ...state,
                download_loading: false,
                download_success: false,
                download_msg: null
            }

        case DOWNLOAD_ORDER_LOADING:
            return {
                ...state,
                download_loading: true,
                download_success: false,
                download_msg: null
            }
        case DOWNLOAD_ORDER_FAILED:
            return {
                ...state,
                download_loading: false,
                download_success: false,
                download_msg: payload
            }
        case DOWNLOAD_ORDER_SUCCESS:
            return {
                ...state,
                download_loading: false,
                download_success: true,
                download_msg: payload
            }

        default:
            return state
    }

}