import React, { useState } from 'react'
import filter_icon from '../../Assets/images/filter_icon.svg';
import modal_filter_icon from '../../Assets/images/modal_filter_icon.svg';
import reset_icon from '../../Assets/images/reset_icon.svg';
import ComponentAddButton from '../../Common/ComponentAddButton';
import SwitchButton from '../../Common/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from "@material-ui/core/styles";
// import VehicleFormComponent from './VehicleForm';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllTestdrives, updateTestDriveStatus } from '../../../actions/orders';
import Dropdown from '../../Common/Select/Select';
import Loader from '../../Common/Loaders/Loader';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button'
import { ucfirst } from '../../../utils/common';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { getOemDropdown, getshowroomDropdown } from '../../../actions/oem';
import { getAllStates } from '../../../actions/common';
import { getVehiclesDropdown } from '../../../actions/vehicle';
import SearchBar from '../../Common/SearchBar/SearchBar';
import { getdecodedItem } from '../../../utils/localStorageHelper';

const useStyles = makeStyles({
    root: {
        backgroundColor: '#203594',
    },
    label: {
        color: "#fff",
        "&.Mui-focused": {
            color: "#fff",

        },
    },
    select: {
        "& .MuiSvgIcon-root": {
            color: "#fff",
        },

    },

})


const TestDrives = () => {

    const classes = useStyles();
    const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};
    const dispatch = useDispatch();
    const ordersData = useSelector(state => state.orders.data)
    const ordersDataError = useSelector(state => state.orders.error)
    const ordersDataLoading = useSelector(state => state.orders.loading)
    const oemData = useSelector(state => state.oem.list_oem_data);
    const stateData = useSelector(state => state.common.state_data);
    const showroomData = useSelector(state => state.oem.list_showroom_data);
    const vehicleData = useSelector(state => state.vehicle.list_vehicle_data);
    const updateSuccess = useSelector(state => state.orders.update_test_drive_status_success);


    const [openFilter, setOpenFilter] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [openOem, setOpenOem] = useState(false)
    const [openColor, setOpenColor] = useState(false)
    const [openVehicle, setOpenVehicle] = useState(false)
    const [openShowroom, setOpenShowroom] = useState(false)
    const [openState, setOpenState] = useState(false)
    const [openApproval, setOpenApproval] = useState(false)
    const [page, setPage] = useState(1)

    const [searchVal, setSearchVal] = useState("");


    const [applyState, setApplyState] = useState('')
    const [applyApproval, setApplyApproval] = useState('')
    const [applyVehicle, setApplyVehicle] = useState('')
    const [applyShowroom, setApplyShowroom] = useState('')
    const [applyOem, setApplyOem] = useState('')
    const [applyColor, setApplyColor] = useState('')


    const handleFilterClose = () => {
        setOpenFilter(false)
    }

    const applyFilter = {
        oemId: openOem ? applyOem : '',
        statusId: openApproval ? applyApproval : '',
        vehicleId: openVehicle ? applyVehicle : '',
        colorId: openColor ? applyColor : '',
        stateId: openState ? applyState : '',
        showroomId: openShowroom ? applyShowroom : '',
        key: searchVal,
        page: page
    }


    const handleOemChange = () => {
        setOpenOem(!openOem)
    }
    /**
  * @method search
  * @description for seaching the value 
  * @param {*} value 
  */

    const search = (value) => {
        setPage(1);
        setSearchVal(value);
    }
    /**
     * @method resetSearch
     * @description for resetting the search
     */

    const resetSearch = () => {
        setPage(1)
        setSearchVal("");
    }



    const getFilteredData = () => {
        dispatch(getAllTestdrives(applyFilter))
        setOpenFilter(false)
    }

    const selectData = [
        { id: 'active', name: 'Active' },
        { id: 'completed', name: 'Completed' },
        { id: 'cancelled', name: 'Cancelled' },
    ]

    const handleStateChange = (e, data) => {

        dispatch(updateTestDriveStatus({ id: data.id, status: e.id }))
        // setSelectedState(state)
    }


    const handleResetFilter = () => {
        setOpenOem(false)
        setOpenApproval(false)
        setOpenState(false)
        setOpenVehicle(false)
    }



    useEffect(() => {
        openOem && dispatch(getOemDropdown())
        openState && dispatch(getAllStates()) 
        openShowroom ? dispatch(getshowroomDropdown({ oemId: userData.OEMId ? userData.OEMId : applyOem, stateId: 7, page: 1 })) : setOpenVehicle(false)
        openVehicle ? dispatch(getVehiclesDropdown({ oemId: userData.OEMId ? userData.OEMId : applyOem, showroomId: applyShowroom })) : setOpenColor(false)

    }, [openOem, openState, openShowroom, openVehicle])


    useEffect(() => {
        updateSuccess && dispatch(getAllTestdrives(applyFilter))
    }, [updateSuccess])

    useEffect(() => {

        if (userData.OEMId) {
            setOpenOem(true)
            applyFilter.oemId = userData.OEMId
        }
        dispatch(getAllTestdrives(applyFilter))


    }, [dispatch, page, searchVal])


    return (
        <div className='container__content'>
            <div className='padded-container'>
                <span className="table_heading" style={{ height: 33 }}>
                    <h1>Test Drives</h1>
                    <div className="center-row">
                        <SearchBar initialSearch={(value) => search(value)} resetSearchVal={resetSearch} />
                        <div className="filter_icon_wrapper">
                            <img src={filter_icon} className="filter_icon" onClick={() => setOpenFilter(true)}
                                alt="filter" style={{ cursor: 'pointer', marginRight: 10 }} />

                        </div>
                        {/* <ComponentAddButton title="Add Vehicle" onAdd={() => setOpenAdd(true)} /> */}

                    </div>

                </span>
                {
                    ordersDataError ? <h3 className="error_value center">{ordersDataError}</h3> :
                        ordersDataLoading ? <Loader /> :
                            ordersData?.data?.length < 1 ?
                                <h4 className="error_value center">No Data Available</h4>
                                :
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Username</th>
                                            <th>Ph No</th>
                                            <th>Vehicle</th>
                                            <th>Showroom</th>
                                            {/* <th>Requested Date</th> */}
                                            <th>Order Date</th>
                                            <th>Status</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            ordersData?.data?.map((item, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.vehicleName}</td>
                                                    <td>{item.showroomName}</td>
                                                    {/* <td>{item.tdDate}</td> */}
                                                    <td>{item.orderDate}</td>

                                                    <td>
                                                        <Dropdown data={userData?.adminGroup === 'cesl admin' ? selectData : []}
                                                            onChange={e => handleStateChange(e, item)} placeholder={ucfirst(item.status)}
                                                            arrowIcon={userData?.adminGroup === 'cesl admin' ? true : false} />
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            {/* Pagination */}
                                            <td style={{ textAlign: 'right', fontSize: '32px' }} colSpan="14"><span><span onClick={() => page > 1 && setPage(page - 1)}>
                                                <ChevronLeftIcon fontSize="large" /></span> {page}/{ordersData?.totalPages}
                                                <span onClick={() => page < ordersData?.totalPages && setPage(page + 1)}><ChevronRightIcon fontSize="large" /></span></span></td>

                                        </tr>
                                    </tbody>
                                </table>

                }
            </div>
            {/* Modal of filter */}
            <Dialog open={openFilter} onClose={handleFilterClose}
                fullWidth={true} maxWidth={'xs'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <img src={modal_filter_icon} alt='filter' />
                                <h2>Test Drive Filter</h2>
                                <img src={reset_icon} style={{ cursor: 'pointer' }} onClick={handleResetFilter} alt='reset' />
                            </div>
                        </Grid>
                        {userData.adminGroup === 'cesl admin' && <>     <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>OEM</h2>
                                <SwitchButton checked={openOem} onChange={handleOemChange} name="openOem" />
                            </div>
                        </Grid>
                            {
                                openOem &&
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="label">Oem</InputLabel>
                                        <Select
                                            required
                                            id="select-outlined"
                                            value={applyOem}
                                            onChange={e => setApplyOem(e.target.value)}
                                            label="Oem"
                                            fullWidth
                                        >
                                            {
                                                oemData?.oemDetail?.map((oem, i) => (
                                                    <MenuItem key={i} value={oem.id}>{oem.name}</MenuItem>
                                                ))
                                            }

                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                        </>

                        }
                        {/* <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>State</h2>
                                <SwitchButton checked={openState} onChange={() => setOpenState(!openState)} name="openState" />
                            </div>
                        </Grid>
                        {
                            openState &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="label">State</InputLabel>
                                    <Select

                                        id="select-outlined"
                                        value={applyState}
                                        onChange={e => setApplyState(e.target.value)}
                                        label="State"
                                        fullWidth
                                    >

                                        {
                                            stateData?.states?.map((state, i) => (
                                                <MenuItem key={i} value={state.id}>{state.name}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                        } */}

                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Showroom</h2>
                                <SwitchButton checked={openShowroom} onChange={() => setOpenShowroom(!openShowroom)} name="openShowroom" />
                            </div>
                        </Grid>
                        {
                            openShowroom &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="label">Showroom</InputLabel>
                                    <Select

                                        id="select-outlined"
                                        value={applyShowroom}
                                        onChange={e => setApplyShowroom(e.target.value)}
                                        label="Showroom"
                                        fullWidth
                                    >

                                        {
                                            showroomData?.showroom?.map((showroom, i) => (
                                                <MenuItem key={i} value={showroom.id}>{showroom.name}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Vehicle</h2>
                                <SwitchButton checked={openVehicle}
                                    disabled={openShowroom ? false : true}
                                    onChange={() => setOpenVehicle(!openVehicle)} name="openShowroom" />
                            </div>
                        </Grid>
                        {
                            openVehicle &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="label">Vehicle</InputLabel>
                                    <Select

                                        id="select-outlined"
                                        value={applyVehicle}
                                        onChange={e => setApplyVehicle(e.target.value)}
                                        label="Vehicle"
                                        fullWidth
                                    >

                                        {
                                            vehicleData?.vehicleDetail?.map((vehicle, i) => (
                                                <MenuItem key={i} value={vehicle.id}>{vehicle.name}</MenuItem>
                                            ))
                                        }


                                    </Select>
                                </FormControl>
                            </Grid>
                        }


                        {/* <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Approval</h2>
                                <SwitchButton checked={openApproval} onChange={() => setOpenApproval(!openApproval)} name="openApproval" />
                            </div>
                        </Grid>
                        {
                            openApproval &&
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        name="approval"
                                        row
                                        value={applyApproval}
                                        onChange={e => setApplyApproval(e.target.value)}>
                                        {
                                            approvalData?.approvalStatuses?.map((item, i) => (
                                                <FormControlLabel key={i} value={item.id}
                                                    control={<Radio color="primary"
                                                        checked={item.id === parseInt(applyApproval)} />}
                                                    label={ucfirst(item.name)} />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        } */}

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={getFilteredData} fullWidth >Apply</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            {/* Modal for add */}
            <Dialog open={openAdd} onClose={() => setOpenAdd(false)}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>

                    {/* <VehicleFormComponent closeForm={() => setOpenAdd(false)} /> */}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default TestDrives
