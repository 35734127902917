import { LOAD_USER, LOGIN_SUCCESS, LOGIN_FAILED, AUTH_ERR, LOGOUT_USER, OTP_RESET, OTP_FAILED, OTP_SUCCESS, OTP_VERIFY_SUCCESS, OTP_VERIFY_FAILED } from './types';
import ApiConstants from '../constants/ApiConstants';
import { HTTPGetRequest, HTTPRequest } from '../utils/serverHelper';
import { setAlert } from './alert';

const MODULE_URL = `${ApiConstants.BASE_URL}/signIn`;
const MODULE_URL_ECOMM = `${ApiConstants.BASE_URL_ECOM}/customer`;


export const loginUser = ({ email, password }) => async dispatch => {

    const config = {
        headers: {
            Authorization: "Basic " + btoa(unescape(encodeURIComponent(email + ":" + password)))
        }
    }
    try {
        let res = await HTTPGetRequest(`${MODULE_URL}/v1/admin-account`, config.headers)
        if (res.status === 200) {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.response
            })
            dispatch(setAlert('success', 'Login Successfull !!!'))

        }
        else {
            dispatch({
                type: LOGIN_FAILED,
                payload: res.response
            })
            dispatch(setAlert('error', res.response.message))
        }
    } catch (error) {
        dispatch({
            type: AUTH_ERR,
            payload: error
        })
        dispatch(setAlert('error', error.message))
    }

}

export const logoutUser = () => async dispatch => {

    dispatch({
        type: LOGOUT_USER
    })

}

export const resetOtp = () => ({
    type: OTP_RESET
})
// export const resetNewPassword = () => ({
//     type: Actions.NEW_PASS_VERIFY_RESET
// })

export const sendOtp = (data) => async dispatch => {
    dispatch({
        type: OTP_RESET
    })
    try {
        let res = await HTTPGetRequest(`${MODULE_URL_ECOMM}/v1/OTP?phoneNumber=${data.phone}&countryCode=${91}&OtpType=${data.type}&enteredOTP=${data.enteredOtp}`, {})
        if (res.status === 200) {
            dispatch({
                type: OTP_SUCCESS,
                payload: res.response.message
            })
            dispatch(setAlert('success', res.response.message))
        }
        else {
            dispatch({
                type: OTP_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {
        dispatch({
            type: OTP_FAILED,
            payload: error.message
        })
        dispatch(setAlert('error', error.message))
    }
}
export const verifyOtp = (data) => async dispatch => {
    dispatch({
        type: OTP_RESET
    })
    try {
        let res = await HTTPGetRequest(`${MODULE_URL_ECOMM}/v1/OTP?phoneNumber=${data.phone}&countryCode=${91}&OtpType=${data.type}&enteredOTP=${data.enteredOtp}`, {})
        if (res.status === 200) {
            dispatch({
                type: OTP_VERIFY_SUCCESS,
                payload: res.response.message
            })
            dispatch(setAlert('success', res.response.message))
        }
        else {
            dispatch({
                type: OTP_VERIFY_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {
        dispatch({
            type: OTP_VERIFY_FAILED,
            payload: error.message
        })

        dispatch(setAlert('error', error.message))
    }
}