import React, { useState } from 'react'
import filter_icon from '../../Assets/images/filter_icon.svg';
import modal_filter_icon from '../../Assets/images/modal_filter_icon.svg';
import reset_icon from '../../Assets/images/reset_icon.svg';
import ComponentAddButton from '../../Common/ComponentAddButton';
import SwitchButton from '../../Common/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from "@material-ui/core/styles";
// import VehicleFormComponent from './VehicleForm';
import DeliveryForm from './DeliveryForm';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { downloadOrder, getAllOrders, updateOrdersStatus } from '../../../actions/orders';
import Dropdown from '../../Common/Select/Select';
import Loader from '../../Common/Loaders/Loader';
import { getOemDropdown, getshowroomDropdown } from '../../../actions/oem';
import { getAllApprovalStatus, getAllOrderStatus, getAllStates } from '../../../actions/common';
import { getColorDropdown, getVehiclesDropdown } from '../../../actions/vehicle';
import { resetOtp } from '../../../actions/auth';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button'
import { ucfirst } from '../../../utils/common';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import SearchBar from '../../Common/SearchBar/SearchBar';
import { getdecodedItem } from '../../../utils/localStorageHelper';
import Calender from '../../Common/Calender/Calender';

const useStyles = makeStyles({
    root: {
        backgroundColor: '#203594',
    },
    label: {
        color: "#fff",
        "&.Mui-focused": {
            color: "#fff",

        },
    },
    select: {
        "& .MuiSvgIcon-root": {
            color: "#fff",
        },

    },

})


const Orders = () => {

    const classes = useStyles();
    const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};
    const dispatch = useDispatch();
    const ordersData = useSelector(state => state.orders.data)
    const ordersDataLoading = useSelector(state => state.orders.loading)
    const ordersDataError = useSelector(state => state.orders.error)
    const oemData = useSelector(state => state.oem.list_oem_data);
    const stateData = useSelector(state => state.common.state_data);
    const showroomData = useSelector(state => state.oem.list_showroom_data);
    const vehicleData = useSelector(state => state.vehicle.list_vehicle_data);
    const approvalData = useSelector(state => state.common.approval_data);
    const orderStatusData = useSelector(state => state.common.order_status_data)
    const orderStatusSuccess = useSelector(state => state.orders.update_order_status_success)

    const [openFilter, setOpenFilter] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);


    const [openOem, setOpenOem] = useState(false)
    const [openColor, setOpenColor] = useState(false)
    const [openVehicle, setOpenVehicle] = useState(false)
    const [openShowroom, setOpenShowroom] = useState(false)
    const [openState, setOpenState] = useState(false)
    const [openApproval, setOpenApproval] = useState(false)
    const [openDelivery, setOpenDelivery] = useState(false)
    const [openDate, setOpenDate] = useState(false)
    const [page, setPage] = useState(1)


    const [applyState, setApplyState] = useState('')
    const [applyApproval, setApplyApproval] = useState('')
    const [applyVehicle, setApplyVehicle] = useState('')
    const [applyShowroom, setApplyShowroom] = useState('')
    const [applyOem, setApplyOem] = useState('')
    const [applyColor, setApplyColor] = useState('')
    const [orderStatusObj, setOrderStatusObj] = useState({})
    const [orderValues, setOrderValues] = useState(null)

    const [handleOtp, setHandleOtp] = useState(false)

    const [searchVal, setSearchVal] = useState("");

    const [state, setState] = useState([
        {
            startDate: new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const handleFilterClose = () => {
        setOpenFilter(false)
    }

    const applyFilter = {
        oemId: openOem ? applyOem : '',
        statusId: openApproval ? applyApproval : '',
        vehicleId: openVehicle ? applyVehicle : '',
        colorId: openColor ? applyColor : '',
        stateId: openState ? applyState : '',
        showroomId: openShowroom ? applyShowroom : '',
        search: searchVal,
        page: page,
        startDate: openDate ? `${new Date(state[0].startDate).getFullYear()}-${new Date(state[0].startDate).getMonth() + 1}-${new Date(state[0].startDate).getDate()}` : '',
        endDate: openDate ? `${new Date(state[0].endDate).getFullYear()}-${new Date(state[0].endDate).getMonth() + 1}-${new Date(state[0].endDate).getDate()}` : ''
    }

    /**
  * @method search
  * @description for seaching the value 
  * @param {*} value 
  */

    const search = (value) => {
        setPage(1);
        setSearchVal(value);
    }
    /**
     * @method resetSearch
     * @description for resetting the search
     */

    const resetSearch = () => {
        setPage(1)
        setSearchVal("");
    }



    const handleOemChange = () => {
        setOpenOem(!openOem)
    }


    const selectData = [
        { id: 'pending_failed', name: 'Pending Failed' },
        { id: 'pending_done', name: 'Pending Done' },
    ]

    const handleStateChange = (e, data) => {
        if (e.id === 5) {
            setOpenDelivery(true)
            setOrderValues(data)
            setOrderStatusObj({ id: data.orderId, statusId: e.id })
        }
        else {
            dispatch(updateOrdersStatus({ id: data.orderId, statusId: e.id }))
        }

        // setSelectedState(state)
    }


    const handleResetFilter = () => {
        setOpenOem(false)
        setOpenApproval(false)
        setOpenState(false)
        setOpenColor(false)
        setOpenVehicle(false)
        setOpenShowroom(false)
    }




    const getFilteredData = () => {
        dispatch(getAllOrders(applyFilter))
        setOpenFilter(false)

    }
    const download = () => {
        // let date = new Date()
        let isoStartDate = new Date(new Date(new Date(state[0].startDate).getTime() + 24 * 60 * 60 * 1000).setUTCHours(0, 0, 0, 0))
        let isoEndDate = new Date(new Date(new Date(state[0].endDate).getTime() + 24 * 60 * 60 * 1000).setUTCHours(0, 0, 0, 0));
        let startDate = `${isoStartDate.getFullYear()}-${isoStartDate.getMonth() + 1}-${isoStartDate.getDate()}`
        let endDate = `${isoEndDate.getFullYear()}-${isoEndDate.getMonth() + 1}-${isoEndDate.getDate()}`
        applyFilter.startDate = startDate
        applyFilter.endDate = endDate
        dispatch(downloadOrder(applyFilter))
    }

    const isTotalPaid = (value, data, arr) => {
        let filterArr = []
        let obj = { id: 2, name: 'Confirmed By CESL' }
        value === 1 ? filterArr.push(...arr) : data === 1 ? filterArr.push(obj) : filterArr = new Array()
        return filterArr
    }

    const getOemStatus = (data, value) => {

        let arr = orderStatusData?.orderStatuses

        if (userData?.adminGroup === 'cesl admin') {

            let newArr = [];
            if (data <= 4) {
                newArr.push(arr[data])

            }

            return isTotalPaid(value, data, newArr)
        }
        else {
            let newArr = [];
            arr.slice(2, 5)
            if (data <= 4 && data > 1) {
                value === 1 ? newArr.push(arr[data]) : newArr = new Array()
            }


            return newArr

        }
    }

    const getArrow = (data, value) => {
        if (userData?.adminGroup === 'cesl admin') {
            if (data <= 4) {
                return value === 0 && data >= 2 ? false : true
            }
            else {
                return false
            }
        }
        else {
            if (data <= 4 && data > 1) {
                return value === 1 ? true : false
            }
            else {
                return false
            }
        }

    }

    const handleCloseDeliveryForm = (data) => {
        setOpenDelivery(false)
        dispatch(resetOtp())
        // setOrderStatusObj({ ...orderStatusObj, ...data })
        dispatch(updateOrdersStatus({ ...orderStatusObj, ...data }))
    }

    const handleCloseDelivery = () => {
        setOpenDelivery(false)
        dispatch(resetOtp())
        setHandleOtp(true)
    }




    useEffect(() => {
        openOem && dispatch(getOemDropdown())
        openState && dispatch(getAllStates())
        openShowroom ? dispatch(getshowroomDropdown({ oemId: userData.OEMId ? userData.OEMId : applyOem, stateId: 7, page: 1 })) : setOpenVehicle(false)
        openVehicle ? dispatch(getVehiclesDropdown({ oemId: userData.OEMId ? userData.OEMId : applyOem, showroomId: applyShowroom })) : setOpenColor(false)
        openColor && dispatch(getColorDropdown({ vehicleId: applyVehicle, showroomId: applyShowroom }))
        // openApproval && dispatch(getAllOrderStatus())
    }, [openOem, openState, openShowroom, openVehicle, openColor, openApproval])


    useEffect(() => {
        orderStatusSuccess && dispatch(getAllOrders(applyFilter))

    }, [orderStatusSuccess])

    useEffect(() => {
        dispatch(getAllOrders(applyFilter))
        dispatch(getAllOrderStatus())
        userData.OEMId && setOpenOem(true)
    }, [dispatch, page, searchVal])


    return (
        <div className='container__content'>
            <div className='padded-container'>
                <span style={{ height: 33 }} className="table_heading">
                    <h1>Orders</h1>

                    <div className="center-row">
                        <Button variant="contained" color="primary" onClick={download}
                            className="m-r-10" size="large" >Download report</Button>
                        <SearchBar initialSearch={(value) => search(value)} resetSearchVal={resetSearch} />
                        <div className="filter_icon_wrapper">
                            <img src={filter_icon} className="filter_icon" onClick={() => setOpenFilter(true)}
                                alt="filter" style={{ cursor: 'pointer', marginRight: 10 }} />

                        </div>


                    </div>

                </span>
                {
                    ordersDataError ? <h3 className="error_value center">{ordersDataError}</h3> :

                        ordersDataLoading ? <Loader /> :
                            ordersData?.orderDetail?.length < 1 ?
                                <h4 className="error_value center">No Data Available</h4>
                                :
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Order Id</th>
                                            <th>Username</th>
                                            <th>Ph No</th>
                                            <th>Vehicle</th>
                                            <th>Colour</th>
                                            {
                                                userData?.adminGroup === 'cesl admin'
                                                &&
                                                <>
                                                    <th>Price</th>
                                                    <th>Subsidy</th>
                                                </>
                                            }

                                            <th>Payment Id</th>

                                            <th>Amount Paid</th>
                                            <th>Full Amount Paid</th>
                                            <th>Billing Address</th>
                                            <th>Showroom</th>
                                            <th>Order Date</th>
                                            <th>Remaining Payment Date</th>
                                            <th>Status</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {

                                            ordersData?.orderDetail?.map((item, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.orderId}</td>
                                                    <td>{item.userName}</td>
                                                    <td>{item.userPhone}</td>
                                                    <td>{item.vehicleName}</td>
                                                    <td>{item.color}</td>
                                                    {
                                                        userData?.adminGroup === 'cesl admin'
                                                        &&
                                                        <>
                                                            <td>{item.price}</td>
                                                            <td>{item.subsidy}</td>
                                                        </>
                                                    }

                                                    <td>{item.paymentId.join(',')}</td>

                                                    <td>{item.amountPaid}</td>
                                                    <td
                                                        style={{ color: item.totalPaidFlag === 1 ? 'green' : 'red', fontWeight: item.totalPaidFlag === 1 ? 'bold' : 'normal' }}
                                                    >{item.totalPaidFlag === 1 ? 'Yes' : 'No'}</td>
                                                    <td>
                                                        {item.street}
                                                        <br />
                                                        {item.city}
                                                        <br />
                                                        {item.stateName}
                                                        <br />
                                                        {item.pincode}
                                                    </td>
                                                    <td>{item.showroomName}</td>
                                                    <td>{item.orderDate}</td>
                                                    <td>{item.remaining_payment_date}</td>

                                                    <td>
                                                        <Dropdown data={getOemStatus(item.orderStatusId, item.totalPaidFlag)} onChange={(e) =>
                                                            handleStateChange(e, item)} placeholder={item.orderStatus}
                                                            arrowIcon={getArrow(item.orderStatusId, item.totalPaidFlag)} />

                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            {/* Pagination */}
                                            <td style={{ textAlign: 'right', fontSize: '32px' }} colSpan="14"><span><span onClick={() => page > 1 && setPage(page - 1)}>
                                                <ChevronLeftIcon fontSize="large" /></span> {page}/{ordersData?.totalPages}
                                                <span onClick={() => page < ordersData?.totalPages && setPage(page + 1)}><ChevronRightIcon fontSize="large" /></span></span></td>

                                        </tr>

                                    </tbody>
                                </table>
                }

            </div>
            {/* Modal of filter */}
            <Dialog open={openFilter} onClose={handleFilterClose}
                fullWidth={true} maxWidth={'xs'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <img src={modal_filter_icon} alt='filter' />
                                <h2>Order Filter</h2>
                                <img src={reset_icon} style={{ cursor: 'pointer' }} onClick={handleResetFilter} alt='reset' />
                            </div>
                        </Grid>
                        {userData.adminGroup === 'cesl admin' && <>    <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>OEM</h2>
                                <SwitchButton checked={openOem} onChange={handleOemChange} name="openOem" />
                            </div>
                        </Grid>
                            {
                                openOem &&
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="label">Oem</InputLabel>
                                        <Select
                                            required
                                            id="select-outlined"
                                            value={applyOem}
                                            onChange={e => setApplyOem(e.target.value)}
                                            label="Oem"
                                            fullWidth
                                        >
                                            {
                                                oemData?.oemDetail?.map((oem, i) => (
                                                    <MenuItem key={i} value={oem.id}>{oem.name}</MenuItem>
                                                ))
                                            }

                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                        </>
                        }
                        {/* <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>State</h2>
                                <SwitchButton checked={openState} onChange={() => setOpenState(!openState)} name="openState" />
                            </div>
                        </Grid>
                        {
                            openState &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="label">State</InputLabel>
                                    <Select

                                        id="select-outlined"
                                        value={applyState}
                                        onChange={e => setApplyState(e.target.value)}
                                        label="State"
                                        fullWidth
                                    >

                                        {
                                            stateData?.states?.map((state, i) => (
                                                <MenuItem key={i} value={state.id}>{state.name}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                        } */}

                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Showroom</h2>
                                <SwitchButton checked={openShowroom}  onChange={() => setOpenShowroom(!openShowroom)} name="openShowroom" />
                            </div>
                        </Grid>
                        {
                            openShowroom &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="label">Showroom</InputLabel>
                                    <Select

                                        id="select-outlined"
                                        value={applyShowroom}
                                        onChange={e => setApplyShowroom(e.target.value)}
                                        label="Showroom"
                                        fullWidth
                                    >

                                        {
                                            showroomData?.showroom?.map((showroom, i) => (
                                                <MenuItem key={i} value={showroom.id}>{showroom.name}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Vehicle</h2>
                                <SwitchButton checked={openVehicle}
                                    disabled={openShowroom ? false : true}
                                    onChange={() => setOpenVehicle(!openVehicle)} name="openShowroom" />
                            </div>
                        </Grid>
                        {
                            openVehicle &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="label">Vehicle</InputLabel>
                                    <Select

                                        id="select-outlined"
                                        value={applyVehicle}
                                        onChange={e => setApplyVehicle(e.target.value)}
                                        label="Vehicle"
                                        fullWidth
                                    >

                                        {
                                            vehicleData?.vehicleDetail?.map((vehicle, i) => (
                                                <MenuItem key={i} value={vehicle.id}>{vehicle.name}</MenuItem>
                                            ))
                                        }


                                    </Select>
                                </FormControl>
                            </Grid>
                        }


                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Status</h2>
                                <SwitchButton checked={openApproval} onChange={() => setOpenApproval(!openApproval)} name="openApproval" />
                            </div>
                        </Grid>
                        {
                            openApproval &&
                            <Grid item xs={12}>

                                <FormControl component="fieldset">
                                    <RadioGroup
                                        name="Status"
                                        row
                                        value={applyApproval}
                                        onChange={e => setApplyApproval(e.target.value)}>
                                        {

                                            orderStatusData?.orderStatuses?.map((item, i) => (
                                                <FormControlLabel key={i} value={item.id}
                                                    control={<Radio color="primary"
                                                        checked={item.id === parseInt(applyApproval)} />}
                                                    label={ucfirst(item.name)} />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Select Date</h2>
                                <SwitchButton checked={openDate} onChange={() => setOpenDate(!openDate)} name="openDate" />
                            </div>
                        </Grid>
                        {
                            openDate &&
                            <Grid item xs={12}>

                                <Calender rangeDates={state} setDates={item => setState(item)} />
                            </Grid>}

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={getFilteredData} fullWidth >Apply</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            {/* Modal for add */}
            <Dialog open={openAdd} onClose={() => setOpenAdd(false)}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>

                    {/* <VehicleFormComponent closeForm={() => setOpenAdd(false)} /> */}
                </DialogContent>
            </Dialog>


            {/* Modal for delivery form */}
            <Dialog open={openDelivery} onClose={() => handleCloseDelivery()}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <DeliveryForm orderData={orderValues} otpOpen={handleOtp} closeForm={(data) => handleCloseDeliveryForm(data)} />

                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Orders
