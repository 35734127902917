import React, { useState, useEffect } from 'react';
import filter_icon from '../../Assets/images/filter_icon.svg';
import modal_filter_icon from '../../Assets/images/modal_filter_icon.svg';
import edit__icon from '../../Assets/images/edit__icon.svg';
import reset_icon from '../../Assets/images/reset_icon.svg';
import ComponentAddButton from '../../Common/ComponentAddButton';
import SwitchButton from '../../Common/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OemForm from './OemForm';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from 'react-redux';
import { getOemDetails, updateOemDetails } from '../../../actions/oem';
import Dropdown from '../../Common/Select/Select';
import { getAllApprovalStatus, getAllStates } from '../../../actions/common';
import { ucfirst } from '../../../utils/common';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Loader from '../../Common/Loaders/Loader';
import { getdecodedItem } from '../../../utils/localStorageHelper';


const Oem = () => {
    const dispatch = useDispatch();

    const oemData = useSelector(state => state.oem.data);
    const oemDataLoading = useSelector(state => state.oem.loading);
    const oemAddSuccess = useSelector(state => state.oem.add_success);
    const oemUpdateSuccess = useSelector(state => state.oem.update_success);

    const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};

    const stateData = useSelector(state => state.common.state_data);
    const approvalData = useSelector(state => state.common.approval_data);

    const [openFilter, setOpenFilter] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [openState, setOpenState] = useState(false)
    const [openApproval, setOpenApproval] = useState(false)

    const [page, setPage] = useState(1);

    const [applyState, setApplyState] = useState(0);
    const [applyStatus, setApplyStatus] = useState(0);
    const [statusVal, setStatusVal] = useState('')
    const [editData, setEditData] = useState(null)

    const applyFilter = {
        stateId: openState ? applyState : 0,
        statusId: openApproval ? applyStatus : 0,
        page: page
    }

    const handleFilterClose = () => {
        setOpenFilter(false)
    }

    const selectData = [
        { id: 'approved', name: 'Approved' },
        { id: 'not_approved', name: 'Not Approved' },
    ]

    const handleStateChange = (e, value) => {
        value.statusId = e.id
        value.hqStreet = value.HqAddressStreet
        dispatch(updateOemDetails(value))
    }

    const handleFilterApply = () => {
        dispatch(getOemDetails(applyFilter))
        setOpenFilter(false)
    }

    const handleResetFilter = () => {
        setOpenApproval(false)
        setOpenState(false)
    }

    const handleCloseAdd = (val) => {
        setOpenAdd(false)
        val && (getOemDetails(applyFilter))
    }

    const handleCloseUpdate = (val) => {
        setOpenEdit(false)
        val && (getOemDetails(applyFilter))
    }

    const handleEditOpen = (data) => {
        setEditData(data)
        setOpenEdit(true)
    }

    useEffect(() => {
        dispatch(getOemDetails(applyFilter))
        dispatch(getAllApprovalStatus())
    }, [dispatch])

    useEffect(() => {
        openState && dispatch(getAllStates())
        openApproval && dispatch(getAllApprovalStatus())
    }, [openState, openApproval])

    useEffect(() => {
        (oemAddSuccess || oemUpdateSuccess) && dispatch(getOemDetails(applyFilter))
    }, [oemAddSuccess, oemUpdateSuccess])




    return (
        <div className='container__content'>
            <div className='padded-container'>
                <span className="table_heading">
                    <h1>Company</h1>
                    <div className="center-row">
                        <div className="filter_icon_wrapper">
                            <img src={filter_icon} className="filter_icon" onClick={() => setOpenFilter(true)}
                                alt="filter" style={{ cursor: 'pointer', marginRight: 10 }} />

                        </div>
                        {
                            userData?.role?.add_oem &&
                            <ComponentAddButton title="Add Oem" onAdd={() => setOpenAdd(true)} />
                        }


                    </div>

                </span>
                {
                    oemDataLoading ? <Loader /> :
                        oemData?.oemDetail?.length < 1 ?
                            <h4 className="error_value center">No Data Available</h4>
                            :
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Name</th>
                                        <th>Registration Number</th>
                                        <th>GST</th>
                                        <th>Contact Number</th>
                                        <th>HQ Address</th>
                                        <th>Approval</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {

                                        oemData?.oemDetail?.map((item, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.regNumber}</td>
                                                <td>{item.gstNumber}</td>
                                                <td>{item.phone}</td>
                                                <td>{item.HqAddressStreet}</td>
                                                <td>


                                                    <Dropdown data={userData?.adminGroup === 'cesl admin' ? approvalData?.approvalStatuses : []} onChange={(e) =>
                                                        handleStateChange(e, item)} placeholder={ucfirst(item.approvalStatus)}
                                                        arrowIcon={userData?.adminGroup === 'cesl admin' ? true : false} />
                                                </td>
                                                <td>
                                                    <span className="edit-button" onClick={() => handleEditOpen(item)}>
                                                        <img src={edit__icon} alt="edit" className="table_edit_icon" />
                                                    </span>

                                                </td>
                                            </tr>
                                        )
                                        )
                                    }
                                    <tr>
                                        {/* Pagination */}
                                        <td style={{ textAlign: 'right', fontSize: '32px' }} colSpan="14"><span><span onClick={() => page > 1 && setPage(page - 1)}>
                                            <ChevronLeftIcon fontSize="large" /></span> {page}/{oemData?.totalPages}
                                            <span onClick={() => page < oemData?.totalPages && setPage(page + 1)}><ChevronRightIcon fontSize="large" /></span></span></td>

                                    </tr>
                                </tbody>
                            </table>
                }

            </div>
            {/* Modal of filter */}
            <Dialog open={openFilter} onClose={handleFilterClose}
                fullWidth={true} maxWidth={'xs'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <img src={modal_filter_icon} alt='filter' />
                                <h2>Oem Filter</h2>
                                <img src={reset_icon} style={{ cursor: 'pointer' }} onClick={handleResetFilter} alt='reset' />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Approval</h2>
                                <SwitchButton checked={openApproval} onChange={() => setOpenApproval(!openApproval)} name="openApproval" />
                            </div>
                        </Grid>
                        {
                            openApproval &&
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        name="approval"
                                        row
                                        value={applyStatus}
                                        onChange={e => setApplyStatus(e.target.value)}>
                                        {
                                            approvalData?.approvalStatuses?.map((item, i) => (
                                                <FormControlLabel key={i} value={item.id}
                                                    control={<Radio color="primary"
                                                        checked={item.id === parseInt(applyStatus)} />}
                                                    label={ucfirst(item.name)} />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" fullWidth
                                onClick={handleFilterApply}>Apply</Button>
                        </Grid>

                    </Grid>

                </DialogContent>
            </Dialog>

            {/* Modal for add */}
            <Dialog open={openAdd} onClose={() => setOpenAdd(false)}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <OemForm closeForm={(loadable = false) => handleCloseAdd(loadable)} />
                </DialogContent>
            </Dialog>

            {/* Modal for edit */}
            <Dialog open={openEdit} onClose={() => setOpenEdit(false)}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <OemForm editData={editData} closeForm={(loadable = false) => handleCloseUpdate(loadable)} />
                </DialogContent>
            </Dialog>


        </div >
    )
}

export default Oem
