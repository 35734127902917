import React, { useEffect } from 'react'
import moment from 'moment';
import { useState } from 'react'
import './Calender.css';

const Calender = ({rangeDates , setDates}) => {



    var enumerateDaysBetweenDates = function(startDate,endDate) {
        var dates = [];
    
        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');
    
        while(currDate.add(1, 'days').diff(lastDate) < 0) {
            dates.push(currDate.clone().toDate());
        }
    
        return dates;
    };
    const [value , setValue] = useState(moment())
    const [range, setRange] = useState(enumerateDaysBetweenDates(moment(rangeDates[0] && rangeDates[0].startDate).subtract(1,'day'),moment(rangeDates[0] && rangeDates[0].endDate).add(1,'day')));
    const [rangeDate , setRangeDate] = useState()
  
    const startDay = value.clone().startOf('month').startOf('week')
    const endDay = value.clone().endOf('month').endOf('week')
    const day = startDay.clone().subtract(1, 'day');
  
    const calender = [];
  
    while(day.isBefore(endDay)){
      calender.push(
            Array(7).fill(0).map(() => day.add(1, 'day').clone())
          )
    }
  
  
    const selectedDate = (value) => {
      if (range.length < 2) {
        let minDate = moment.min([range[0],value])
        let maxDate = moment.max([range[0],value])
        console.log(minDate , maxDate)
        let days=enumerateDaysBetweenDates(moment(minDate).subtract(1 ,'day'), moment(maxDate).add(1,'day'))
        setRange(days)
        setRangeDate([{startDate:minDate.toDate(),endDate: maxDate.toDate()}])
      }
      else {
        setRange([value])
        setRangeDate([{startDate:value.toDate()}])
      }
    }
    
  
    const displayDates = (day) => {
      let bkcolor = '';
     range && range.map((item) =>{
        if(day.isSame(item,"day")){
          bkcolor = '#437FEC';
        }
      })
      return <div 
      className="day"
      style={{color : day.isSame(value,'month') ? '#fff' : 'gray'}}
       onClick={() => selectedDate(day)}>
          <div className={day.isSame(new Date(),'day') ? 'today' :''} >
            <div className={day.isSame(range &&range[0]) && 'start-day' || day.isSame( range &&range[range.length-1]) && 'end-day' } style={{backgroundColor:(day.isSame( range &&range[0]) || day.isSame( range &&range[range.length-1]))?bkcolor : 'transparent' }}>
            <div style={{backgroundColor : (day.isSame( range &&range[0]) || day.isSame( range &&range[range.length-1])) ? 'orange' :bkcolor ,borderRadius : (day.isSame( range &&range[0]) || day.isSame( range &&range[range.length-1])) && 10}}>
            {day.format('D')}
            </div>
            </div>
          </div>
    
      </div>
  }
  
  const previousMonth =( ) =>{
      setValue(value.subtract(1,'month').clone())
  }
  
  const nextMonth =( ) =>{
      setValue(value.add(1,'month').clone())
  }

  useEffect(()=>{
   (rangeDate && Object.keys(rangeDate[0]).length > 1) && setDates(rangeDate)
  },[rangeDate])

    return (
     
      <div className="calender-wrapper">
   
        <div style={{display:'flex' , marginLeft:21 , justifyContent:'space-between' , color:'#fff'}}>
        <div>{value.format('MMMM')}-{value.format('YYYY')}</div>
     
        <div style={{marginRight:21}}>
        <span style={{margin:16, cursor:'pointer'}} onClick={previousMonth}>{String.fromCharCode(171)}</span>
        <span  style={{cursor:'pointer'}} onClick={nextMonth}>{String.fromCharCode(187)}</span>
        </div>
        </div>
        <div style={{display:'flex' , justifyContent : 'space-around' , color : '#fff', paddingTop: 10}}>
        <div>Sun</div>
        <div>Mon</div>
        <div>Tue</div>
        <div>Wed</div>
        <div>Thu</div>
        <div>Fri</div>
        <div>Sat</div>
        </div>
      
        {
          calender.map((week) => {
            return <div style={{ display: 'flex', alignItems: 'center'}}>
              {
                week.map((day) => {
                  return displayDates(day)
                })
              }
            </div>
          })
        }
        </div>
  
    );
  
}

export default Calender
