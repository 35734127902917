import { HTTPGetRequest, HTTPPutRequest, HTTPRequest } from "../utils/serverHelper";
import { GET_INVENTORY_SUCCESS, GET_INVENTORY_FAILED, GET_INVENTORY_RESET, ADD_INVENTORY_RESET, ADD_INVENTORY_SUCCESS, ADD_INVENTORY_FAILED, UPDATE_INVENTORY_RESET, UPDATE_INVENTORY_SUCCESS, UPDATE_INVENTORY_FAILED, UPDATE_INVENTORY_STATUS_FAILED, UPDATE_INVENTORY_STATUS_SUCCESS, UPDATE_INVENTORY_STATUS_RESET } from "./types";
import ApiConstants from "../constants/ApiConstants";
import { getdecodedItem } from "../utils/localStorageHelper";

const MODULE_URL = `${ApiConstants.BASE_URL}/inventory`;
const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};

export const getAllInventory = (data) => async dispatch => {
    dispatch({ type: GET_INVENTORY_RESET })
    dispatch({ type: ADD_INVENTORY_RESET })
    try {

        let res = await HTTPGetRequest(`${MODULE_URL}/?vehicleId=${data.vehicleId}&oemId=${data.oemId}&colorId=${data.colorId}&statusId=${data.statusId}&stateId=${data.stateId}&showroomId=${data.showroomId}&page=${data.page}`);

        if (res.status === 200) {
            dispatch({
                type: GET_INVENTORY_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_INVENTORY_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_INVENTORY_FAILED,
            payload: error.message
        })
    }

}
export const addInventory = (data) => async dispatch => {

    dispatch({ type: ADD_INVENTORY_RESET })
    try {

        let res = await HTTPRequest(`${MODULE_URL}/`,
            {
                authToken: userData.token
            }, {
            ...data
        });

        if (res.status === 200) {
            dispatch({
                type: ADD_INVENTORY_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: ADD_INVENTORY_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_INVENTORY_FAILED,
            payload: error.message
        })
    }

}

export const updateInventory = (data) => async dispatch => {

    dispatch({ type: UPDATE_INVENTORY_RESET })
    try {

        let res = await HTTPPutRequest(`${MODULE_URL}/`,
            {
                authToken: userData.token
            }, {
            ...data
        });

        if (res.status === 200) {
            dispatch({
                type: UPDATE_INVENTORY_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: UPDATE_INVENTORY_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: UPDATE_INVENTORY_FAILED,
            payload: error.message
        })
    }

}

export const updateInventoryStatus = (data) => async dispatch => {

    dispatch({ type: UPDATE_INVENTORY_STATUS_RESET })
    try {

        let res = await HTTPPutRequest(`${MODULE_URL}/modify-status`,
            {
                authToken: userData.token
            }, {
            ...data
        });

        if (res.status === 200) {
            dispatch({
                type: UPDATE_INVENTORY_STATUS_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: UPDATE_INVENTORY_STATUS_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: UPDATE_INVENTORY_STATUS_FAILED,
            payload: error.message
        })
    }

}