import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useSelector , useDispatch } from 'react-redux';
import { updateInventory } from '../../../actions/inventory';
import { useEffect } from 'react';

const EditInventoryForm = ({ data , closeForm}) => {

    const dispatch = useDispatch();

    const editSuccess = useSelector(state => state.inventory.update_success)

    const [formData, setFormData] = useState({
        price: data.price,
        units: data.units,
        subsidy: data.subsidy,
        invId: data.id
    })

    const { price, units, subsidy } = formData;

    const handleValueChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const submitForm = () => {
        dispatch(updateInventory(formData))
    }

    useEffect(() =>{
     editSuccess && closeForm()
    },[editSuccess])

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h1>Edit inventory </h1>
                </Grid>

                <Grid item xs={12}>
                    <h3>Fill the details</h3>
                </Grid>



                <Grid item xs={6}>
                    <TextField variant="outlined" label="OEM" value={data.oemName} fullWidth disabled />
                </Grid>

                <Grid item xs={6}>
                    <TextField variant="outlined" label="Showroom" value={data.showroomName} disabled fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField variant="outlined" label="Vehicle" value={data.vehicleName} disabled fullWidth />
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={3} style={{ alignItems: 'center' }}>
                        <Grid item xs={2}>
                            <TextField variant="outlined" label="Color"
                                value={data.colorName} disabled fullWidth />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField variant="outlined" label="Price"
                                type="number" value={price}
                                name="price"
                                onChange={e => handleValueChange(e)}
                                fullWidth />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField variant="outlined" label="Subsidy"
                                type="number" value={subsidy}
                                name="subsidy"
                                onChange={e => handleValueChange(e)}
                                fullWidth />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField variant="outlined" label="Units"
                                type="number" value={units}
                                name="units"
                                onChange={e => handleValueChange(e)}
                                fullWidth />
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary" onClick={submitForm}>Update</Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    )
}

export default EditInventoryForm
