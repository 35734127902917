import React from 'react';
import { Route } from 'react-router';

const LandingRoutes = (props) => {
    const { component: Component, ...rest } = props
    return (
        <div>
            <Route {...rest} render={props => (
                    <Component {...props} />
            )} />
        </div>
    )
}

export default LandingRoutes
