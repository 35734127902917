import { GET_STATE_SUCCESS, GET_STATE_FAILED, GET_APPROVAL_SUCCESS, GET_APPROVAL_FAILED, GET_ORDER_STATUS_RESET, GET_ORDER_STATUS_FAILED, GET_ORDER_STATUS_SUCCESS, GET_APPROVAL_RESET, GET_STATE_RESET, GET_ROLES_LOADING, GET_ROLES_SUCCESS, GET_ROLES_FAILED } from "../actions/types";

const initialState = {
    state_loading: true,
    state_data: [],
    state_error: null,
    approval_loading: true,
    approval_data: [],
    approval_error: null,
    order_status_loading: false,
    order_status_data: [],
    order_status_msg: null,

    get_roles_loading: false,
    get_roles_data: [],
    get_roles_success: false,
    get_roles_msg: null


}

export default function (state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case GET_STATE_RESET:
            return {
                ...state,
                state_data: [],
                state_loading: true,
                state_error: null
            }
        case GET_STATE_SUCCESS:
            return {
                ...state,
                state_data: payload,
                state_loading: false,
                state_error: null
            }
        case GET_STATE_FAILED:
            return {
                ...state,
                state_error: payload,
                state_data: [],
                state_loading: false
            }
        case GET_APPROVAL_RESET:
            return {
                ...state,
                approval_data: [],
                approval_loading: true,
                approval_error: null
            }
        case GET_APPROVAL_SUCCESS:
            return {
                ...state,
                approval_data: payload,
                approval_loading: false,
                approval_error: null
            }
        case GET_APPROVAL_FAILED:
            return {
                ...state,
                approval_error: payload,
                approval_data: [],
                approval_loading: false
            }
        case GET_ORDER_STATUS_RESET:
            return {
                ...state,
                order_status_data: [],
                order_status_loading: true,
                order_status_msg: null
            }
        case GET_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                order_status_data: payload,
                order_status_loading: false,
                order_status_msg: null
            }
        case GET_ORDER_STATUS_FAILED:
            return {
                ...state,
                order_status_msg: payload,
                order_status_data: [],
                order_status_loading: false
            }

        case GET_ROLES_LOADING:
            return {
                ...state,
                get_roles_loading: true,
                get_roles_success: false,
                get_roles_msg: null
            }
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                get_roles_loading: false,
                get_roles_data: payload,
                get_roles_success: true
            }
        case GET_ROLES_FAILED:
            return {
                ...state,
                get_roles_loading: false,
                get_roles_data: [],
                get_roles_success: false,
                get_roles_msg: payload
            }
        default:
            return state
    }

}