import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import logo from '../Assets/images/tsredco_logo.svg';
import oem_icon from '../Assets/images/oem_icon.svg';
import vehicle_icon from '../Assets/images/vehicle_icon.svg';
import order_icon from '../Assets/images/order_icon.svg';
import user_icon from '../Assets/images/user_icon.svg';
import complaint_icon from '../Assets/images/complaint_icon.svg';
import { getdecodedItem } from '../../utils/localStorageHelper';
import { ucfirst } from '../../utils/common';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../actions/auth';

const Header = () => {

    const location = useLocation();
    const path = location.pathname;

    const dispatch = useDispatch();

    const userData = JSON.parse(getdecodedItem('x_auth_user'));

    return (
        <div className="header__sticky">

            <div className='header__wrapper padding10' >
                <div className='header__left'>
                    <Link to='/'>
                        <img src={logo} className='logo__image' alt='statiq logo' />
                    </Link>

                </div>

                <div className='header__right'>

                    <div className='header__profile'>

                        <span className='profile__name'>{userData.name ? ucfirst(userData.name) : ''}</span>
                        {/* <span className='profile__role'>{userData.adminGroup ? ucfirst(userData.adminGroup) : ''}</span> */}
                        <span className='span-btn profile__role' onClick={() => dispatch(logoutUser())}>Logout</span>

                    </div>
                    <div className="CmsHeader__user">
                        <Avatar style={{ color: 'white', backgroundColor: "#FF914D", marginLeft: 10 }}>
                            {userData.name ? ucfirst(userData.name[0]) : ''}
                        </Avatar>
                    </div>
                </div>
            </div>

            <div className='container__topmenu'>
                <nav className="container__nav">
                    <ul>
                        {

                            <Link to="/oem-management">
                                <li className={path.includes('/oem-management') ? `active ` : ''}>
                                    <i className={path.includes('/oem-management') ? `nav-icon-active ` : 'nav-icon'}> <img src={oem_icon} alt="oem" className="menu_icon" /></i>

                                    <span>Oem Management</span>
                                </li>
                            </Link>

                        }

                        <Link to="/vehicle-management">
                            <li className={path.includes('/vehicle-management') ? `active ` : ''}>
                                <i className={path.includes('/vehicle-management') ? `nav-icon-active ` : 'nav-icon'}> <img src={vehicle_icon} alt="vehicle" className="menu_icon" /></i>
                                <span>Vehicle Management</span>
                            </li>
                        </Link>

                        <Link to="/order-management">
                            <li className={path.includes('/order-management') ? `active` : ''}>
                                <i className={path.includes('/order-management') ? `nav-icon-active ` : 'nav-icon'}> <img src={order_icon} alt="order" className="menu_icon" /></i>
                                <span>Order Management</span>
                            </li>
                        </Link>
                        <Link to="/user-management">
                            <li className={path.includes('/user-management') ? `active` : ''}>
                                <i className={path.includes('/user-management') ? `nav-icon-active ` : 'nav-icon'}> <img src={user_icon} alt="user" className="menu_icon" /></i>
                                <span>User Management</span>
                            </li>
                        </Link>
                        {
                            userData.adminGroup === 'cesl admin' &&
                            <Link to="/complaint-management">
                                <li className={path.includes('/complaint-management') ? `active` : ''}>
                                    <i className={path.includes('/complaint-management') ? `nav-icon-active ` : 'nav-icon'}> <img src={complaint_icon} alt="complaint" className="menu_icon" /></i>
                                    <span>Complaint Management</span>
                                </li>
                            </Link>
                        }


                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Header
