
import {
    GET_OEM_SUCCESS, GET_OEM_FAILED, GET_SHOWROOM_SUCCESS, GET_SHOWROOM_FAILED,
    ADD_OEM_FAILED, ADD_OEM_SUCCESS, GET_OEM_RESET, GET_SHOWROOM_RESET, GET_OEM_LIST_RESET,
    GET_OEM_LIST_SUCCESS, GET_OEM_LIST_FAILED, ADD_OEM_RESET, ADD_SHOWROOM_RESET, ADD_SHOWROOM_SUCCESS, ADD_SHOWROOM_FAILED, GET_SHOWROOM_LIST_RESET, GET_SHOWROOM_LIST_SUCCESS, GET_SHOWROOM_LIST_FAILED, UPDATE_OEM_RESET, UPDATE_OEM_SUCCESS, UPDATE_OEM_FAILED, UPDATE_SHOWROOM_RESET, UPDATE_SHOWROOM_SUCCESS, UPDATE_SHOWROOM_FAILED
} from './types';
import ApiConstants from '../constants/ApiConstants';
import { HTTPGetRequest, HTTPPutRequest, HTTPRequest } from '../utils/serverHelper';
import { getdecodedItem } from '../utils/localStorageHelper';
import { setAlert } from './alert';

const MODULE_URL = `${ApiConstants.BASE_URL}/oem`;
const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};

export const getOemDetails = (data) => async dispatch => {
    dispatch({ type: GET_OEM_RESET })
    dispatch({ type: ADD_OEM_RESET })
    try {
        let res = await HTTPGetRequest(`${MODULE_URL}/v1/oem?stateId=${data.stateId}&statusId=${data.statusId}&page=${data.page}`)
        if (res.status === 200) {
            dispatch({
                type: GET_OEM_SUCCESS,
                payload: res.response
            })

        }
        else {
            dispatch({
                type: GET_OEM_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {

        dispatch({
            type: GET_OEM_FAILED,
            payload: error.message
        })
    }
}

export const getOemDropdown = () => async dispatch => {
    dispatch({ type: GET_OEM_LIST_RESET })
    try {
        let res = await HTTPGetRequest(`${MODULE_URL}/v1/list-oem?searchKey=`)
        if (res.status === 200) {
            dispatch({
                type: GET_OEM_LIST_SUCCESS,
                payload: res.response
            })

        }
        else {
            dispatch({
                type: GET_OEM_LIST_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {

        dispatch({
            type: GET_OEM_LIST_FAILED,
            payload: error.message
        })
    }
}


export const getshowroomDropdown = (data) => async dispatch => {
    dispatch({ type: GET_SHOWROOM_LIST_RESET })
    try {
        let res = await HTTPGetRequest(`${MODULE_URL}/showroom?oemId=${data.oemId}&stateId=${data.stateId}`)
        if (res.status === 200) {
            dispatch({
                type: GET_SHOWROOM_LIST_SUCCESS,
                payload: res.response
            })


        }
        else {
            dispatch({
                type: GET_SHOWROOM_LIST_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))

        }

    } catch (error) {

        dispatch({
            type: GET_SHOWROOM_LIST_FAILED,
            payload: error.message
        })
    }
}


export const addOemDetails = (data) => async dispatch => {
    dispatch({ type: ADD_OEM_RESET })
    try {
        let res = await HTTPRequest(`${MODULE_URL}/v1/oem`,
            {
                authToken: userData.token
            },
            {
                ...data,
                countryCode: userData.countryCode,
                phone: userData.phone,
                email: userData.email
            })
        if (res.status === 200) {
            dispatch({
                type: ADD_OEM_SUCCESS,
                payload: res.response.message
            })

            dispatch(setAlert('success', res.response.message))

        }
        else {
            dispatch({
                type: ADD_OEM_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {

        dispatch({
            type: GET_OEM_FAILED,
            payload: error.message
        })
    }
}

export const updateOemDetails = (data) => async dispatch => {
    dispatch({ type: UPDATE_OEM_RESET })
    try {
        let res = await HTTPPutRequest(`${MODULE_URL}/v1/oem`,
            {
                authToken: userData.token
            },
            {
                ...data,
                countryCode: userData.countryCode,
                phone: userData.phone,
                email: userData.email,

            })
        if (res.status === 200) {
            dispatch({
                type: UPDATE_OEM_SUCCESS,
                payload: res.response.message
            })

            dispatch(setAlert('success', res.response.message))

        }
        else {
            dispatch({
                type: UPDATE_OEM_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {

        dispatch({
            type: GET_OEM_FAILED,
            payload: error.message
        })
    }
}


export const getShowroomDetails = (data) => async dispatch => {
    dispatch({ type: GET_SHOWROOM_RESET })
    dispatch({ type: UPDATE_SHOWROOM_RESET })
    dispatch({ type: ADD_SHOWROOM_RESET })
    try {
        let res = await HTTPGetRequest(`${MODULE_URL}/v1/showroom?stateId=${data.stateId}&oemId=${data.oemId}&page=${data.page}`)
        if (res.status === 200) {
            dispatch({
                type: GET_SHOWROOM_SUCCESS,
                payload: res.response
            })

        }
        else {
            dispatch({
                type: GET_SHOWROOM_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_SHOWROOM_FAILED,
            payload: error.message
        })
    }
}


export const addShowroom = (data) => async dispatch => {
    dispatch({ type: ADD_SHOWROOM_RESET })
    try {
        let res = await HTTPRequest(`${MODULE_URL}/v1/showroom`,
            {
                authToken: userData.token
            },
            {
                ...data,
                countryCode: userData.countryCode,
                email: userData.email
            })
        if (res.status === 200) {
            dispatch({
                type: ADD_SHOWROOM_SUCCESS,
                payload: res.response.message
            })

            dispatch(setAlert('success', res.response.message))

        }
        else {
            dispatch({
                type: ADD_SHOWROOM_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {

        dispatch({
            type: ADD_SHOWROOM_FAILED,
            payload: error.message
        })
    }
}

export const updateShowroom = (data) => async dispatch => {
    dispatch({ type: UPDATE_SHOWROOM_RESET })
    try {
        let res = await HTTPPutRequest(`${MODULE_URL}/v1/showroom`,
            {
                authToken: userData.token
            },
            {
                ...data,
                countryCode: userData.countryCode,
                email: userData.email
            })
        if (res.status === 200) {
            dispatch({
                type: UPDATE_SHOWROOM_SUCCESS,
                payload: res.response.message
            })

            dispatch(setAlert('success', res.response.message))

        }
        else {
            dispatch({
                type: UPDATE_SHOWROOM_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {

        dispatch({
            type: UPDATE_SHOWROOM_FAILED,
            payload: error.message
        })
    }
}

