import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Complaint from './Complaint';

const ComplaintManagement = () => {

    const [active, setActive] = useState('complaint')

    const { component } = useParams();


    useEffect(() => {
        setActive(component ? component : 'complaint')
    }, [component])

    const setMenu = (item) => {
        setActive(item)
    }

    const renderComponent = (component) => {
        switch (component) {
            case 'complaint':
                return <Complaint />

            default:
                return <Complaint />
        }
    }
    return (
        <div className="view__wrapper">
            <div className="container__sidemenu-wrapper">
                <div className="sub_wrapper">
                    <ul>
                        <Link to='/complaint-management/complaint'><li onClick={() => setMenu('complaint')} className={active === 'complaint' ? 'active' : ''}>Complaint</li></Link>

                    </ul>
                </div>
            </div>
            {/* content  */}

            {/* <Suspense fallback={() => <Loader />}> */}
            {renderComponent(component)}
            {/* </Suspense> */}

        </div>
    )
}

export default ComplaintManagement
