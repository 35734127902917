import { GET_INVENTORY_SUCCESS, GET_INVENTORY_FAILED, GET_INVENTORY_RESET, ADD_INVENTORY_FAILED, ADD_INVENTORY_SUCCESS, ADD_INVENTORY_RESET, UPDATE_INVENTORY_FAILED, UPDATE_INVENTORY_SUCCESS, UPDATE_INVENTORY_RESET, UPDATE_INVENTORY_STATUS_FAILED, UPDATE_INVENTORY_STATUS_SUCCESS, UPDATE_INVENTORY_STATUS_RESET } from "../actions/types";

const initialState = {
    loading: true,
    data: [],
    error: null,
    add_loading: true,
    add_success: false,
    add_msg: null,
    update_loading: true,
    update_success: false,
    update_msg: null,
    update_status_loading: true,
    update_status_success: false,
    update_status_msg: null


}

export default function (state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case GET_INVENTORY_RESET:
            return {
                ...state,
                data: [],
                loading: true,
                error: null,
                add_loading: true,
                add_success: false,
                add_msg: null,
                update_loading: true,
                update_success: false,
                update_msg: null,
                update_status_loading: false,
                update_status_success: false,
                update_status_msg: null
            }
        case GET_INVENTORY_SUCCESS:
            return {
                ...state,
                data: payload,
                loading: false,
                error: null
            }
        case GET_INVENTORY_FAILED:
            return {
                ...state,
                error: payload,
                loading: false,
                data: []
            }
        case ADD_INVENTORY_RESET:
            return {
                ...state,
                add_loading: true,
                add_success: false,
                add_msg: null
            }
        case ADD_INVENTORY_SUCCESS:
            return {
                ...state,
                add_loading: false,
                add_success: true,
            }
        case ADD_INVENTORY_FAILED:
            return {
                ...state,
                add_loading: false,
                add_success: false,
                add_msg: payload.message
            }
        case UPDATE_INVENTORY_RESET:
            return {
                ...state,
                update_loading: true,
                update_success: false,
                update_msg: null
            }
        case UPDATE_INVENTORY_SUCCESS:
            return {
                ...state,
                update_loading: false,
                update_success: true,
            }
        case UPDATE_INVENTORY_FAILED:
            return {
                ...state,
                update_loading: false,
                update_success: false,
                update_msg: payload.message
            }
        case UPDATE_INVENTORY_STATUS_RESET:
            return {
                ...state,
                update_status_loading: true,
                update_status_success: false,
                update_status_msg: null
            }
        case UPDATE_INVENTORY_STATUS_SUCCESS:
            return {
                ...state,
                update_status_loading: false,
                update_status_success: true,
            }
        case UPDATE_INVENTORY_STATUS_FAILED:
            return {
                ...state,
                update_status_loading: false,
                update_status_success: false,
                update_status_msg: payload.message
            }
        default:
            return state
    }

}