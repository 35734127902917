import React, { useEffect, useState } from 'react'
import filter_icon from '../../Assets/images/filter_icon.svg';
import modal_filter_icon from '../../Assets/images/modal_filter_icon.svg';
import reset_icon from '../../Assets/images/reset_icon.svg';
import ComponentAddButton from '../../Common/ComponentAddButton';
import SwitchButton from '../../Common/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useDispatch, useSelector } from 'react-redux';
import { getAllInventory, updateInventoryStatus } from '../../../actions/inventory';
import Dropdown from '../../Common/Select/Select';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Loader from '../../Common/Loaders/Loader';
import InventoryForm from './InventoryForm';
import { getOemDropdown, getshowroomDropdown } from '../../../actions/oem';
import { getAllApprovalStatus, getAllStates } from '../../../actions/common';
import { getColorDropdown, getVehiclesDropdown } from '../../../actions/vehicle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button'
import { ucfirst } from '../../../utils/common';
import { getdecodedItem } from '../../../utils/localStorageHelper';
import edit__icon from '../../Assets/images/edit__icon.svg';
import EditInventoryForm from './EditInventoryForm';


const Inventory = () => {

    const dispatch = useDispatch();
    const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};
    const inventoryData = useSelector(state => state.inventory.data)
    const inventoryDataError = useSelector(state => state.inventory.error)
    const inventoryDataLoading = useSelector(state => state.inventory.loading)
    const oemData = useSelector(state => state.oem.list_oem_data);
    const stateData = useSelector(state => state.common.state_data);
    const showroomData = useSelector(state => state.oem.list_showroom_data);
    const vehicleData = useSelector(state => state.vehicle.list_vehicle_data);
    const colorData = useSelector(state => state.vehicle.color_vehicle_data);
    const approvalData = useSelector(state => state.common.approval_data);
    const statusStatusSuccess = useSelector(state => state.inventory.update_status_success);

    const [openFilter, setOpenFilter] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);

    const [openOem, setOpenOem] = useState(false)
    const [openColor, setOpenColor] = useState(false)
    const [openVehicle, setOpenVehicle] = useState(false)
    const [openShowroom, setOpenShowroom] = useState(false)
    const [openState, setOpenState] = useState(false)
    const [openApproval, setOpenApproval] = useState(false)
    const [page, setPage] = useState(1)


    const [applyState, setApplyState] = useState('')
    const [applyApproval, setApplyApproval] = useState('')
    const [applyVehicle, setApplyVehicle] = useState('')
    const [applyShowroom, setApplyShowroom] = useState('')
    const [applyOem, setApplyOem] = useState('')
    const [applyColor, setApplyColor] = useState('')

    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState(null)



    const handleFilterClose = () => {
        setOpenFilter(false)
    }


    const applyFilter = {
        oemId: openOem ? applyOem : '',
        statusId: openApproval ? applyApproval : '',
        vehicleId: openVehicle ? applyVehicle : '',
        colorId: openColor ? applyColor : '',
        stateId: openState ? applyState : '',
        showroomId: openShowroom ? applyShowroom : '',
        page: page
    }


    const selectData = [
        { id: 'approved', name: 'Approved' },
        { id: 'not_approved', name: 'Not Approved' },
    ]

    const handleStateChange = (e, value) => {
        dispatch(updateInventoryStatus({
            "statusId": e.id,
            "invId": value.id
        }))
    }

    const handleOemChange = () => {
        setOpenOem(!openOem)
    }

    const handleEditOpen = (data) => {
        setEditData(data)
        setOpenEdit(true)
    }

    const handleCloseAdd = (value) => {
        setOpenAdd(false)
        value && dispatch(getAllInventory(applyFilter))
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
        dispatch(getAllInventory(applyFilter))
    }

    const getFilteredData = () => {
        dispatch(getAllInventory(applyFilter))
        setOpenFilter(false)
    }


    const handleResetFilter = () => {
        setOpenOem(false)
        setOpenApproval(false)
        setOpenState(false)
        setOpenColor(false)
        setOpenVehicle(false)
        setOpenShowroom(false)
    }

    useEffect(() => {
        openOem ? dispatch(getOemDropdown()) : setOpenState(false)
        openState && dispatch(getAllStates())
        openShowroom ? dispatch(getshowroomDropdown({ oemId: userData.OEMId ? userData.OEMId : applyOem, stateId: 7, page: 1 })) : setOpenVehicle(false)
        openVehicle ? dispatch(getVehiclesDropdown({ oemId: userData.OEMId ? userData.OEMId : applyOem, showroomId: applyShowroom })) : setOpenColor(false)
        openColor && dispatch(getColorDropdown({ vehicleId: applyVehicle, showroomId: applyShowroom }))
        openApproval && dispatch(getAllApprovalStatus())
    }, [openOem, openState, openShowroom, openVehicle, openColor, openApproval])


    useEffect(() => {
        statusStatusSuccess && dispatch(getAllInventory(applyFilter))
    }, [statusStatusSuccess])

    useEffect(() => {
        dispatch(getAllApprovalStatus())
        dispatch(getAllInventory(applyFilter))
        userData.OEMId && setOpenOem(true)
    }, [dispatch, page])



    return (
        <div className='container__content'>
            <div className='padded-container'>
                <span className="table_heading">
                    <h1>Inventory</h1>
                    <div className="center-row">
                        <div className="filter_icon_wrapper">
                            <img src={filter_icon} className="filter_icon" onClick={() => setOpenFilter(true)}
                                alt="filter" style={{ cursor: 'pointer', marginRight: 10 }} />

                        </div>

                        {
                            userData?.role?.add_inventory === 1 && <ComponentAddButton title="Add Inventory" onAdd={() => setOpenAdd(true)} />



                        }

                    </div>

                </span>
                {
                    inventoryDataError ? <h3 className="error_value center">{inventoryDataError}</h3>
                        :
                        inventoryDataLoading ? <Loader />
                            :
                            inventoryData?.data?.length < 1 ?
                                <h4 className="error_value center">No Data Available</h4>
                                :
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Vehicle Name</th>
                                            <th>Oem</th>
                                            <th>Showroom</th>
                                            <th>Color</th>
                                            <th>Price</th>
                                            <th>Subsidy</th>
                                            <th>Units</th>
                                            <th>Approval</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            inventoryData?.data?.map((item, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.vehicleName}</td>
                                                    <td>{item.oemName}</td>
                                                    <td>{item.showroomName}</td>
                                                    <td>{item.colorName}</td>
                                                    <td>{item.price}</td>
                                                    <td>{item.subsidy}</td>
                                                    <td>{item.units}</td>
                                                    <td>
                                                        <Dropdown data={userData?.adminGroup === 'cesl admin' ? approvalData?.approvalStatuses : []}
                                                            onChange={(e) => handleStateChange(e, item)} placeholder={ucfirst(item.status)}
                                                            arrowIcon={userData?.adminGroup === 'cesl admin' ? true : false} />
                                                    </td>
                                                    {
                                                        userData?.roles?.edit_inventory === 1 &&
                                                        <td onClick={() => handleEditOpen(item)}>
                                                            <span className="edit-button">
                                                                <img src={edit__icon} alt="edit" className="table_edit_icon" />
                                                            </span>
                                                        </td>
                                                    }

                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            {/* Pagination */}
                                            <td style={{ textAlign: 'right', fontSize: '32px' }} colSpan="14"><span><span onClick={() => page > 1 && setPage(page - 1)}>
                                                <ChevronLeftIcon fontSize="large" /></span> {page}/{inventoryData?.totalPages}
                                                <span onClick={() => page < inventoryData?.totalPages && setPage(page + 1)}><ChevronRightIcon fontSize="large" /></span></span></td>

                                        </tr>
                                    </tbody>
                                </table>
                }

            </div>
            {/* Modal of filter */}
            <Dialog open={openFilter} onClose={handleFilterClose}
                fullWidth={true} maxWidth={'xs'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <img src={modal_filter_icon} alt='filter' />
                                <h2>Inventory Filter</h2>
                                <img src={reset_icon} style={{ cursor: 'pointer' }} onClick={handleResetFilter} alt='reset' />
                            </div>
                        </Grid>
                        {userData.adminGroup === 'cesl admin' && <>
                            <Grid item xs={12}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                    <h2>OEM</h2>
                                    <SwitchButton checked={openOem} onChange={handleOemChange} name="openOem" />
                                </div>
                            </Grid>
                            {
                                openOem &&
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="label">Oem</InputLabel>
                                        <Select
                                            required
                                            id="select-outlined"
                                            value={applyOem}
                                            onChange={e => setApplyOem(e.target.value)}
                                            label="Oem"
                                            fullWidth
                                        >
                                            {
                                                oemData?.oemDetail?.map((oem, i) => (
                                                    <MenuItem key={i} value={oem.id}>{oem.name}</MenuItem>
                                                ))
                                            }

                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                        </>

                        }
                        {/* <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>State</h2>

                                <SwitchButton checked={openState} disabled={openOem ? false : true} onChange={() => setOpenState(!openState)} name="openState" />

                            </div>
                        </Grid>
                        {
                            openState &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="label">State</InputLabel>
                                    <Select

                                        id="select-outlined"
                                        value={applyState}
                                        onChange={e => setApplyState(e.target.value)}
                                        label="State"
                                        fullWidth
                                    >

                                        {
                                            stateData?.states?.map((state, i) => (
                                                <MenuItem key={i} value={state.id}>{state.name}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                        } */}

                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Showroom</h2>
                                <SwitchButton checked={openShowroom} onChange={() => setOpenShowroom(!openShowroom)} name="openShowroom" />
                            </div>
                        </Grid>
                        {
                            openShowroom &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="label">Showroom</InputLabel>
                                    <Select

                                        id="select-outlined"
                                        value={applyShowroom}
                                        onChange={e => setApplyShowroom(e.target.value)}
                                        label="Showroom"
                                        fullWidth
                                    >

                                        {
                                            showroomData?.showroom?.map((showroom, i) => (
                                                <MenuItem key={i} value={showroom.id}>{showroom.name}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Vehicle</h2>
                                <SwitchButton checked={openVehicle}
                                    disabled={openShowroom ? false : true}
                                    onChange={() => setOpenVehicle(!openVehicle)} name="openShowroom" />
                            </div>
                        </Grid>
                        {
                            openVehicle &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="label">Vehicle</InputLabel>
                                    <Select

                                        id="select-outlined"
                                        value={applyVehicle}
                                        onChange={e => setApplyVehicle(e.target.value)}
                                        label="Vehicle"
                                        fullWidth
                                    >

                                        {
                                            vehicleData?.vehicleDetail?.map((vehicle, i) => (
                                                <MenuItem key={i} value={vehicle.id}>{vehicle.name}</MenuItem>
                                            ))
                                        }


                                    </Select>
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Color</h2>
                                <SwitchButton
                                    checked={openColor}
                                    disabled={openVehicle ? false : true}
                                    onChange={() => setOpenColor(!openColor)} name="openColor" />
                            </div>
                        </Grid>
                        {
                            openColor &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="label">Color</InputLabel>
                                    <Select

                                        id="select-outlined"
                                        value={applyColor}
                                        onChange={e => setApplyColor(e.target.value)}
                                        label="Color"
                                        fullWidth
                                    >

                                        {
                                            colorData?.color?.map((color, i) => (
                                                <MenuItem key={i} value={color.colorId}>{color.colorName}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Approval</h2>
                                <SwitchButton checked={openApproval} onChange={() => setOpenApproval(!openApproval)} name="openApproval" />
                            </div>
                        </Grid>
                        {
                            openApproval &&
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        name="approval"
                                        row
                                        value={applyApproval}
                                        onChange={e => setApplyApproval(e.target.value)}>
                                        {
                                            approvalData?.approvalStatuses?.map((item, i) => (
                                                <FormControlLabel key={i} value={item.id}
                                                    control={<Radio color="primary"
                                                        checked={item.id === parseInt(applyApproval)} />}
                                                    label={ucfirst(item.name)} />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={getFilteredData} fullWidth >Apply</Button>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>

            {/* Modal for add */}
            <Dialog open={openAdd} onClose={() => setOpenAdd(false)}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <InventoryForm closeForm={(loadable = false) => handleCloseAdd(loadable)} />

                </DialogContent>
            </Dialog>

            {/* Modal for edit */}
            <Dialog open={openEdit} onClose={() => setOpenEdit(false)}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <EditInventoryForm data={editData} closeForm={handleCloseEdit} />

                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Inventory
