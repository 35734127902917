import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Oem from './Oem';
import { Link } from 'react-router-dom';
import Showroom from './Showroom';
import '../Oem.css'
import { getdecodedItem } from '../../../utils/localStorageHelper';

const OemManagement = () => {

    const [active, setActive] = useState('oem')

    const { component } = useParams();
    const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};

    useEffect(() => {
        setActive(component ? component : 'oem')
    }, [component])

    const setMenu = (item) => {
        setActive(item)
    }

    const renderComponent = (component) => {
        switch (component) {
            case 'oem':
                return <Oem />
            case 'showroom':
                return <Showroom />

            default:
                return <Oem />
        }
    }

    return (
        <div className="view__wrapper">
            <div className="container__sidemenu-wrapper">
                <div className="sub_wrapper">
                    <ul>
                        {

                            <Link to='/oem-management/oem'><li onClick={() => setMenu('oem')} className={active === 'oem' ? 'active' : ''}>Oem</li></Link>

                        }

                        {

                            <Link to='/oem-management/showroom'><li onClick={() => setMenu('showroom')} className={active === 'showroom' ? 'active' : ''}>Showroom</li></Link>
                        }


                    </ul>
                </div>
            </div>
            {/* content  */}

            {/* <Suspense fallback={() => <Loader />}> */}
            {renderComponent(component)}
            {/* </Suspense> */}

        </div>
    )
}

export default OemManagement
