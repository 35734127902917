import React, { useState } from 'react';
import './Auth.css';
import login_image from '../Assets/images/login_image.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../actions/auth';
import { Redirect } from 'react-router-dom'
import { getdecodedItem } from '../../utils/localStorageHelper';
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';


const Login = () => {

    const dispatch = useDispatch();

    const isAuth = useSelector(state => state.authReducer.isAuth);
    const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};


    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const [showPassword, setShowPassword] = useState(false);

    const { email, password } = formData;

    const loginApi = e => {
        e.preventDefault();

        dispatch(loginUser(formData))

    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    // Redirect if is Auth
    if (isAuth) {
        return <Redirect to='/oem-management/oem' />
    }




    return (
        <form onSubmit={loginApi}>
            <div className="login_wrapper">
                <div style={{ backgroundColor: '#e9edff' }}>
                    <img style={{ height: '99.7vh', width: '70vw' }} src={login_image} alt="login" />
                </div>
                <div className="login_form_wrapper">
                    <h1>Sign In</h1>
                    <h4 className="m-t-30" style={{ color: 'rgba(40, 44, 64, 0.6)' }}>Welcome, we missed you!</h4>
                    <div className="m-t-20">
                        <TextField id="outlined-basic" type="text" value={email}
                            required
                            onChange={e => setFormData({ ...formData, email: e.target.value })}
                            fullWidth label="Email" variant="outlined" />
                    </div>
                    <div className="m-t-20">
                        {/* <TextField id="outlined-basic"
                            type="password" value={password}
                            required
                            onChange={e => setFormData({ ...formData, password: e.target.value })}
                            fullWidth label="Password" variant="outlined" 
                            /> */}

                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={e => setFormData({ ...formData, password: e.target.value })}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>

                    </div>
                    <div className="m-t-20">
                        <Button color="primary" type="submit" className="p-10" fullWidth variant="contained">Login</Button>
                    </div>

                </div>
            </div>
        </form>

    )
}

export default Login
