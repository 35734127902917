import { GET_OEM_SUCCESS, GET_OEM_FAILED, GET_SHOWROOM_SUCCESS, GET_SHOWROOM_FAILED, ADD_OEM_SUCCESS, ADD_OEM_FAILED, GET_OEM_RESET, GET_SHOWROOM_RESET, GET_OEM_LIST_FAILED, GET_OEM_LIST_RESET, GET_OEM_LIST_SUCCESS, ADD_OEM_RESET, ADD_SHOWROOM_SUCCESS, ADD_SHOWROOM_RESET, ADD_SHOWROOM_FAILED, GET_SHOWROOM_LIST_RESET, GET_SHOWROOM_LIST_SUCCESS, GET_SHOWROOM_LIST_FAILED, UPDATE_OEM_RESET, UPDATE_OEM_SUCCESS, UPDATE_OEM_FAILED, UPDATE_SHOWROOM_SUCCESS, UPDATE_SHOWROOM_RESET, UPDATE_SHOWROOM_FAILED } from "../actions/types";

const initialState = {
    loading: true,
    data: [],
    error: null,
    add_loading: true,
    add_success: false,
    add_msg: null,
    add_showroom_loading: true,
    add_showroom_success: false,
    add_showroom_msg: null,
    update_showroom_loading: true,
    update_showroom_success: false,
    update_showroom_msg: null,
    update_loading: true,
    update_success: false,
    update_msg: null,
    list_oem_loading: true,
    list_oem_success: false,
    list_oem_data: [],
    list_oem_msg: null,
    list_showroom_loading: true,
    list_showroom_success: false,
    list_showroom_data: [],
    list_showroom_msg: null,


}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_OEM_RESET:
        case GET_SHOWROOM_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: null,
                add_loading: true,
                add_success: false,
                add_msg: null,
                add_showroom_loading: true,
                add_showroom_success: false,
                add_showroom_msg: null,
                update_loading: true,
                update_success: false,
                update_msg: null,
            }
        case GET_OEM_SUCCESS:
        case GET_SHOWROOM_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: null
            }
        case GET_OEM_FAILED:
        case GET_SHOWROOM_FAILED:
            return {
                ...state,
                loading: false,
                data: [],
                error: payload
            }
        case ADD_OEM_RESET:
            return {
                ...state,
                add_loading: true,
                add_success: false,
                add_msg: null
            }
        case ADD_OEM_SUCCESS:
            return {
                ...state,
                add_loading: false,
                add_success: true,
                add_msg: payload
            }
        case ADD_OEM_FAILED:
            return {
                ...state,
                add_loading: false,
                add_success: false,
                add_msg: payload
            }
        case ADD_SHOWROOM_RESET:
            return {
                ...state,
                add_showroom_loading: true,
                add_showroom_success: false,
                add_showroom_msg: null
            }
        case ADD_SHOWROOM_SUCCESS:
            return {
                ...state,
                add_showroom_loading: false,
                add_showroom_success: true,
                add_showroom_msg: payload
            }
        case ADD_SHOWROOM_FAILED:
            return {
                ...state,
                add_showroom_loading: false,
                add_showroom_success: false,
                add_showroom_msg: payload
            }
        case GET_OEM_LIST_RESET:
            return {
                ...state,
                list_oem_loading: true,
                list_oem_success: false,
                list_oem_msg: null,
                list_oem_data: []
            }
        case GET_OEM_LIST_SUCCESS:
            return {
                ...state,
                list_oem_loading: false,
                list_oem_success: true,
                list_oem_data: payload,
            }
        case GET_OEM_LIST_FAILED:
            return {
                ...state,
                list_oem_loading: false,
                list_oem_success: false,
                list_oem_msg: payload.message
            }
        case GET_SHOWROOM_LIST_RESET:
            return {
                ...state,
                list_showroom_loading: true,
                list_showroom_success: false,
                list_showroom_msg: null,
                list_showroom_data: []
            }
        case GET_SHOWROOM_LIST_SUCCESS:
            return {
                ...state,
                list_showroom_loading: false,
                list_showroom_success: true,
                list_showroom_data: payload,
            }
        case GET_SHOWROOM_LIST_FAILED:
            return {
                ...state,
                list_showroom_loading: false,
                list_showroom_success: false,
                list_showroom_msg: payload.message
            }
        case UPDATE_OEM_RESET:
            return {
                ...state,
                update_loading: true,
                update_success: false,
                update_msg: null
            }
        case UPDATE_OEM_SUCCESS:
            return {
                ...state,
                update_loading: false,
                update_success: true,
            }
        case UPDATE_OEM_FAILED:
            return {
                ...state,
                update_loading: false,
                update_success: false,
                update_msg: payload.message
            }
        case UPDATE_SHOWROOM_RESET:
            return {
                ...state,
                update_showroom_loading: true,
                update_showroom_success: false,
                update_showroom_msg: null
            }
        case UPDATE_SHOWROOM_SUCCESS:
            return {
                ...state,
                update_showroom_loading: false,
                update_showroom_success: true,
                update_showroom_msg: payload
            }
        case UPDATE_SHOWROOM_FAILED:
            return {
                ...state,
                update_showroom_loading: false,
                update_showroom_success: false,
                update_showroom_msg: payload
            }
        default:
            return state
    }
}