import React, { useEffect, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';


const Alert = () => {

    const [open, setOpen] = useState(false)

    const alertData = useSelector(state => state.alert.msg)
    const alertType = useSelector(state => state.alert.type)

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        // 
        alertData !== '' &&setOpen(true)
    }, [alertData])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={2700} 
            anchorOrigin={{ vertical:'top', horizontal:'right' }}
            onClose={handleClose}>
                <MuiAlert onClose={handleClose} severity={alertType}>
                   {alertData}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default Alert
