import React from 'react'
import Header from './Header'

const OemLayout = (props) => {
    return (
        <>
        <Header/>
        {props.children}   
        </>
    )
}

export default OemLayout
