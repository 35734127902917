import { HTTPGetRequest, HTTPPutRequest } from "../utils/serverHelper";
import { GET_ORDERS_SUCCESS, GET_ORDERS_FAILED, GET_TEST_DRIVE_SUCCESS, GET_TEST_DRIVE_FAILED, GET_ORDERS_RESET, GET_TEST_DRIVE_RESET, UPDATE_ORDERS_STATUS_RESET, UPDATE_ORDERS_STATUS_SUCCESS, UPDATE_ORDERS_STATUS_FAILED, UPDATE_TEST_DRIVE_STATUS_RESET, UPDATE_TEST_DRIVE_STATUS_SUCCESS, UPDATE_TEST_DRIVE_STATUS_FAILED, DOWNLOAD_ORDER_RESET, DOWNLOAD_ORDER_SUCCESS, DOWNLOAD_ORDER_FAILED, DOWNLOAD_ORDER_LOADING } from "./types";
import ApiConstants from "../constants/ApiConstants";
import { getdecodedItem } from "../utils/localStorageHelper";
import { setAlert } from "./alert";

const MODULE_URL = `${ApiConstants.BASE_URL}/order`;
const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};

export const getAllOrders = (value) => async dispatch => {
    dispatch({ type: GET_ORDERS_RESET })

    try {
        let url = `${MODULE_URL}/v1/order?`
        let query = Object.keys(value).map((k) => {
            if (value[k] !== '') {
                return k + '=' + value[k]
            }
        }).filter(Boolean).join('&')
        let res = await HTTPGetRequest(url + query);
        if (res.status === 200) {
            dispatch({
                type: GET_ORDERS_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_ORDERS_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ORDERS_FAILED,
            payload: error.message
        })
    }

}

export const updateOrdersStatus = (value) => async dispatch => {
    dispatch({ type: UPDATE_ORDERS_STATUS_RESET })

    try {
        let url = `${MODULE_URL}/v1/order`

        let res = await HTTPPutRequest(url, { }, {
            ...value
        });
        if (res.status === 200) {
            dispatch({
                type: UPDATE_ORDERS_STATUS_SUCCESS,
                payload: res.response.message
            })
            dispatch(setAlert('success', res.response.message))
        }
        else {
            dispatch({
                type: UPDATE_ORDERS_STATUS_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {
        dispatch({
            type: UPDATE_ORDERS_STATUS_FAILED,
            payload: error.message
        })
    }

}
export const getAllTestdrives = (value) => async dispatch => {
    dispatch({ type: GET_TEST_DRIVE_RESET })
    try {

        let res = await HTTPGetRequest(`${MODULE_URL}/v1/test-drive?oemId=${value.oemId}&vehicleId=${value.vehicleId}&stateId=${value.stateId}&showroomId=${value.showroomId}&key=${value.key}&page=${value.page}`);

        if (res.status === 200) {
            dispatch({
                type: GET_TEST_DRIVE_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_TEST_DRIVE_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_TEST_DRIVE_FAILED,
            payload: error.message
        })
    }

}

export const updateTestDriveStatus = (value) => async dispatch => {
    dispatch({ type: UPDATE_TEST_DRIVE_STATUS_RESET })

    try {
        let url = `${MODULE_URL}/v1/test-drive`

        let res = await HTTPPutRequest(url, {
            authToken: userData.token
        }, {
            ...value
        });
        if (res.status === 200) {
            dispatch({
                type: UPDATE_TEST_DRIVE_STATUS_SUCCESS,
                payload: res.response.message
            })
            dispatch(setAlert('success', res.response.message))
        }
        else {
            dispatch({
                type: UPDATE_TEST_DRIVE_STATUS_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {
        dispatch({
            type: UPDATE_TEST_DRIVE_STATUS_FAILED,
            payload: error.message
        })
    }

}

export const downloadOrder = (value) => async dispatch => {
    dispatch({ type: DOWNLOAD_ORDER_LOADING })
    try {
        let url = `${MODULE_URL}/v1/download?`
        let query = Object.keys(value).map((k) => {
            if (value[k] !== '') {
                return k + '=' + value[k]
            }
        }).filter(Boolean).join('&')
        let res = await HTTPGetRequest(url + query, {
            authToken: userData.token
        })

        if (res.status === 200) {
            dispatch({ type: DOWNLOAD_ORDER_SUCCESS, payload: res.response })
            dispatch(setAlert('success', res.response.message))
            window.open(res.response.orderReportURL)
        }
        else {
            dispatch({ type: DOWNLOAD_ORDER_FAILED, payload: res.response })
            dispatch(setAlert('error', res.response.message))
        }


    } catch (error) {
        dispatch({ type: DOWNLOAD_ORDER_FAILED, payload: error })
    }

}
