import { combineReducers } from 'redux';
import authReducer from './authReducer';
import oem from './oem';
import vehicle from './vehicle';
import inventory from './inventory';
import orders from './orders';
import user from './user';
import common from './common'
import alert from './alert';
import complaint from './complaint';
export default combineReducers({
    authReducer,
    oem,
    vehicle,
    inventory,
    orders,
    user,
    common,
    alert,
    complaint
})