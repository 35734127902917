import { HTTPGetRequest } from "../utils/serverHelper";
import { GET_STATE_SUCCESS, GET_STATE_FAILED, GET_APPROVAL_SUCCESS, GET_APPROVAL_FAILED, GET_ORDER_STATUS_SUCCESS, GET_ORDER_STATUS_FAILED, GET_ORDER_STATUS_RESET, GET_APPROVAL_RESET, GET_STATE_RESET, GET_ROLES_LOADING, GET_ROLES_SUCCESS, GET_ROLES_FAILED } from "./types";
import ApiConstants from "../constants/ApiConstants";

const MODULE_URL = `${ApiConstants.BASE_URL}/common`;

export const getAllStates = () => async dispatch => {

    dispatch({ type: GET_STATE_RESET })

    try {

        let res = await HTTPGetRequest(`${MODULE_URL}/v1/state`);

        if (res.status === 200) {
            dispatch({
                type: GET_STATE_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_STATE_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_STATE_FAILED,
            payload: error.message
        })
    }

}
export const getAllApprovalStatus = () => async dispatch => {
    dispatch({ type: GET_APPROVAL_RESET })
    try {

        let res = await HTTPGetRequest(`${MODULE_URL}/v1/approval-status`);

        if (res.status === 200) {
            dispatch({
                type: GET_APPROVAL_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_APPROVAL_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_APPROVAL_FAILED,
            payload: error.message
        })
    }

}

export const getAllOrderStatus = () => async dispatch => {
    dispatch({ type: GET_ORDER_STATUS_RESET })
    try {

        let res = await HTTPGetRequest(`${MODULE_URL}/v1/order-status`);

        if (res.status === 200) {
            dispatch({
                type: GET_ORDER_STATUS_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_ORDER_STATUS_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ORDER_STATUS_FAILED,
            payload: error.message
        })
    }

}

export const getAllRoles = () => async dispatch => {
    dispatch({ type: GET_ROLES_LOADING })
    try {

        let res = await HTTPGetRequest(`${MODULE_URL}/v1/roles`);

        if (res.status === 200) {
            dispatch({
                type: GET_ROLES_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_ROLES_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ROLES_FAILED,
            payload: error.message
        })
    }

}