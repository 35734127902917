import React, { useEffect, useState } from 'react'
import filter_icon from '../../Assets/images/filter_icon.svg';
import modal_filter_icon from '../../Assets/images/modal_filter_icon.svg';
import reset_icon from '../../Assets/images/reset_icon.svg';
import ComponentAddButton from '../../Common/ComponentAddButton';
import SwitchButton from '../../Common/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useDispatch, useSelector } from 'react-redux';
import { getCeslAdmins } from '../../../actions/user';
import Loader from '../../Common/Loaders/Loader';
import CeslAdminForm from './CeslAdminForm';
import SearchBar from '../../Common/SearchBar/SearchBar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { getdecodedItem } from '../../../utils/localStorageHelper';

const CeslAdmins = () => {

    const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};
    const dispatch = useDispatch();
    const oemData = useSelector(state => state.user.data)
    const oemDataError = useSelector(state => state.user.error)
    const oemDataLoading = useSelector(state => state.user.loading)

    const [openFilter, setOpenFilter] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);

    const [openState, setOpenState] = useState(false)
    const [openApproval, setOpenApproval] = useState(false)

    const [page, setPage] = useState(1)
    const [searchVal, setSearchVal] = useState("");

    const applyFilters = {
        key: searchVal,
        page: page
    }

    const handleFilterClose = () => {
        setOpenFilter(false)
    }


    /**
  * @method search
  * @description for seaching the value 
  * @param {*} value 
  */

    const search = (value) => {
        setPage(1);
        setSearchVal(value);
    }
    /**
     * @method resetSearch
     * @description for resetting the search
     */

    const resetSearch = () => {
        setPage(1)
        setSearchVal("");
    }



    const handleCloseAdd = (value) => {
        setOpenAdd(false)
        value && dispatch(getCeslAdmins(applyFilters))
    }

    useEffect(() => {
        dispatch(getCeslAdmins(applyFilters))
    }, [dispatch, page, searchVal])

    return (
        <div className='container__content'>
            <div className='padded-container'>
                <span className="table_heading">
                    <h1>TSREDCO Admins</h1>
                    <div className="center-row">
                        <SearchBar initialSearch={(value) => search(value)} resetSearchVal={resetSearch} />
                        {/* <div className="filter_icon_wrapper">
                            <img src={filter_icon} className="filter_icon" onClick={() => setOpenFilter(true)}
                                alt="filter" style={{ cursor: 'pointer', marginRight: 10 }} />

                        </div> */}
                        {
                            // userData?.roles?.add_admin ===1 &&
                            <ComponentAddButton title="Add Admin" onAdd={() => setOpenAdd(true)} />
                        }


                    </div>

                </span>
                {
                    oemDataError ? <h3 className="error_value center">{oemDataError}</h3> :
                        oemDataLoading ? <Loader /> :
                            oemData?.data?.length < 1 ?
                                <h4 className="error_value center">No Data Available</h4>
                                :
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Admin Name</th>
                                            <th>Ph No</th>
                                            <th>Email</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            oemData?.data?.map((item, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.email}</td>

                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            {/* Pagination */}
                                            <td style={{ textAlign: 'right', fontSize: '32px' }} colSpan="14"><span><span onClick={() => page > 1 && setPage(page - 1)}>
                                                <ChevronLeftIcon fontSize="large" /></span> {page}/{oemData?.totalPages}
                                                <span onClick={() => page < oemData?.totalPages && setPage(page + 1)}><ChevronRightIcon fontSize="large" /></span></span></td>

                                        </tr>
                                    </tbody>
                                </table>
                }

            </div>
            {/* Modal of filter */}
            <Dialog open={openFilter} onClose={handleFilterClose}
                fullWidth={true} maxWidth={'xs'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <img src={modal_filter_icon} alt='filter' />
                                <h2>Showroom Filter</h2>
                                <img src={reset_icon} style={{ cursor: 'pointer' }} alt='reset' />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>State</h2>
                                <SwitchButton checked={openState} onChange={() => setOpenState(!openState)} name="openState" />
                            </div>
                        </Grid>
                        {
                            openState &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="label">State</InputLabel>
                                    <Select

                                        id="select-outlined"
                                        // value={age}
                                        // onChange={handleChange}
                                        label="Age"
                                        fullWidth
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Oem</h2>
                                <SwitchButton checked={openApproval} onChange={() => setOpenApproval(!openApproval)} name="openApproval" />
                            </div>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>

            {/* Modal for add */}
            <Dialog open={openAdd} onClose={() => setOpenAdd(false)}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <CeslAdminForm closeForm={(loadable = false) => handleCloseAdd(loadable)} />
                    {/* <ShowroomForm closeForm={()=> setOpenAdd(false)}/> */}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default CeslAdmins
