import { HTTPGetRequest, HTTPRequest } from "../utils/serverHelper";
import { GET_USER_SUCCESS, GET_USER_FAILED, GET_USER_RESET, GET_ADD_OEM_USER_RESET, GET_ADD_OEM_USER_SUCCESS, GET_ADD_OEM_USER_FAILED, GET_ADD_CESL_USER_RESET, GET_ADD_CESL_USER_SUCCESS, GET_ADD_CESL_USER_FAILED } from "./types";
import ApiConstants from "../constants/ApiConstants";
import { getdecodedItem } from "../utils/localStorageHelper";
import { setAlert } from "./alert";

const MODULE_URL = `${ApiConstants.BASE_URL}/user`;
const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};

export const getAllCustomers = (value) => async dispatch => {
    dispatch({ type: GET_USER_RESET })
    try {

        let res = await HTTPGetRequest(`${MODULE_URL}/list?stateId=${value.stateId}&key=${value.key}&page=${value.page}`);

        if (res.status === 200) {
            dispatch({
                type: GET_USER_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_USER_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_USER_FAILED,
            payload: error.message
        })
    }

}

export const getOemAdmins = (value) => async dispatch => {
    dispatch({ type: GET_USER_RESET })
    try {

        let res = await HTTPGetRequest(`${MODULE_URL}/oem-admin?oemId=${value.oemId}&key=${value.key}&page=${value.page}`);

        if (res.status === 200) {
            dispatch({
                type: GET_USER_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_USER_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_USER_FAILED,
            payload: error.message
        })
    }

}

export const addOemAdmins = (value) => async dispatch => {
    dispatch({ type: GET_ADD_OEM_USER_RESET })
    try {

        let res = await HTTPRequest(`${MODULE_URL}/oem-admin`,
            {
                authToken: userData.token
            }, {
            ...value
        });

        if (res.status === 200) {
            dispatch({
                type: GET_ADD_OEM_USER_SUCCESS,
                payload: res.response.message
            })
            dispatch(setAlert('success', res.response.message))
        }
        else {
            dispatch({
                type: GET_ADD_OEM_USER_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {
        dispatch({
            type: GET_ADD_OEM_USER_FAILED,
            payload: error.message
        })
        dispatch(setAlert('error', error.message))
    }

}

export const getCeslAdmins = (value) => async dispatch => {
    dispatch({ type: GET_USER_RESET })
    try {

        let res = await HTTPGetRequest(`${MODULE_URL}/cesl-admin?key=${value.key}&page=${value.page}`);

        if (res.status === 200) {
            dispatch({
                type: GET_USER_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_USER_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_USER_FAILED,
            payload: error.message
        })
    }

}


export const addCeslAdmins = (value) => async dispatch => {
    dispatch({ type: GET_ADD_CESL_USER_RESET })
    try {

        let res = await HTTPRequest(`${MODULE_URL}/cesl-admin`,
            {
                authToken: userData.token
            }, {
            ...value
        });

        if (res.status === 200) {
            dispatch({
                type: GET_ADD_CESL_USER_SUCCESS,
                payload: res.response.message
            })
            dispatch(setAlert('success', res.response.message))
        }
        else {
            dispatch({
                type: GET_ADD_CESL_USER_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {
        dispatch({
            type: GET_ADD_CESL_USER_FAILED,
            payload: error.message
        })
        dispatch(setAlert('error', error.message))
    }

}

