import React from 'react';
import BounceLoader from "react-spinners/BounceLoader";

function Loader(props) {
    return (
        <div style={{width:"100%",height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <BounceLoader loading={true} color="#123abc" size={50}/>
        </div>
    );
}

export default Loader;