import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOemDropdown } from '../../../actions/oem';
import { addVehicles, updateVehicleDetails } from '../../../actions/vehicle';

const VehicleFormComponent = ({ closeForm, editData }) => {

    const dispatch = useDispatch();
    const oemData = useSelector(state => state.oem.list_oem_data);
    const vehicleSuccess = useSelector(state => state.vehicle.add_success);
    const vehicleDetail = useSelector(state => state.vehicle.vehicle_detail_data)
    const vehicleDetailUpdateSuccess = useSelector(state => state.vehicle.update_vehicle_detail_success)
    const [vehicleForm, setVehicleForm] = useState({
        vehicle_id: '',
        name: '',
        topSpeed: '',
        motorPower: '',
        motorType: '',
        motorTorque: '',
        motorRating: '',
        batteryRange: '',
        batteryPower: '',
        batteryType: '',
        breakingSys: '',
        frontBreak: '',
        rearBreak: '',
        frontSuspension: '',
        rearSuspension: '',
        dimensions: '',
        chasis: '',
        weight: '',
        groundClearance: '',
        tyreSize: '',
        wheelSize: '',
        tailLight: '',
        headLight: '',
        launchYear: '',
        specialFeatures: '',
        oem_id: '',
        color: '',
        description: '',
        registrationRequired: ''
    })

    const [colorNum, setColorNum] = useState([
        { name: '', code: '' }
    ])

    const { name, topSpeed, motorPower, motorType, motorTorque, motorRating, batteryRange, batteryPower, batteryType,
        breakingSys, frontBreak, frontSuspension, rearBreak, rearSuspension, dimensions, chasis, weight,
        groundClearance, tyreSize, wheelSize, tailLight, headLight, launchYear,
        specialFeatures, description, oem_id, vehicle_id, registrationRequired } = vehicleForm;

    const onChangeForm = (e) => {
        setVehicleForm({ ...vehicleForm, [e.target.name]: e.target.value })
    }

    const onChangeColorForm = (e) => {
        let value = [...colorNum];
        value[e.target.id][e.target.name] = e.target.value;
        setColorNum(value)
        //setColorNum([...colorNum , colorNum[e.target.id][e.target.name] = e.target.value])
    }

    const addColor = () => {
        setColorNum([...colorNum, { name: '', code: '' }])
    }


    const onChangeOem = (e) => {
        setVehicleForm({ ...vehicleForm, oem_id: e.target.value })
    }


    const removeColorCode = (id) => {
        let value = [...colorNum];
        value.splice(id, 1)
        setColorNum(value)
    }

    const submitData = (e) => {
        e.preventDefault()
        vehicleForm.color = colorNum;
        editData ? dispatch(updateVehicleDetails(vehicleForm)) : dispatch(addVehicles(vehicleForm))

    }

    const handleCloseOemForm = () => {
        setVehicleForm({
            vehicle_id: '',
            name: '',
            topSpeed: '',
            motorPower: '',
            motorType: '',
            motorTorque: '',
            motorRating: '',
            batteryRange: '',
            batteryPower: '',
            batteryType: '',
            breakingSys: '',
            frontBreak: '',
            rearBreak: '',
            frontSuspension: '',
            rearSuspension: '',
            dimensions: '',
            chasis: '',
            weight: '',
            groundClearance: '',
            tyreSize: '',
            wheelSize: '',
            tailLight: '',
            headLight: '',
            launchYear: '',
            specialFeatures: '',
            oem_id: '',
            color: '',
            description: '',
            registrationRequired: ''
        })
        closeForm()
    }

    useEffect(() => {
        vehicleSuccess && closeForm(true)
    }, [vehicleSuccess])

    useEffect(() => {
        vehicleDetailUpdateSuccess && closeForm(true)
    }, [vehicleDetailUpdateSuccess])



    useEffect(() => {
        if (editData && vehicleDetail)
            setVehicleForm({
                name: editData.name,
                vehicle_id: editData.id,
                topSpeed: vehicleDetail.top_speed,
                motorPower: vehicleDetail.motor_power,
                motorType: vehicleDetail.motor_type,
                motorTorque: vehicleDetail.motor_torque,
                motorRating: vehicleDetail.motor_rating,
                batteryRange: vehicleDetail.battery_range,
                batteryPower: vehicleDetail.battery_power,
                batteryType: vehicleDetail.battery_type,
                breakingSys: vehicleDetail.breaking_system,
                frontBreak: vehicleDetail.front_break,
                rearBreak: vehicleDetail.rear_break,
                frontSuspension: vehicleDetail.front_suspension,
                rearSuspension: vehicleDetail.rear_suspension,
                dimensions: vehicleDetail.dimensions,
                chasis: vehicleDetail.chasis,
                weight: vehicleDetail.weight,
                groundClearance: vehicleDetail.ground_clearance,
                tyreSize: vehicleDetail.tyre_size,
                wheelSize: vehicleDetail.wheel_size,
                tailLight: vehicleDetail.tail_light,
                headLight: vehicleDetail.head_light,
                launchYear: vehicleDetail.launch_year,
                specialFeatures: vehicleDetail.special_features,
                description: vehicleDetail.description,
                registrationRequired: vehicleDetail.registrationRequired
            })

    }, [vehicleDetail, editData])

    useEffect(() => {
        dispatch(getOemDropdown())
    }, [])

    return (
        <div>
            <h1>{editData ? 'Edit Vehicle' : 'Add Vehicle'}</h1>
            <form onSubmit={submitData}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h3>Fill The Details</h3>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="name" fullWidth
                            required
                            value={name}
                            onChange={e => onChangeForm(e)}
                            label="Name" />
                    </Grid>
                    <Grid item xs={12}>
                        <h3>Performance</h3>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="topSpeed" fullWidth
                            required
                            value={topSpeed}
                            onChange={e => onChangeForm(e)}
                            label="Top Speed" />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="motorPower" fullWidth
                            required
                            value={motorPower}
                            onChange={e => onChangeForm(e)}
                            label="Motor Power" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="motorType" fullWidth
                            required
                            value={motorType}
                            onChange={e => onChangeForm(e)}
                            label="Motor Type" />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="motorTorque" fullWidth
                            required
                            value={motorTorque}
                            onChange={e => onChangeForm(e)}
                            label="Motor Torque" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="motorRating" fullWidth
                            required
                            value={motorRating}
                            onChange={e => onChangeForm(e)}
                            label="Motor Ip Rating" />
                    </Grid>
                    <Grid item xs={12}>
                        <h3>Battery</h3>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="batteryRange" fullWidth
                            required
                            value={batteryRange}
                            onChange={e => onChangeForm(e)}
                            label="Battery Range" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="batteryPower" fullWidth
                            required
                            value={batteryPower}
                            onChange={e => onChangeForm(e)}
                            label="Battery Power" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="batteryType" fullWidth
                            required
                            value={batteryType}
                            onChange={e => onChangeForm(e)}
                            label="Battery Type" />
                    </Grid>
                    <Grid item xs={12}>
                        <h3>Brake And Suspension</h3>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="breakingSys" fullWidth
                            required
                            value={breakingSys}
                            onChange={e => onChangeForm(e)}
                            label="Battery System" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="frontSuspension" fullWidth
                            required
                            value={frontSuspension}
                            onChange={e => onChangeForm(e)}
                            label="Front Suspension" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="rearSuspension" fullWidth
                            required
                            value={rearSuspension}
                            onChange={e => onChangeForm(e)}
                            label="Rear Suspension" />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="frontBreak" fullWidth
                            required
                            value={frontBreak}
                            onChange={e => onChangeForm(e)}
                            label="Front Break" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="rearBreak" fullWidth
                            required
                            value={rearBreak}
                            onChange={e => onChangeForm(e)}
                            label="Rear Brake" />
                    </Grid>
                    <Grid item xs={12}>
                        <h3>Others</h3>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="dimensions" fullWidth
                            required
                            value={dimensions}
                            onChange={e => onChangeForm(e)}
                            label="dimensions" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="chasis" fullWidth
                            required
                            value={chasis}
                            onChange={e => onChangeForm(e)}
                            label="Chasis" />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="weight" fullWidth
                            required
                            value={weight}
                            onChange={e => onChangeForm(e)}
                            label="Weight" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="groundClearance" fullWidth
                            required
                            value={groundClearance}
                            onChange={e => onChangeForm(e)}
                            label="Ground Clearence" />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="tyreSize" fullWidth
                            required
                            value={tyreSize}
                            onChange={e => onChangeForm(e)}
                            label="Tyre Size" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="wheelSize" fullWidth
                            required
                            value={wheelSize}
                            onChange={e => onChangeForm(e)}
                            label="Wheel Size" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="tailLight" fullWidth
                            required
                            value={tailLight}
                            onChange={e => onChangeForm(e)}
                            label="Tail Light" />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="headLight" fullWidth
                            required
                            value={headLight}
                            onChange={e => onChangeForm(e)}
                            label="Head Light" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="launchYear" fullWidth
                            required
                            value={launchYear}
                            onChange={e => onChangeForm(e)}
                            label="Launch Year" />
                    </Grid>
                    {!editData && <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <h4 onClick={addColor} className="span-btn" >Add New Colour</h4>
                        </div>
                    </Grid>}

                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="specialFeatures" fullWidth
                            value={specialFeatures}
                            required
                            onChange={e => onChangeForm(e)}
                            label="Special Features" />
                    </Grid>
                    {!editData && <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="label">Oem</InputLabel>
                            <Select
                                required
                                id="select-outlined"
                                value={oem_id}
                                onChange={e => onChangeOem(e)}
                                label="Oem"
                                fullWidth
                            >
                                {
                                    oemData?.oemDetail?.map((oem, i) => (
                                        <MenuItem key={i} value={oem.id}>{oem.name}</MenuItem>
                                    ))
                                }

                            </Select>
                        </FormControl>
                    </Grid>}
                    {/* <Grid item xs={2}>
                        <TextField variant="outlined"
                            name="Color Code" fullWidth
                            value={color_code}
                            onChange={e => onChangeForm(e)}
                            label="Color Code" />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField variant="outlined"
                            name="Hex Code" fullWidth
                            value={hex_code}
                            onChange={e => onChangeForm(e)}
                            label="Hex Code" />
                    </Grid> */}

                    {!editData &&
                        colorNum?.map((item, i) => (
                            <>
                                {i !== 0 && <Grid item xs={8}>
                                </Grid>}
                                <Grid item xs={2}>
                                    <TextField variant="outlined"
                                        name="code" fullWidth
                                        id={i}
                                        required
                                        value={colorNum[i].code}
                                        onChange={e => onChangeColorForm(e)}
                                        label="Color Code" />
                                </Grid>
                                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField variant="outlined"
                                        name="name" fullWidth
                                        id={i}
                                        required
                                        value={colorNum[i].name}
                                        style={{ paddingRight: 10 }}
                                        onChange={e => onChangeColorForm(e)}
                                        label="Hex Code" />
                                    <div style={{ cursor: 'pointer' }} onClick={() => removeColorCode(i)}>
                                        &#10006;
                                    </div>
                                </Grid>
                            </>
                        ))
                    }
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            name="description" fullWidth
                            value={description}
                            onChange={e => onChangeForm(e)}
                            label="Description" />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="label">Registration Required</InputLabel>
                            <Select
                                required
                                id="select-outlined"
                                name='registrationRequired'
                                value={registrationRequired}
                                onChange={e => onChangeForm(e)}
                                label="registrationRequired"
                                fullWidth
                            >
                                <MenuItem value="yes">
                                    Yes
                                </MenuItem>
                                <MenuItem value="no" >
                                    NO
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" size="large" type="submit" >Submit</Button>
                        <Button color="primary" onClick={handleCloseOemForm} >Cancel</Button>
                    </Grid>
                </Grid>
            </form>

        </div>
    )
}

export default VehicleFormComponent
