
import { GET_USER_SUCCESS, GET_USER_FAILED, GET_USER_RESET, GET_ADD_OEM_USER_RESET, GET_ADD_OEM_USER_SUCCESS, GET_ADD_OEM_USER_FAILED, GET_ADD_CESL_USER_RESET, GET_ADD_CESL_USER_SUCCESS, GET_ADD_CESL_USER_FAILED } from "../actions/types";

const initialState = {
    loading: true,
    data: [],
    error: null,
    add_loading: true,
    add_success: false,
    add_msg: null,
    cesl_add_loading: true,
    cesl_add_success: false,
    cesl_add_msg: null


}

export default function (state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case GET_USER_RESET:
            return {
                ...state,
                add_loading: true,
                add_success: false,
                add_msg: null,
                cesl_add_loading: true,
                cesl_add_success: false,
                cesl_add_msg: null,
                loading: true,
                data: [],
                error: null
            }
        case GET_USER_SUCCESS:
            return {
                ...state,
                data: payload,
                loading: false,
                error: null
            }
        case GET_USER_FAILED:
            return {
                ...state,
                error: payload,
                data: [],
                loading: false
            }
        case GET_ADD_OEM_USER_RESET:
            return {
                ...state,
                add_loading: true,
                add_success: false,
                add_msg: null
            }
        case GET_ADD_OEM_USER_SUCCESS:
            return {
                ...state,
                add_loading: false,
                add_success: true,

            }
        case GET_ADD_OEM_USER_FAILED:
            return {
                ...state,
                add_loading: false,
                add_success: false,
                add_msg: payload
            }
        case GET_ADD_CESL_USER_RESET:
            return {
                ...state,
                cesl_add_loading: true,
                cesl_add_success: false,
                cesl_add_msg: null
            }
        case GET_ADD_CESL_USER_SUCCESS:
            return {
                ...state,
                cesl_add_loading: false,
                cesl_add_success: true,

            }
        case GET_ADD_CESL_USER_FAILED:
            return {
                ...state,
                cesl_add_loading: false,
                cesl_add_success: false,
                cesl_add_msg: payload
            }
        default:
            return state
    }

}