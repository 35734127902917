import { setItem, removeItem, getdecodedItem } from "./localStorageHelper";
import store from '../store';

export const HTTPRequest = async (url, headers, body) => {
	headers["Content-Type"] = "application/json"
	headers['authToken'] = getdecodedItem('x_auth_token')
	let method = 'POST'
	// //log("Request: {Url:" + url + ",Method:" + method + ",Headers:" + JSON.stringify(headers) + ",body:" + JSON.stringify(body) + "}");
	try {
		let response = await fetch(url, {
			//redirect: 'follow',
			method: method,
			headers: headers,
			body: JSON.stringify(body),
		});
		let responseJson = await response.json();

		// if(response.status===401){
		// 	removeItem('x_auth_token')
		// 	window.location.href = '/'
		// }
		return {
			response: responseJson,
			status: response.status
		};


	} catch (e) {
		//log("HTTP-ERROR : ", JSON.stringify(e))
		return {
			response: { message: "HTTP ERROR" },
			status: 500
		}
	}
}

export const HTTPPutRequest = async (url, headers, body) => {
	headers["Content-Type"] = "application/json"
	headers['authToken'] = getdecodedItem('x_auth_token')
	let method = 'PUT'
	//log("Request: {Url:" + url + ",Method:" + method + ",Headers:" + JSON.stringify(headers) + ",body:" + JSON.stringify(body) + "}");

	try {

		let response = await fetch(url, {
			//redirect: 'follow',
			method: method,
			headers: headers,
			body: JSON.stringify(body),
		});
		let responseJson = await response.json();

		return {
			response: responseJson,
			status: response.status
		};


	} catch (e) {
		return {
			response: { message: "HTTP ERROR" },
			status: 500
		}
	}
}
//Patch
export const HTTPPatchRequest = async (url, headers, body) => {
	headers["Content-Type"] = "application/json"
	headers['authToken'] = getdecodedItem('x_auth_token')
	let method = 'PATCH'
	//log("Request: {Url:" + url + ",Method:" + method + ",Headers:" + JSON.stringify(headers) + ",body:" + JSON.stringify(body) + "}");

	try {

		let response = await fetch(url, {
			//redirect: 'follow',
			method: method,
			headers: headers,
			body: JSON.stringify(body),
		});
		let responseJson = await response.json();

		return {
			response: responseJson,
			status: response.status
		};


	} catch (e) {
		return {
			response: { message: "HTTP ERROR" },
			status: 500
		}
	}
}

//  Delete Request
export const HTTPDeleteRequest = async (url, headers) => {
	headers["Content-Type"] = "application/json"
	headers['authToken'] = getdecodedItem('x_auth_token')
	let method = 'DELETE'
	// //log("Request: {Url:" + url + ",Method:" + method + ",Headers:" + JSON.stringify(headers) + ",body:" + JSON.stringify(body) + "}");

	try {

		let response = await fetch(url, {
			method: method,
			headers: headers,
		});
		let responseJson = await response.json();

		if (response.status === 401) {
			removeItem('x_auth_token')

			window.location.href = '/'
		}

		return {
			response: responseJson,
			status: response.status
		};


	} catch (e) {
		//log("HTTP-ERROR : ", JSON.stringify(e))
		return {
			response: { message: "HTTP ERROR" },
			status: 500
		}
	}
}

export const HTTPFileUploadRequest = async (url, headers, body) => {
	//log("Request: {Url:" + url + ",Headers:" + JSON.stringify(headers) + ",body:" + JSON.stringify(body) + "}");

	try {
		let response = await fetch(url, {
			redirect: 'follow',
			method: 'POST',
			headers: headers,
			body: createFormData(body),
		});
		//let responsetext = await response.text();
		////log("SSS",responsetext)
		let responseJson = await response.json();

		return {
			response: responseJson,
			status: response.status
		};;
	} catch (e) {
		//log("HTTPERR", JSON.stringify(e.message))
		return {
			response: { message: "HTTP ERROR" },
			status: 500
		}
	}
}

const createFormData = (body) => {
	const data = new FormData();

	Object.keys(body).forEach(key => {
		data.append(key, body[key]);
	});
	//log('FormData: ', data)
	//	alert('FormData: '+ JSON.stringify(data))
	return data;
};

export const HTTPGetRequest = async (url, headers = { authToken: getdecodedItem('x_auth_token') }) => {
	//log("Request: {Url:" + url + ",Headers:" + JSON.stringify(headers) + "}");
	headers['authToken'] = getdecodedItem('x_auth_token')
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: headers,
		});
		let responseJson = await response.json();
		if (response.status === 401) {
			removeItem('x_auth_token')

			window.location.href = "/"
		}

		return {
			response: responseJson,
			status: response.status
		};

	} catch (e) {
		////log("HTTP-ERROR : ", JSON.stringify(e))
		return e;
	}
}

export const HTTPPostRequest = async (url, method, headers = null, body = null) => {
	// console.//log("Request: {Url:" + url + ",Method:" + method + ",Headers:" + JSON.stringify(headers) + ",body:" + JSON.stringify(body) + "}");
	const extras = (method === 'POST') ? {
		headers: headers,
		body: JSON.stringify(body)
	} : ''
	try {
		let response = await fetch(url, {
			method: method,
			extras
		});
		let responseJson = await response.json();
		return {
			response: responseJson,
			status: response.status
		};
	} catch (e) {
		return e;
	}
}
/*
 try {
	let response = await fetch(
	  'https://facebook.github.io/react-native/movies.json'
	);
	let responseJson = await response.json();
	return responseJson.movies;
  } catch (error) {
	error(error);
  }

*/

