import { ADD_VEHICLE_COLOR_FAILED, ADD_VEHICLE_COLOR_RESET, ADD_VEHICLE_COLOR_SUCCESS, ADD_VEHICLE_FAILED, ADD_VEHICLE_RESET, ADD_VEHICLE_SUCCESS, GET_VEHICLE_COLOR_FAILED, GET_VEHICLE_COLOR_RESET, GET_VEHICLE_COLOR_SUCCESS, GET_VEHICLE_DETAIL_FAILED, GET_VEHICLE_DETAIL_RESET, GET_VEHICLE_DETAIL_SUCCESS, GET_VEHICLE_FAILED, GET_VEHICLE_LIST_FAILED, GET_VEHICLE_LIST_RESET, GET_VEHICLE_LIST_SUCCESS, GET_VEHICLE_RESET, GET_VEHICLE_SUCCESS, UPDATE_VEHICLE_DETAIL_FAILED, UPDATE_VEHICLE_DETAIL_RESET, UPDATE_VEHICLE_DETAIL_SUCCESS, UPDATE_VEHICLE_FAILED, UPDATE_VEHICLE_RESET, UPDATE_VEHICLE_SUCCESS } from "../actions/types";

const initialState = {
    loading: true,
    data: [],
    error: null,
    add_loading: true,
    add_success: false,
    add_msg: null,
    update_loading: true,
    update_success: false,
    update_msg: null,
    list_vehicle_loading: true,
    list_vehicle_success: false,
    list_vehicle_data: [],
    list_vehicle_msg: null,
    color_vehicle_loading: true,
    color_vehicle_success: false,
    color_vehicle_data: [],
    color_vehicle_msg: null,
    vehicle_detail_loading: true,
    vehicle_detail_success: false,
    vehicle_detail_data: [],
    vehicle_detail_msg: null,
    update_vehicle_detail_loading: true,
    update_vehicle_detail_msg: null,
    update_vehicle_detail_success: null,
    update_vehicle_detail_data: [],
    add_color_loading: true,
    add_color_msg: null,
    add_color_success: false
}

export default function (state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case GET_VEHICLE_RESET:
            return {
                ...state,
                data: [],
                loading: true,
                error: null,
                add_loading: true,
                add_success: false,
                add_msg: null,
                update_loading: false,
                update_success: false,
                update_msg: null,
                update_vehicle_detail_loading: true,
                update_vehicle_detail_msg: null,
                update_vehicle_detail_success: null,
                update_vehicle_detail_data: [],
                add_color_loading: true,
                add_color_msg: null,
                add_color_success: false
            }
        case GET_VEHICLE_SUCCESS:
            return {
                ...state,
                data: payload,
                loading: false
            }
        case GET_VEHICLE_FAILED:
            return {
                ...state,
                error: payload,
                loading: false
            }
        case ADD_VEHICLE_RESET:
            return {
                ...state,
                add_loading: true,
                add_msg: null,
                add_success: null
            }
        case ADD_VEHICLE_SUCCESS:
            return {
                ...state,
                add_loading: false,
                add_msg: payload,
                add_success: true
            }
        case ADD_VEHICLE_FAILED:
            return {
                ...state,
                add_loading: true,
                add_msg: payload,
                add_success: false
            }
        case ADD_VEHICLE_COLOR_RESET:
            return {
                ...state,
                add_color_loading: true,
                add_color_msg: null,
                add_color_success: null
            }
        case ADD_VEHICLE_COLOR_SUCCESS:
            return {
                ...state,
                add_color_loading: false,
                add_color_msg: payload,
                add_color_success: true
            }
        case ADD_VEHICLE_COLOR_FAILED:
            return {
                ...state,
                add_color_loading: true,
                add_color_msg: payload,
                add_color_success: false
            }
        case UPDATE_VEHICLE_RESET:
            return {
                ...state,
                update_loading: true,
                update_msg: null,
                update_success: null
            }
        case UPDATE_VEHICLE_SUCCESS:
            return {
                ...state,
                update_loading: false,
                update_msg: payload,
                update_success: true
            }
        case UPDATE_VEHICLE_FAILED:
            return {
                ...state,
                update_loading: true,
                update_msg: payload,
                update_success: false
            }
        case GET_VEHICLE_LIST_RESET:
            return {
                ...state,
                list_vehicle_loading: true,
                list_vehicle_msg: null,
                list_vehicle_success: null,
                list_vehicle_data: []
            }
        case GET_VEHICLE_LIST_SUCCESS:
            return {
                ...state,
                list_vehicle_loading: false,
                list_vehicle_success: true,
                list_vehicle_data: payload
            }
        case GET_VEHICLE_LIST_FAILED:
            return {
                ...state,
                list_vehicle_loading: true,
                list_vehicle_msg: payload,
                list_vehicle_success: false,
            }
        case GET_VEHICLE_COLOR_RESET:
            return {
                ...state,
                color_vehicle_loading: true,
                color_vehicle_msg: null,
                color_vehicle_success: null,
                color_vehicle_data: []
            }
        case GET_VEHICLE_COLOR_SUCCESS:
            return {
                ...state,
                color_vehicle_loading: false,
                color_vehicle_success: true,
                color_vehicle_data: payload
            }
        case GET_VEHICLE_COLOR_FAILED:
            return {
                ...state,
                color_vehicle_loading: true,
                color_vehicle_msg: payload,
                color_vehicle_success: false,
            }
        case GET_VEHICLE_DETAIL_RESET:
            return {
                ...state,
                vehicle_detail_loading: true,
                vehicle_detail_msg: null,
                vehicle_detail_success: null,
                vehicle_detail_data: []
            }
        case GET_VEHICLE_DETAIL_SUCCESS:
            return {
                ...state,
                vehicle_detail_loading: false,
                vehicle_detail_success: true,
                vehicle_detail_data: payload
            }
        case GET_VEHICLE_DETAIL_FAILED:
            return {
                ...state,
                vehicle_detail_loading: true,
                vehicle_detail_msg: payload,
                vehicle_detail_success: false,
            }
        case UPDATE_VEHICLE_DETAIL_RESET:
            return {
                ...state,
                update_vehicle_detail_loading: true,
                update_vehicle_detail_msg: null,
                update_vehicle_detail_success: null,
                update_vehicle_detail_data: []
            }
        case UPDATE_VEHICLE_DETAIL_SUCCESS:
            return {
                ...state,
                update_vehicle_detail_loading: false,
                update_vehicle_detail_success: true,
                update_vehicle_detail_data: payload
            }
        case UPDATE_VEHICLE_DETAIL_FAILED:
            return {
                ...state,
                update_vehicle_detail_loading: true,
                update_vehicle_detail_msg: payload,
                update_vehicle_detail_success: false,
            }
        default:
            return state
    }

}