import React, { useState, useEffect } from 'react'
import Orders from './Orders';
import TestDrives from './TestDrives';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';


const OrderManagement = () => {

    const [active, setActive] = useState('orders')

    const { component } = useParams();

    useEffect(() => {
        setActive(component ? component : 'orders')
    }, [component])

    const setMenu = (item) => {
        setActive(item)
    }

    const renderComponent = (component) => {
        switch (component) {
            case 'orders':
                return <Orders />
            case 'testdrives':
                return <TestDrives />

            default:
                return <Orders />
        }
    }

    return (
        <div className="view__wrapper">
            <div className="container__sidemenu-wrapper">
                <div className="sub_wrapper">
                    <ul>
                        <Link to='/order-management/orders'><li onClick={() => setMenu('orders')} className={active === 'orders' ? 'active' : ''}>Orders</li></Link>


                        <Link to='/order-management/testdrives'><li onClick={() => setMenu('testdrives')} className={active === 'testdrives' ? 'active' : ''}>Test Drives</li></Link>

                    </ul>
                </div>
            </div>
            {/* content  */}

            {/* <Suspense fallback={() => <Loader />}> */}
            {renderComponent(component)}
            {/* </Suspense> */}

        </div>
    )
}

export default OrderManagement
