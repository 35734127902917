import { HTTPGetRequest, HTTPPutRequest, HTTPRequest } from "../utils/serverHelper";
import { GET_VEHICLE_SUCCESS, GET_VEHICLE_FAILED, GET_VEHICLE_RESET, ADD_VEHICLE_RESET, ADD_VEHICLE_FAILED, ADD_VEHICLE_SUCCESS, GET_VEHICLE_LIST_RESET, GET_VEHICLE_LIST_SUCCESS, GET_VEHICLE_LIST_FAILED, GET_VEHICLE_COLOR_RESET, GET_VEHICLE_COLOR_SUCCESS, GET_VEHICLE_COLOR_FAILED, UPDATE_VEHICLE_RESET, UPDATE_VEHICLE_SUCCESS, UPDATE_VEHICLE_FAILED, GET_VEHICLE_DETAIL_RESET, GET_VEHICLE_DETAIL_SUCCESS, GET_VEHICLE_DETAIL_FAILED, UPDATE_VEHICLE_DETAIL_RESET, UPDATE_VEHICLE_DETAIL_SUCCESS, UPDATE_VEHICLE_DETAIL_FAILED, ADD_VEHICLE_COLOR_RESET, ADD_VEHICLE_COLOR_SUCCESS, ADD_VEHICLE_COLOR_FAILED } from "./types";
import ApiConstants from "../constants/ApiConstants";
import { getdecodedItem } from "../utils/localStorageHelper";
import { setAlert } from "./alert";

const MODULE_URL = `${ApiConstants.BASE_URL}/vehicle`;
const MODULE_ECOM_URL = `${ApiConstants.BASE_URL_ECOM}product`;
const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};

export const getAllVehicles = (value) => async dispatch => {
    dispatch({ type: GET_VEHICLE_RESET })
    try {

        let res = await HTTPGetRequest(`${MODULE_URL}/list?oemId=${value.oemId}&statusId=${value.statusId}&key=${value.key}&page=${value.page}`);

        if (res.status === 200) {
            dispatch({
                type: GET_VEHICLE_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_VEHICLE_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_VEHICLE_FAILED,
            payload: error.message
        })
    }

}

export const getVehiclesDropdown = (value) => async dispatch => {
    dispatch({ type: GET_VEHICLE_LIST_RESET })
    try {

        let res = await HTTPGetRequest(`${MODULE_URL}/v1/search-vehicle?oemId=${value.oemId}&showroomId=${value.showroomId}&searchKey=`);

        if (res.status === 200) {
            dispatch({
                type: GET_VEHICLE_LIST_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_VEHICLE_LIST_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {
        dispatch({
            type: GET_VEHICLE_LIST_FAILED,
            payload: error.message
        })
    }

}

export const getVehicleDetail = (value) => async dispatch => {
    dispatch({ type: GET_VEHICLE_DETAIL_RESET })
    try {

        let res = await HTTPGetRequest(`${MODULE_ECOM_URL}/v1/product-details?vehicleId=${value}`);

        if (res.status === 200) {
            dispatch({
                type: GET_VEHICLE_DETAIL_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_VEHICLE_DETAIL_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {
        dispatch({
            type: GET_VEHICLE_DETAIL_FAILED,
            payload: error.message
        })
    }

}
export const getColorDropdown = (data) => async dispatch => {
    dispatch({ type: GET_VEHICLE_COLOR_RESET })
    try {

        let res = await HTTPGetRequest(`${MODULE_URL}/color?vehicleId=${data.vehicleId}&showroomId=${data.showroomId}`);

        if (res.status === 200) {
            dispatch({
                type: GET_VEHICLE_COLOR_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_VEHICLE_COLOR_FAILED,
                payload: res.response.message
            })
            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {
        dispatch({
            type: GET_VEHICLE_COLOR_FAILED,
            payload: error.message
        })
    }

}

export const addVehicles = (data) => async dispatch => {
    dispatch({ type: ADD_VEHICLE_RESET })
    try {

        let res = await HTTPRequest(`${MODULE_URL}/list`,
            {
                authToken: userData.token
            },
            {
                ...data
            });

        if (res.status === 200) {
            dispatch({
                type: ADD_VEHICLE_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: ADD_VEHICLE_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_VEHICLE_FAILED,
            payload: error.message
        })
    }

}

export const updateVehicles = (statusId, vehicleId) => async dispatch => {
    dispatch({ type: UPDATE_VEHICLE_RESET })
    try {

        let res = await HTTPPutRequest(`${MODULE_URL}/modify-status`,
            {
                authToken: userData.token
            },
            {
                "statusId": statusId,
                "vehicleId": vehicleId
            });

        if (res.status === 200) {
            dispatch({
                type: UPDATE_VEHICLE_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: UPDATE_VEHICLE_FAILED,
                payload: res.response.message
            })
        }

        dispatch(setAlert('success', res.response.message))

    } catch (error) {
        dispatch({
            type: UPDATE_VEHICLE_FAILED,
            payload: error.message
        })

        dispatch(setAlert('error', error.message))
    }

}

export const updateVehicleDetails = (data) => async dispatch => {
    dispatch({ type: UPDATE_VEHICLE_DETAIL_RESET })
    try {

        let res = await HTTPPutRequest(`${MODULE_URL}/list`,
            {
                authToken: userData.token
            },
            {
                ...data
            });

        if (res.status === 200) {
            dispatch({
                type: UPDATE_VEHICLE_DETAIL_SUCCESS,
                payload: res.response
            })
            dispatch(setAlert('success', res.response.message))
        }
        else {
            dispatch({
                type: UPDATE_VEHICLE_DETAIL_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: UPDATE_VEHICLE_DETAIL_FAILED,
            payload: error.message
        })
        dispatch(setAlert('error', error.message))
    }

}

export const addVehicleColor = (data) => async dispatch => {
    dispatch({ type: ADD_VEHICLE_COLOR_RESET })
    try {

        let res = await HTTPRequest(`${MODULE_URL}/color`,
            {
                authToken: userData.token
            },
            {
                "vehicle_id": data.vehicle_id,
                "name": data.colorName,
                "hex_code": data.colorHexCode

            });

        if (res.status === 200) {
            dispatch({
                type: ADD_VEHICLE_COLOR_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: ADD_VEHICLE_COLOR_FAILED,
                payload: res.response.message
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_VEHICLE_COLOR_FAILED,
            payload: error.message
        })
    }

}
