import React, { useEffect, useState } from 'react';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import './Select.css'
import { ucfirst } from '../../../utils/common';

const Dropdown = (props) => {
    const [isOpened, setOpened] = useState(false)
    const [isClicked, setClicked] = useState(false)
    const [selectedItem, setSelectItem] = useState('--Select--')

    window.addEventListener('click', (e) => {
        e.stopPropagation();
        if (e.target.id != "stq-select")
            setOpened(false)

    });

    useEffect(() => {
        if (props.placeholder) {
            setSelectItem(props.placeholder)
        } else {
            props.data?.length && setSelectItem(props.data[0].name)
        }
    }, [])
    useEffect(() => {
        if (isClicked) {
            // setOpened(false)
        }
    }, [isClicked])

    const getArrowTint = () => {
        return isOpened ? (props.activeColor) ? props.activeColor : '#fff' : (props.tintColor) ? props.tintColor : '#fff'
    }
    return (
        <div style={{ position: 'relative' }}>
            <div id="stq-select" className={`select ${isOpened && 'select-clicked'} ${props.className}`} style={props.style} onClick={() => isOpened ? setOpened(false) : setOpened(true)} >
                <span>{selectedItem}</span>
                {props.arrowIcon &&
                    <>
                        {
                            !isOpened ? <ExpandMoreOutlinedIcon style={{ color: getArrowTint() }} /> :
                                <ExpandLessOutlinedIcon style={{ color: getArrowTint() }} />
                        }

                    </>}
            </div>
            {isOpened && <div className='select__options'>
                <ul>
                    {props.data.map((item, index) => {
                        return (<li
                            key={index.toString()}
                            onClick={() => {

                                item.name !== 'Delivered' && setSelectItem(item.name)
                                setOpened(false)
                                props.onChange(item)
                            }}>{ucfirst(item.name)}</li>)
                    })}
                </ul>
            </div>}
        </div>
    );
}

export default Dropdown