import React, { useState } from 'react';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import './SearchBar.css';

const SearchBar = (props) => {

    const [searchVal, setSearchVal] = useState("");


    const search = e => {
        props.initialSearch(searchVal);
    }

    const resetSearch = () => {
        setSearchVal("")
        props.resetSearchVal();
    }

    return (
        <div className='search__view'>
            <input type="text" placeholder='Search' value={searchVal} onChange={e => setSearchVal(e.target.value)}
                onKeyPress={event => {
                    if (event.key === 'Enter') {
                        { search() }
                    }
                }} />


            {
                searchVal !== "" ? <CancelIcon alt="reset" fontSize='large' style={{ cursor: 'pointer' }} className='search_icon' onClick={resetSearch} />
                    : <SearchRoundedIcon className='search_icon' fontSize='large' onClick={search} style={{ cursor: 'pointer' }} />
            }

        </div>


    )
}

export default SearchBar;
