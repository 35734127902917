import React, { useState } from 'react'
import filter_icon from '../../Assets/images/filter_icon.svg';
import modal_filter_icon from '../../Assets/images/modal_filter_icon.svg';
import reset_icon from '../../Assets/images/reset_icon.svg';
import edit__icon from '../../Assets/images/edit__icon.svg';
import ComponentAddButton from '../../Common/ComponentAddButton';
import SwitchButton from '../../Common/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ShowroomForm from './ShowroomForm';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getOemDropdown, getShowroomDetails } from '../../../actions/oem';
import { getAllStates } from '../../../actions/common';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Loader from '../../Common/Loaders/Loader';
import { getdecodedItem } from '../../../utils/localStorageHelper';

const Showroom = () => {

    const dispatch = useDispatch();
    const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};
    const showroomData = useSelector(state => state.oem.data)
    const showroomDataLoading = useSelector(state => state.oem.loading)
    const error = useSelector(state => state.oem.error)
    const stateData = useSelector(state => state.common.state_data);
    const oemData = useSelector(state => state.oem.list_oem_data);



    const [openFilter, setOpenFilter] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);

    const [openState, setOpenState] = useState(false)
    const [openOem, setOpenOem] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)

    const [page, setPage] = useState(1);

    const [applyState, setApplyState] = useState(0);
    const [applyOem, setApplyOem] = useState(0);
    const [editData, setEditData] = useState([])

    const applyFilter = {
        stateId: openState ? applyState : 0,
        oemId: openOem ? applyOem : 0,
        page: page
    }



    const handleFilterClose = () => {
        setOpenFilter(false)
    }

    const handleFilterApply = () => {
        setPage(1)
        dispatch(getShowroomDetails(applyFilter))
        setOpenFilter(false)
    }

    const handleResetFilter = () => {
        setOpenOem(false)
        setOpenState(false)
    }
    const handleOpenAdd = () => {
        setOpenAdd(true)
        dispatch(getOemDropdown())
        dispatch(getAllStates())
    }

    const handleCloseAdd = (val) => {
        setOpenAdd(false)
        val && dispatch(getShowroomDetails(applyFilter));

    }

    const handleCloseEdit = (val) => {
        setOpenEdit(false)
        val && dispatch(getShowroomDetails(applyFilter));

    }

    const handleEditOpen = (data) => {
        setOpenEdit(true)
        setEditData(data)
    }


    useEffect(() => {
        dispatch(getShowroomDetails(applyFilter));
    }, [dispatch, page])

    useEffect(() => {

        openState && dispatch(getAllStates())
    }, [openState])
    useEffect(() => {

        openOem && dispatch(getOemDropdown())
    }, [openOem])





    return (
        <div className='container__content'>
            <div className='padded-container'>
                <span className="table_heading">
                    <h1>Showroom</h1>
                    <div className="center-row">
                        <div className="filter_icon_wrapper">
                            <img src={filter_icon} className="filter_icon" onClick={() => setOpenFilter(true)}
                                alt="filter" style={{ cursor: 'pointer', marginRight: 10 }} />

                        </div>
                        {
                            userData?.role?.add_showroom === 1 &&
                            <ComponentAddButton title="Add Showroom" onAdd={() => handleOpenAdd(true)} />

                        }

                    </div>

                </span>
                {
                    error ? <h3 className="error_value center">{error}</h3> :
                        <>

                            {
                                showroomDataLoading ? <Loader /> :
                                    showroomData?.showroomDetail?.length < 0 ?
                                        <h4 className="error_value center">No Data Available</h4>
                                        :
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Name</th>
                                                    <th>Location</th>
                                                    <th>State</th>
                                                    <th>Address</th>
                                                    <th>Contact Number</th>
                                                    <th>Oem</th>
                                                </tr>
                                            </thead>


                                            <tbody>
                                                {
                                                    showroomData?.showroomDetail?.map((item, i) => (
                                                        <tr>
                                                            <td>{i + 1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.latitude} {item.longitude}</td>
                                                            <td>{item.state}</td>
                                                            <td>{item.streetAddress}</td>
                                                            <td>{item.phone}</td>
                                                            <td>{item.oem}</td>
                                                            <td>
                                                                <span className="edit-button" onClick={() => handleEditOpen(item)} >
                                                                    <img src={edit__icon} alt="edit" className="table_edit_icon" />
                                                                </span>

                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr>
                                                    {/* Pagination */}
                                                    <td style={{ textAlign: 'right', fontSize: '32px' }} colSpan="14"><span><span onClick={() => page > 1 && setPage(page - 1)}>
                                                        <ChevronLeftIcon fontSize="large" /></span> {page}/{showroomData?.totalPages}
                                                        <span onClick={() => page < showroomData?.totalPages && setPage(page + 1)}><ChevronRightIcon fontSize="large" /></span></span></td>

                                                </tr>
                                            </tbody>


                                        </table>
                            }

                        </>
                }

            </div>
            {/* Modal of filter */}
            <Dialog open={openFilter} onClose={handleFilterClose}
                fullWidth={true} maxWidth={'xs'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <img src={modal_filter_icon} alt='filter' />
                                <h2>Showroom Filter</h2>

                                <img src={reset_icon} style={{ cursor: 'pointer' }} onClick={handleResetFilter} alt='reset' />
                            </div>
                        </Grid>
                        {userData.adminGroup === 'cesl admin' &&
                            <>
                                <Grid item xs={12}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                        <h2>OEM</h2>
                                        <SwitchButton checked={openOem} onChange={() => setOpenOem(!openOem)} name="openOEM" />
                                    </div>
                                </Grid>
                                {
                                    openOem &&
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="label">Oem</InputLabel>
                                            <Select
                                                required
                                                id="select-outlined"
                                                value={applyOem}
                                                name="oemId"
                                                onChange={e => setApplyOem(e.target.value)}
                                                label="Oem"
                                                fullWidth
                                            >
                                                {
                                                    oemData?.oemDetail?.map((oem, i) => (
                                                        <MenuItem key={i} value={oem.id}>{oem.name}</MenuItem>
                                                    ))
                                                }

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                            </>
                        }
                       

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" fullWidth
                                onClick={handleFilterApply}>Apply</Button>
                        </Grid>

                    </Grid>

                </DialogContent>
            </Dialog>

            {/* Modal for add */}
            <Dialog open={openAdd} onClose={() => handleCloseAdd(false)}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <ShowroomForm closeForm={(value) => handleCloseAdd(value)} />
                </DialogContent>
            </Dialog>
            {/* Modal for edit */}
            <Dialog open={openEdit} onClose={() => setOpenEdit(false)}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <ShowroomForm editData={editData} closeForm={(value) => handleCloseEdit(value)} />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Showroom
