import { HTTPGetRequest, HTTPPatchRequest } from "../utils/serverHelper";
import { GET_COMPLAINT_FAILED, GET_COMPLAINT_SUCCESS, GET_COMPLAINT_LOADING, MODIFY_COMPLAINT_RESET, MODIFY_COMPLAINT_SUCCESS, MODIFY_COMPLAINT_FAILED } from "./types";
import ApiConstants from "../constants/ApiConstants";
import { getdecodedItem } from "../utils/localStorageHelper";
import { setAlert } from "./alert";

const MODULE_URL = `${ApiConstants.BASE_URL}/complaint/`;
const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};


export const getAllComplaint = (data) => async dispatch => {
    dispatch({ type: GET_COMPLAINT_LOADING })
    dispatch({ type: MODIFY_COMPLAINT_RESET })
    try {

        let res = await HTTPGetRequest(`${MODULE_URL}?page=${data}`, {
            authToken: userData?.token
        });

        if (res.status === 200) {
            dispatch({
                type: GET_COMPLAINT_SUCCESS,
                payload: res.response
            })
        }
        else {
            dispatch({
                type: GET_COMPLAINT_FAILED,
                payload: res.response.message
            })

            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {
        dispatch({
            type: GET_COMPLAINT_FAILED,
            payload: error.message
        })
        dispatch(setAlert('error', error))
    }

}

export const modifyComplaintStatus = (data) => async dispatch => {
    dispatch({ type: MODIFY_COMPLAINT_RESET })
    try {

        let res = await HTTPPatchRequest(`${MODULE_URL}`, {
            authToken: userData?.token
        }, {
            ...data
        });

        if (res.status === 200) {
            dispatch({
                type: MODIFY_COMPLAINT_SUCCESS,
                payload: res.response
            })
            dispatch(setAlert('success', res.response.message))
        }
        else {
            dispatch({
                type: MODIFY_COMPLAINT_FAILED,
                payload: res.response.message
            })

            dispatch(setAlert('error', res.response.message))
        }

    } catch (error) {
        dispatch({
            type: MODIFY_COMPLAINT_FAILED,
            payload: error.message
        })
        dispatch(setAlert('error', error.message))
    }

}