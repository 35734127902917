export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const LOAD_USER = 'LOAD_USER';
export const AUTH_ERR = 'AUTH_ERR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';

export const GET_OEM_RESET = 'GET_OEM_RESET';
export const GET_OEM_SUCCESS = 'GET_OEM_SUCCESS';
export const GET_OEM_FAILED = 'GET_OEM_FAILED';

export const GET_OEM_LIST_RESET = 'GET_OEM_LIST_RESET';
export const GET_OEM_LIST_SUCCESS = 'GET_OEM_LIST_SUCCESS';
export const GET_OEM_LIST_FAILED = 'GET_OEM_LIST_FAILED';

export const GET_SHOWROOM_LIST_RESET = 'GET_SHOWROOM_LIST_RESET';
export const GET_SHOWROOM_LIST_SUCCESS = 'GET_SHOWROOM_LIST_SUCCESS';
export const GET_SHOWROOM_LIST_FAILED = 'GET_SHOWROOM_LIST_FAILED';

export const ADD_OEM_RESET = 'ADD_OEM_RESET';
export const ADD_OEM_SUCCESS = 'ADD_OEM_SUCCESS';
export const ADD_OEM_FAILED = 'ADD_OEM_FAILED';

export const UPDATE_OEM_RESET = 'UPDATE_OEM_RESET';
export const UPDATE_OEM_SUCCESS = 'UPDATE_OEM_SUCCESS';
export const UPDATE_OEM_FAILED = 'UPDATE_OEM_FAILED';

export const ADD_SHOWROOM_RESET = 'ADD_SHOWROOM_RESET';
export const ADD_SHOWROOM_SUCCESS = 'ADD_SHOWROOM_SUCCESS';
export const ADD_SHOWROOM_FAILED = 'ADD_SHOWROOM_FAILED';

export const UPDATE_SHOWROOM_RESET = 'UPDATE_SHOWROOM_RESET';
export const UPDATE_SHOWROOM_SUCCESS = 'UPDATE_SHOWROOM_SUCCESS';
export const UPDATE_SHOWROOM_FAILED = 'UPDATE_SHOWROOM_FAILED';

export const GET_SHOWROOM_RESET = 'GET_SHOWROOM_RESET';
export const GET_SHOWROOM_SUCCESS = 'GET_SHOWROOM_SUCCESS';
export const GET_SHOWROOM_FAILED = 'GET_SHOWROOM_FAILED';

export const GET_VEHICLE_LIST_RESET = 'GET_VEHICLE_LIST_RESET';
export const GET_VEHICLE_LIST_SUCCESS = 'GET_VEHICLE_LIST_SUCCESS';
export const GET_VEHICLE_LIST_FAILED = 'GET_VEHICLE_LIST_FAILED';

export const GET_VEHICLE_COLOR_RESET = 'GET_VEHICLE_COLOR_RESET';
export const GET_VEHICLE_COLOR_SUCCESS = 'GET_VEHICLE_COLOR_SUCCESS';
export const GET_VEHICLE_COLOR_FAILED = 'GET_VEHICLE_COLOR_FAILED';

export const GET_VEHICLE_RESET = 'GET_VEHICLE_RESET';
export const GET_VEHICLE_SUCCESS = 'GET_VEHICLE_SUCCESS';
export const GET_VEHICLE_FAILED = 'GET_VEHICLE_FAILED';

export const ADD_VEHICLE_RESET = 'ADD_VEHICLE_RESET';
export const ADD_VEHICLE_SUCCESS = 'ADD_VEHICLE_SUCCESS';
export const ADD_VEHICLE_FAILED = 'ADD_VEHICLE_FAILED';

export const ADD_VEHICLE_COLOR_RESET = 'ADD_VEHICLE_COLOR_RESET';
export const ADD_VEHICLE_COLOR_SUCCESS = 'ADD_VEHICLE_COLOR_SUCCESS';
export const ADD_VEHICLE_COLOR_FAILED = 'ADD_VEHICLE_COLOR_FAILED';

export const UPDATE_VEHICLE_RESET = 'UPDATE_VEHICLE_RESET';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_FAILED = 'UPDATE_VEHICLE_FAILED';

export const GET_INVENTORY_RESET = 'GET_INVENTORY_RESET';
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';
export const GET_INVENTORY_FAILED = 'GET_INVENTORY_FAILED';

export const ADD_INVENTORY_RESET = 'ADD_INVENTORY_RESET';
export const ADD_INVENTORY_SUCCESS = 'ADD_INVENTORY_SUCCESS';
export const ADD_INVENTORY_FAILED = 'ADD_INVENTORY_FAILED';

export const UPDATE_INVENTORY_RESET = 'UPDATE_INVENTORY_RESET';
export const UPDATE_INVENTORY_SUCCESS = 'UPDATE_INVENTORY_SUCCESS';
export const UPDATE_INVENTORY_FAILED = 'ADD_INVENTORY_FAILED';

export const UPDATE_INVENTORY_STATUS_RESET = 'UPDATE_INVENTORY_STATUS_RESET';
export const UPDATE_INVENTORY_STATUS_SUCCESS = 'UPDATE_INVENTORY_STATUS_SUCCESS';
export const UPDATE_INVENTORY_STATUS_FAILED = 'ADD_INVENTORY_STATUS_FAILED';


export const GET_ORDERS_RESET = 'GET_ORDERS_RESET';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED';

export const UPDATE_ORDERS_STATUS_RESET = 'UPDATE_ORDERS_STATUS_RESET';
export const UPDATE_ORDERS_STATUS_SUCCESS = 'UPDATE_ORDERS_STATUS_SUCCESS';
export const UPDATE_ORDERS_STATUS_FAILED = 'UPDATE_ORDERS_STATUS_FAILED';

export const GET_TEST_DRIVE_RESET = 'GET_TEST_DRIVE_RESET';
export const GET_TEST_DRIVE_SUCCESS = 'GET_TEST_DRIVE_SUCCESS';
export const GET_TEST_DRIVE_FAILED = 'GET_TEST_DRIVE_FAILED';

export const UPDATE_TEST_DRIVE_STATUS_RESET = 'UPDATE_TEST_DRIVE_STATUS_RESET';
export const UPDATE_TEST_DRIVE_STATUS_SUCCESS = 'UPDATE_TEST_DRIVE_STATUS_SUCCESS';
export const UPDATE_TEST_DRIVE_STATUS_FAILED = 'UPDATE_DRIVE_STATUS_FAILED';

export const GET_USER_RESET = 'GET_USER_RESET';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const GET_ADD_OEM_USER_RESET = 'GET_ADD_OEM_USER_RESET';
export const GET_ADD_OEM_USER_SUCCESS = 'GET_ADD_OEM_USER_SUCCESS';
export const GET_ADD_OEM_USER_FAILED = 'GET_ADD_OEM_USER_FAILED';

export const GET_ADD_CESL_USER_RESET = 'GET_ADD_CESL_USER_RESET';
export const GET_ADD_CESL_USER_SUCCESS = 'GET_ADD_CESL_USER_SUCCESS';
export const GET_ADD_CESL_USER_FAILED = 'GET_ADD_CESL_USER_FAILED';

export const GET_STATE_RESET = 'GET_STATE_RESET';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';
export const GET_STATE_FAILED = 'GET_STATE_FAILED';

export const GET_APPROVAL_RESET = 'GET_APPROVAL_RESET';
export const GET_APPROVAL_SUCCESS = 'GET_APPROVAL_SUCCESS';
export const GET_APPROVAL_FAILED = 'GET_APPROVAL_FAILED';

export const GET_ORDER_STATUS_RESET = 'GET_ORDER_STATUS_RESET';
export const GET_ORDER_STATUS_SUCCESS = 'GET_ORDER_STATUS_SUCCESS';
export const GET_ORDER_STATUS_FAILED = 'GET_ORDER_STATUS_FAILED';

export const GET_COMPLAINT_LOADING = 'GET_COMPLAINT_LOADING';
export const GET_COMPLAINT_SUCCESS = 'GET_COMPLAINT_SUCCESS';
export const GET_COMPLAINT_FAILED = 'GET_COMPLAINT_FAILED';

export const GET_ROLES_LOADING = 'GET_ROLES_LOADING';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILED = 'GET_ROLES_FAILED';

export const DOWNLOAD_ORDER_RESET = 'DOWNLOAD_ORDER_RESET';
export const DOWNLOAD_ORDER_LOADING = 'DOWNLOAD_ORDER_LOADING';
export const DOWNLOAD_ORDER_SUCCESS = 'DOWNLOAD_ORDER_SUCCESS';
export const DOWNLOAD_ORDER_FAILED = 'GET_DOWNLOAD_ORDER_FAILED';


export const GET_VEHICLE_DETAIL_RESET = 'GET_VEHICLE_DETAIL_RESET';
export const GET_VEHICLE_DETAIL_SUCCESS = 'GET_VEHICLE_DETAIL_SUCCESS';
export const GET_VEHICLE_DETAIL_FAILED = 'GET_VEHICLE_DETAIL_FAILED';


export const UPDATE_VEHICLE_DETAIL_RESET = 'UPDATE_VEHICLE_DETAIL_RESET';
export const UPDATE_VEHICLE_DETAIL_SUCCESS = 'UPDATE_VEHICLE_DETAIL_SUCCESS';
export const UPDATE_VEHICLE_DETAIL_FAILED = 'UPDATE_VEHICLE_DETAIL_FAILED';


export const MODIFY_COMPLAINT_RESET = 'MODIFY_COMPLAINT_RESET';
export const MODIFY_COMPLAINT_SUCCESS = 'MODIFY_COMPLAINT_SUCCESS';
export const MODIFY_COMPLAINT_FAILED = 'MODIFY_COMPLAINT_FAILED';


export const OTP_RESET = 'OTP_RESET';
export const OTP_SUCCESS = 'OTP_SUCCESS';
export const OTP_FAILED = 'OTP_FAILED';
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS';
export const OTP_VERIFY_FAILED = 'OTP_VERIFY_FAILED';
export const OTP_RESEND_SUCCESS = 'OTP_RESEND_SUCCESS';
