import React, { useState } from 'react'
import filter_icon from '../../Assets/images/filter_icon.svg';
import modal_filter_icon from '../../Assets/images/modal_filter_icon.svg';
import reset_icon from '../../Assets/images/reset_icon.svg';
import edit__icon from '../../Assets/images/edit__icon.svg';
import ComponentAddButton from '../../Common/ComponentAddButton';
import SwitchButton from '../../Common/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from "@material-ui/core/styles";
import VehicleFormComponent from './VehicleForm';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { addVehicleColor, getAllVehicles, getVehicleDetail, updateVehicles } from '../../../actions/vehicle';
import Dropdown from '../../Common/Select/Select';
import { getAllApprovalStatus } from '../../../actions/common';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ucfirst } from '../../../utils/common';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Loader from '../../Common/Loaders/Loader';
import { getOemDropdown } from '../../../actions/oem';
import { getdecodedItem } from '../../../utils/localStorageHelper';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
    root: {
        backgroundColor: '#203594',
    },
    label: {
        color: "#fff",
        "&.Mui-focused": {
            color: "#fff",
        },
    },
    select: {
        "& .MuiSvgIcon-root": {
            color: "#fff",
        },

    },

})


const Vehicles = () => {

    const classes = useStyles();
    const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};
    const dispatch = useDispatch();
    const vehicleData = useSelector(state => state.vehicle.data)
    const vehicleDataError = useSelector(state => state.vehicle.error)
    const vehicleDataLoading = useSelector(state => state.vehicle.loading)
    const vehicleStatusSuccess = useSelector(state => state.vehicle.update_success)
    const addColorSuccess = useSelector(state => state.vehicle.add_color_success)
    const approvalData = useSelector(state => state.common.approval_data);
    const oemData = useSelector(state => state.oem.list_oem_data);

    const [openFilter, setOpenFilter] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openColor, setOpenColor] = useState(false)

    const [openState, setOpenState] = useState(false)
    const [openApproval, setOpenApproval] = useState(false)

    const [colorData, setColorData] = useState([])

    const [editData, setEditData] = useState({})

    const [page, setPage] = useState(1)

    const [applyState, setApplyState] = useState('');
    const [applyStatus, setApplyStatus] = useState('');

    const [viewForm, setViewForm] = useState(false)

    const applyFilter = {
        oemId: openState ? applyState : '',
        statusId: openApproval ? applyStatus : '',
        key: '',
        page: page
    }

    const [colorForm, setColorForm] = useState({
        vehicle_id: '',
        colorName: '',
        colorHexCode: ''
    })

    const { colorName, colorHexCode } = colorForm;


    const handleFilterClose = () => {
        setOpenFilter(false)
    }


    const selectData = [
        { id: 'approved', name: 'Approved' },
        { id: 'not_approved', name: 'Not Approved' },
    ]

    const handleStateChange = (e, value) => {
        dispatch(updateVehicles(e.id, value.id))
        // setSelectedState(state)
    }

    const handleFilterApply = () => {
        dispatch(getAllVehicles(applyFilter))
        setOpenFilter(false)
    }

    const handleResetFilter = () => {
        setOpenApproval(false)
        setOpenState(false)
    }

    const handleCloseAdd = (value) => {
        setOpenAdd(false)
        value && dispatch(getAllVehicles(applyFilter))
    }

    const handleCloseEdit = (value) => {
        setOpenEdit(false)
        value && dispatch(getAllVehicles(applyFilter))
    }

    const handleEditOpen = (data) => {
        setOpenEdit(true)
        dispatch(getVehicleDetail(data.id))
        setEditData(data)
    }

    const handleOpenColor = (data) => {
        setColorForm({ ...colorForm, vehicle_id: data.id })
        setOpenColor(true)
        setColorData(data.color)
    }

    const onChangeForm = (e) => {
        setColorForm({ ...colorForm, [e.target.name]: e.target.value })
    }

    const submitForm = () => {
        console.log(colorForm)
        dispatch(addVehicleColor(colorForm))
    }

    const handleColorClose = () => {
        setOpenColor(false)
        setViewForm(false)
    }

    useEffect(() => {
        vehicleStatusSuccess && dispatch(getAllVehicles(applyFilter))

    }, [vehicleStatusSuccess])

    useEffect(() => {
        dispatch(getAllVehicles(applyFilter))
        dispatch(getAllApprovalStatus())
    }, [dispatch, page])

    useEffect(() => {
        openState && dispatch(getOemDropdown())
        openApproval && dispatch(getAllApprovalStatus())
    }, [openState, openApproval])

    useEffect(() => {
        if (addColorSuccess) {
            handleColorClose()
            dispatch(getAllVehicles(applyFilter))
        }
    }, [addColorSuccess])


    return (
        <div className='container__content'>
            <div className='padded-container'>
                <span className="table_heading">
                    <h1>Vehicles</h1>
                    <div className="center-row">
                        <div className="filter_icon_wrapper">
                            <img src={filter_icon} className="filter_icon" onClick={() => setOpenFilter(true)}
                                alt="filter" style={{ cursor: 'pointer', marginRight: 10 }} />

                        </div>
                        {
                            userData?.role?.add_vehicle === 1 &&
                            <ComponentAddButton title="Add Vehicle" onAdd={() => setOpenAdd(true)} />
                        }


                    </div>

                </span>
                {
                    vehicleDataError ? <h4 className="error_value center">{vehicleDataError}</h4> :
                        vehicleDataLoading ? <Loader /> :
                            vehicleData?.data?.length < 1 ?
                                <h4 className="error_value center">No Data Available</h4>
                                :
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Oem</th>
                                            <th>Description</th>
                                            <th>Colour</th>
                                            <th>Approval</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            vehicleData?.data?.map((item, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.oemName}</td>
                                                    <td>{item.description}</td>
                                                    <td>
                                                        <>
                                                            {item.color.map((item, i) => {
                                                                return (
                                                                    item.colorName
                                                                )
                                                            }).join()}
                                                            <img src={edit__icon} alt="edit" className="column_edit_icon" onClick={() => handleOpenColor(item)} />
                                                        </>
                                                    </td>
                                                    <td>
                                                        <Dropdown data={userData?.adminGroup === 'cesl admin' ? approvalData?.approvalStatuses : []}
                                                            onChange={(e) => handleStateChange(e, item)} placeholder={ucfirst(item.status)}
                                                            arrowIcon={userData?.adminGroup === 'cesl admin' ? true : false} />

                                                    </td>
                                                    <td>
                                                        <span className="edit-button" onClick={() => handleEditOpen(item)} >
                                                            <img src={edit__icon} alt="edit" className="table_edit_icon" />
                                                        </span>

                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            {/* Pagination */}
                                            <td style={{ textAlign: 'right', fontSize: '32px' }} colSpan="14"><span><span onClick={() => page > 1 && setPage(page - 1)}>
                                                <ChevronLeftIcon fontSize="large" /></span> {page}/{vehicleData?.totalPages}
                                                <span onClick={() => page < vehicleData?.totalPages && setPage(page + 1)}><ChevronRightIcon fontSize="large" /></span></span></td>

                                        </tr>

                                    </tbody>
                                </table>
                }

            </div>
            {/* Modal of filter */}
            <Dialog open={openFilter} onClose={handleFilterClose}
                fullWidth={true} maxWidth={'xs'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <img src={modal_filter_icon} alt='filter' />
                                <h2>Vehicle Filter</h2>
                                <img src={reset_icon} style={{ cursor: 'pointer' }} onClick={handleResetFilter} alt='reset' />
                            </div>
                        </Grid>
                        {userData.adminGroup === 'cesl admin' &&
                            <>
                                <Grid item xs={12}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                        <h2>OEM</h2>
                                        <SwitchButton checked={openState} onChange={() => setOpenState(!openState)} name="openOEM" />
                                    </div>
                                </Grid>
                                {
                                    openState &&
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="label">Oem</InputLabel>
                                            <Select
                                                required
                                                id="select-outlined"
                                                value={applyState}
                                                onChange={e => setApplyState(e.target.value)}
                                                label="Oem"
                                                fullWidth
                                            >
                                                {
                                                    oemData?.oemDetail?.map((oem, i) => (
                                                        <MenuItem key={i} value={oem.id}>{oem.name}</MenuItem>
                                                    ))
                                                }

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                            </>
                        }
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <h2>Approval</h2>
                                <SwitchButton checked={openApproval} onChange={() => setOpenApproval(!openApproval)} name="openApproval" />
                            </div>
                        </Grid>
                        {
                            openApproval &&
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        name="approval"
                                        row
                                        value={applyStatus}
                                        onChange={e => setApplyStatus(e.target.value)}>
                                        {
                                            approvalData?.approvalStatuses?.map((item, i) => (
                                                <FormControlLabel key={i} value={item.id}
                                                    control={<Radio color="primary"
                                                        checked={item.id === parseInt(applyStatus)} />}
                                                    label={ucfirst(item.name)} />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" fullWidth
                                onClick={handleFilterApply}>Apply</Button>
                        </Grid>

                    </Grid>

                </DialogContent>
            </Dialog>

            {/* Modal for add */}
            <Dialog open={openAdd} onClose={() => setOpenAdd(false)}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>

                    <VehicleFormComponent closeForm={(loadable = false) => handleCloseAdd(loadable)} />
                </DialogContent>
            </Dialog>

            {/* Modal for edit */}
            <Dialog open={openEdit} onClose={() => setOpenEdit(false)}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>

                    <VehicleFormComponent editData={editData} closeForm={(loadable = false) => handleCloseEdit(loadable)} />
                </DialogContent>
            </Dialog>

            {/* Modal for color */}
            <Dialog open={openColor} onClose={handleColorClose}
                fullWidth={true} maxWidth={'md'}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <div>
                        <Button variant="contained" color="primary" onClick={() => setViewForm(true)}>Add New Color</Button>
                    </div>
                    <form onSubmit={submitForm}>
                        <table>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Color Code</th>
                                    <th>Color Hex Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    colorData.map((color, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>
                                                <div style={{ display: 'flex' }}>
                                                    <span className="color_round_div" style={{ backgroundColor: color.colorCode }}></span>
                                                    {color.colorName}
                                                </div>

                                            </td>
                                            <td>{color.colorCode}</td>
                                        </tr>
                                    ))
                                }
                                {viewForm && <tr>
                                    <td>
                                    </td>
                                    <td>

                                        <TextField variant="outlined"
                                            name="colorName"
                                            fullWidth
                                            required
                                            value={colorName}
                                            onChange={e => onChangeForm(e)}
                                            label="Color Code" />
                                    </td>
                                    <td>

                                        <TextField variant="outlined"
                                            name="colorHexCode" fullWidth
                                            required
                                            value={colorHexCode}
                                            onChange={e => onChangeForm(e)}
                                            label="Hex Code" />
                                    </td>
                                    <td>
                                        <Button color="primary" type='submit'>Add</Button>
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                    </form>

                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Vehicles
