import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import './OrderManagement.css'
import { sendOtp, verifyOtp } from '../../../actions/auth';
import { useSelector, useDispatch } from 'react-redux';


const DeliveryForm = ({ orderData, closeForm, otpOpen }) => {


    const [openOtp, setOpenOtp] = useState(false);
    const [deliveryForm, setDeliveryForm] = useState({
        name: '',
        order_no: '',
        address: '',
        dealer_invoice_no: '',
        delivery_challan_no: '',
        vehicle_identification_no: '',
        vehicle_reg_no: '',
        chasis_no: '',
        insurance_no: '',
        vehicleName: '',
        showroomName: '',
        phone: ''
    })


    const dispatch = useDispatch()
    const otpSuccess = useSelector(state => state.authReducer.otp_success)
    const otpVerifySuccess = useSelector(state => state.authReducer.otp_verify_success)

    const [otpValues, setOtpValues] = useState({
        otp1: '',
        otp2: '',
        otp3: '',
        otp4: ''
    })



    const { otp1, otp2, otp3, otp4 } = otpValues

    const { name, order_no, address, dealer_invoice_no, delivery_challan_no,
        vehicle_identification_no, vehicle_reg_no, chasis_no, insurance_no,
        vehicleName, showroomName, phone } = deliveryForm;

    const onDeliveryFormChange = (e) => {
        setDeliveryForm({ ...deliveryForm, [e.target.name]: e.target.value })
    }

    const otpValueChange = (e) => {
        setOtpValues({ ...otpValues, [e.target.name]: e.target.value })
    }

    const submitDeliveryForm = (event) => {
        event.preventDefault()
        dispatch(sendOtp({ phone, type: 'generate', enteredOtp: '' }))

    }

    const verifyForm = () => {

        dispatch(verifyOtp({ phone, type: 'verify', enteredOtp: otp1 + otp2 + otp3 + otp4 }))

    }

    const handleResetOtp = () => {
        dispatch(sendOtp({ phone, type: 'resend', enteredOtp: '' }))
    }

    const handleCloseOtp = () => {
        setOpenOtp(false)
        setOtpValues({
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: ''
        })
    }

    useEffect(() => {
        otpOpen && setOpenOtp(false)
    }, [otpOpen])

    useEffect(() => {
        if (otpSuccess) {
            setOpenOtp(true)
        }

    }, [otpSuccess])

    useEffect(() => {
        if (otpVerifySuccess) {
            setOpenOtp(false)
            closeForm(deliveryForm)
        }

    }, [otpVerifySuccess])

    useEffect(() => {

        setDeliveryForm({
            ...deliveryForm, name: orderData.userName,
            order_no: orderData.orderId, address: orderData.street,
            vehicleName: orderData.vehicleName, showroomName: orderData.showroomName,
            phone: orderData.userPhone
        })
    }, [orderData])

    return (
        <div>



            <div>
                <form onSubmit={(e) => submitDeliveryForm(e)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h1>Delivery Acknowledgement</h1>
                        </Grid>
                        <Grid item xs={12}>
                            <h3>Than you for choosing MyEV</h3>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField variant="outlined"
                                name="name" fullWidth
                                required
                                value={name}
                                disabled
                                onChange={(e) => onDeliveryFormChange(e)}
                                label="Customer Name" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField variant="outlined"
                                name="order_no" fullWidth
                                required
                                disabled
                                value={order_no}
                                onChange={(e) => onDeliveryFormChange(e)}
                                label="MyEv Order No" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField variant="outlined"
                                name="address" fullWidth
                                required
                                disabled
                                value={address}
                                onChange={(e) => onDeliveryFormChange(e)}
                                label="Customer Address" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField variant="outlined"
                                name="dealer_invoice_no" fullWidth
                                required
                                value={dealer_invoice_no}
                                onChange={(e) => onDeliveryFormChange(e)}
                                label="oem/Dealer Invoice No" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField variant="outlined"
                                name="delivery_challan_no" fullWidth
                                required
                                value={delivery_challan_no}
                                onChange={(e) => onDeliveryFormChange(e)}
                                label="Delivery Chalan No." />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField variant="outlined"
                                name="vehicle_identification_no" fullWidth
                                required
                                value={vehicle_identification_no}
                                onChange={(e) => onDeliveryFormChange(e)}
                                label="Vehicle Identification Number(VIN)" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField variant="outlined"
                                name="vehicle_reg_no" fullWidth
                                required
                                value={vehicle_reg_no}
                                onChange={(e) => onDeliveryFormChange(e)}
                                label="Vehicle Registration Number" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField variant="outlined"
                                name="chasis_no" fullWidth
                                required
                                value={chasis_no}
                                onChange={(e) => onDeliveryFormChange(e)}
                                label="Chasis Number" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField variant="outlined"
                                name="insurance_no" fullWidth
                                required
                                value={insurance_no}
                                onChange={(e) => onDeliveryFormChange(e)}
                                label="Insurance Number" />
                        </Grid>
                        <Grid item xs={12}>
                            <h3>
                                I <TextField value={name} disabled /> Confirm that , I have taken delivery of <TextField disabled value={vehicleName} /> from <TextField disabled value={showroomName} /> on <TextField disabled value={`${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`} />
                            </h3>
                            <h4>Date {`${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`}</h4>
                        </Grid>

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" size="large" type="submit">Verify</Button>
                            <Button color="primary">Cancel</Button>
                        </Grid>

                    </Grid>
                </form>

            </div>

            <Dialog open={openOtp} onClose={handleCloseOtp}
                fullWidth={true} maxWidth={'xs'}>
                <DialogContent>

                    <Grid container spacing={3}>
                        <Grid item xs={12}> <h1>Verifying Delivery Acknowledgement</h1></Grid>
                        <Grid item xs={12}><h3>Enter the verification code</h3></Grid>
                        <Grid item xs={12}> <h5>We have sent the otp to {phone} for delivery verification </h5></Grid>



                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {/* <input type="text" className="otp_field" ref={otp1ref} onChange={(e) => otpValueChange(e)} name="otp1" />
                                <input type="text" className="otp_field" ref={otp2ref} onChange={(e) => otpValueChange(e)} name="otp2" /> */}
                                <TextField className="otp_field" onChange={(e) => otpValueChange(e)} autoFocus name="otp1" value={otp1} type="text" inputProps={{ maxLength: 1 }} variant="outlined" />
                                <TextField className="otp_field" onChange={(e) => otpValueChange(e)} inputRef={input => otp1 !== '' && input && input.focus()} name="otp2" value={otp2} type="text" inputProps={{ maxLength: 1 }} variant="outlined" />
                                <TextField className="otp_field" onChange={(e) => otpValueChange(e)} name="otp3" inputRef={input => otp2 !== '' && input && input.focus()} value={otp3} type="text" inputProps={{ maxLength: 1 }} variant="outlined" />
                                <TextField className="otp_field" name="otp4" onChange={(e) => otpValueChange(e)} inputRef={input => otp3 !== '' && input && input.focus()} value={otp4} type="text" inputProps={{ maxLength: 1 }} variant="outlined" />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>Didnt receive the code?<span style={{ color: 'rgb(240, 174, 75)', cursor: 'pointer' }} onClick={handleResetOtp} >Resend Otp</span></div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="center-row">
                                <Button variant="contained" size="large" color="primary" onClick={verifyForm}>Confirm</Button>
                            </div>
                        </Grid>
                    </Grid>


                </DialogContent>
            </Dialog>
        </div>
    )
}

export default DeliveryForm
