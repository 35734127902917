import { REMOVE_ALERT, SET_ALERT } from "./types"

export const setAlert = (type , msg) => dispatch =>{
    dispatch({
        type : SET_ALERT,
        payload : { type , msg }
    })

    setTimeout(() =>{
        dispatch({type : REMOVE_ALERT})
    },2700)
}