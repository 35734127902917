import React, { useState, useEffect } from 'react'
import Customers from './Customers';
import CeslAdmins from './CeslAdmins';
import OemAdmins from './OemAdmins';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getdecodedItem } from '../../../utils/localStorageHelper';


const UserManagement = () => {

    const [active, setActive] = useState('customers')
    const userData = localStorage.getItem('x_auth_user') ? JSON.parse(getdecodedItem('x_auth_user')) : {};


    const { component } = useParams();

    useEffect(() => {
        setActive(component ? component : 'customers')
    }, [component])

    const setMenu = (item) => {
        setActive(item)
    }

    const renderComponent = (component) => {
        switch (component) {
            case 'customers':
                return <Customers />
            case 'oem-admins':
                return <OemAdmins />
            case 'cesl-admins':
                return userData.adminGroup === 'cesl admin' && <CeslAdmins />

            default:
                return <Customers />
        }
    }

    return (
        <div className="view__wrapper">
            <div className="container__sidemenu-wrapper">
                <div className="sub_wrapper">
                    <ul>
                        {

                            <Link to='/user-management/customers'><li onClick={() => setMenu('customers')} className={active === 'customers' ? 'active' : ''}>Customers</li></Link>
                        }



                        <Link to='/user-management/oem-admins'><li onClick={() => setMenu('oem-admins')} className={active === 'oem-admins' ? 'active' : ''}>Oem Admins</li></Link>

                        {userData.adminGroup === 'cesl admin' &&
                            <Link to='/user-management/cesl-admins'><li onClick={() => setMenu('cesl-admins')} className={active === 'cesl-admins' ? 'active' : ''}>TSREDCO Admins</li></Link>}

                    </ul>
                </div>
            </div>
            {/* content  */}

            {/* <Suspense fallback={() => <Loader />}> */}
            {renderComponent(component)}
            {/* </Suspense> */}

        </div>
    )
}

export default UserManagement
