import React from 'react';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';


const useStyles = makeStyles({
label :{
    fontWeight:600
}
});


const ComponentAddButton = (props) => {
    const classes = useStyles(props);

    return (
    
            <Button color="primary" size='large' className={classes.label} 
            onClick={props.onAdd}
            endIcon={<div style={{
                backgroundColor: '#203594',
                color: 'white',
                width: 30,
                borderRadius: 10,
                height:'3rem'
            }}><AddIcon/></div>}>
                {props.title}
            </Button>
       
    )
}

export default ComponentAddButton
