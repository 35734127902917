import { SET_ALERT, REMOVE_ALERT } from "../actions/types";

const INITIAL_STATE = {
    type: '',
    msg: ''
}

export default function (state = INITIAL_STATE, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_ALERT:
            return {
                type: payload.type,
                msg: payload.msg
            }
        case REMOVE_ALERT:
            return {
                ...state,
                type: '',
                msg: ''
            }
        default:
            return state
    }

}