import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addOemDetails, updateOemDetails } from '../../../actions/oem';
import { getAllStates } from '../../../actions/common';
import { useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const OemForm = ({ closeForm, editData }) => {

    const dispatch = useDispatch();
    const oemData = useSelector(state => state.oem.add_msg)
    const oemAddSuccess = useSelector(state => state.oem.add_success)
    const oemUpdateSuccess = useSelector(state => state.oem.update_success)
    const stateData = useSelector(state => state.common.state_data)
    const [oemForm, setOemForm] = useState({
        name: '',
        phone: '',
        gstNumber: '',
        regNumber: '',
        hqStreet: '',
        pincode: '',
        city: '',
        stateId: '',
        statusId: '',
        id: ''
    })

    const { name, phone, regNumber, gstNumber, hqStreet, pincode, city, stateId } = oemForm;

    const onChangeForm = (e) => {
        setOemForm({ ...oemForm, [e.target.name]: e.target.value })
    }

    const stateChange = e => setOemForm({ ...oemForm, stateId: e.target.value });

    const submitData = (e) => {
        e.preventDefault()
        editData ? dispatch(updateOemDetails(oemForm)) : dispatch(addOemDetails(oemForm))
    }

    const handleCloseOemForm = () => {
        setOemForm({
            name: '',
            phone: '',
            gstNumber: '',
            regNumber: '',
            hqStreet: '',
            pincode: '',
            city: '',
            stateId: '',
            statusId: '',
            id: ''
        })
        closeForm()
    }

    useEffect(() => {
        oemAddSuccess && closeForm(true)
    }, [oemAddSuccess])

    useEffect(() => {
        oemUpdateSuccess && closeForm(true)
    }, [oemUpdateSuccess])

    useEffect(() => {
        dispatch(getAllStates())
    }, [])

    useEffect(() => {
        if (editData) {
            setOemForm({
                name: editData.name,
                phone: editData.phone,
                gstNumber: editData.gstNumber,
                regNumber: editData.regNumber,
                hqStreet: editData.HqAddressStreet,
                pincode: editData.pincode,
                city: editData.city,
                stateId: editData.stateId,
                statusId: editData.approvalStatus,
                id: editData.id

            })
        }
    }, [editData])

    return (
        <div>
            <h1>{editData ? 'Edit Oem' : 'Add Oem'}</h1>
            <form onSubmit={submitData}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h3>Fill The Details</h3>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined"
                            name="name" fullWidth
                            value={name}
                            onChange={e => onChangeForm(e)}
                            label="Name" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined"
                            name="phone" fullWidth
                            value={phone}
                            onChange={e => onChangeForm(e)}
                            label="Phone Number" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined"
                            name="gstNumber" fullWidth
                            value={gstNumber}
                            onChange={e => onChangeForm(e)}
                            label="Gst Number" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined"
                            name="regNumber" fullWidth
                            value={regNumber}
                            onChange={e => onChangeForm(e)}
                            label="Registration Number" />
                    </Grid>
                    <Grid item xs={12}>
                        <h3>Headquater Address</h3>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined"
                            name="hqStreet" fullWidth
                            value={hqStreet}
                            onChange={e => onChangeForm(e)}
                            label="Street" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined"
                            name="pincode" fullWidth
                            value={pincode}
                            onChange={e => onChangeForm(e)}
                            label="Pincode" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined"
                            name="city" fullWidth
                            value={city}
                            onChange={e => onChangeForm(e)}
                            label="City" />
                    </Grid>
                    <Grid item xs={6}>
                        {/* <TextField variant="outlined"
                            name="stateId" fullWidth
                            value={stateId}
                            onChange={e => onChangeF
                                orm(e)}
                            label="State" /> */}

                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="label">State</InputLabel>
                            <Select
                                labelId="select-outlined-label"
                                id="select-outlined"
                                value={stateId}
                                fullWidth
                                onChange={stateChange}
                                label="State"
                            >
                                {
                                    stateData?.states?.map((state, i) => (
                                        <MenuItem key={i} value={state.id}>{state.name}</MenuItem>
                                    )
                                    )
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" size="large" type="submit" >Submit</Button>
                        <Button color="primary" onClick={handleCloseOemForm} >Cancel</Button>
                    </Grid>
                </Grid>
            </form>

        </div>
    )
}

export default OemForm
