import { AUTH_ERR, LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT_USER, OTP_FAILED, OTP_RESET, OTP_SUCCESS, OTP_VERIFY_FAILED, OTP_VERIFY_SUCCESS } from '../actions/types';
import { setItem, removeItem } from '../utils/localStorageHelper';

const initialState = {
    token: null,
    isAuth: false,
    loading: true,
    user: null,
    otp_success: false,
    verify_otp_success: false
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
            setItem('x_auth_token', payload.token);
            setItem('x_auth_user', JSON.stringify(payload));
            return {
                ...state,
                ...payload,
                isAuth: true,
                loading: false,
                user: payload
            }
        case AUTH_ERR:
        case LOGIN_FAILED:
            removeItem('x_auth_token');
            removeItem('x_auth_user');
            return {
                ...state,
                token: null,
                isAuth: false,
                loading: false,
                user: null
            }
        case LOGOUT_USER:
            removeItem('x_auth_token');
            removeItem('x_auth_user');
            window.location.href = '/'
            return {
                token: null,
                isAuth: false,
                loading: false,
                user: null
            }
        case OTP_RESET:
            return {
                ...state,
                otp_success: false,
                otp_verify_success: false
            }
        case OTP_SUCCESS:
            return {
                ...state,
                otp_success: true
            }
        case OTP_FAILED: return {
            ...state,
            otp_success: false
        }
        case OTP_VERIFY_SUCCESS: return {
            ...state,
            otp_verify_success: true
        }
        case OTP_VERIFY_FAILED: return {
            ...state,
            otp_verify_success: false
        }
        default:
            return state
    }

}