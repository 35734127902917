export const setItem = (key, value) => {
    let encodedString = window.btoa(value)
    localStorage.setItem(key, encodedString)
}
export const getdecodedItem = (key) => {

    let value = localStorage.getItem(key)
    let decoded = window.atob(value)
    return value?decoded:null
    


}
export const removeItem = (key) => {
    localStorage.removeItem(key)
}
export const removeAll = () => {
    localStorage.removeItem()
}